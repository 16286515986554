import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import styles from './StrikeColumn.module.scss'
import Popover from '../../../Lib/Popover/Popover'
import AdjustedOptionPopover from '../AdjustedOptionPopover/AdjustedOptionPopover'

export default function StrikeColumn ({data, underlyingValue, symbol}) {
  const adjustmentRefs = []
  const [activeRef, setActiveRef] = useState(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [popoverData, setPopoverData] = useState({delivarable: '--', underlyingValue: underlyingValue, strikePrice: '--'})
  const togglePopover = (index, lotSize, strikePrice) => {
    setActiveRef(adjustmentRefs[index])
    if (typeof index === 'number') {
      let delivarable = `${lotSize} shares of ${symbol} stock`
      setPopoverData({delivarable: delivarable, underlyingValue: underlyingValue, strikePrice: strikePrice})
    }
    setIsPopoverOpen(!isPopoverOpen)
  }
  return <div className={styles.strikeColumnContainer}>
    <div className={styles.divider}><div /></div>
    <div className={styles.header}>Strike</div>
    {data.map((item, index) => {
      return <div key={index} className={styles.body}>
        <div className={styles.strikeLabel}>{item.strikePrice}</div>
        <div className={styles.strikeIcon}>
          {item.isAdjusted &&
          <button id={'strikeInfo_' + index}
            className={styles.strikeIcon}
            ref={(ref) => { adjustmentRefs[index] = ref }}
            onClick={() => { togglePopover(index, item.lotSize, item.strikePrice) }}>
            <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={styles.icon} />
          </button>}
        </div>
      </div>
    })}
    {isPopoverOpen && (<div className={styles.popoverContainer}>
      <Popover
        id='bottom-popover'
        referenceElement={activeRef}
        closePopover={togglePopover}
        body={<AdjustedOptionPopover delivarable={popoverData.delivarable} underlyingValue={popoverData.underlyingValue}
          strikePrice={popoverData.strikePrice} closeHandler={togglePopover} />}
        placement={'bottom'}
      />
    </div>
    )}
  </div>
}
StrikeColumn.propTypes = {
  data: PropTypes.array,
  underlyingValue: PropTypes.string,
  symbol: PropTypes.string
}
