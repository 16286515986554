import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { formatNumber, renderColorClass, formatDate, formatPriceNumber } from '../../../utils/formatter'
import { getDeviceType, deviceType, getRangeStep } from '../../../utils/utilities'
import { DATE_FORMAT, TIER_TYPES } from '../../../utils/appConstants'
import Loader from '../../Lib/common/Loader'
import styles from './StocksQuote.module.scss'
import AddToWatchlists from '../../Watchlists/AddToWatchlists/AddToWatchlists'
import Toggle from '../Toggle/Toggle'

export default function StocksQuote (props) {
  let [stocksAllData, setStocksData] = useState(props.stocksData)
  const [device, setDevice] = useState(deviceType.Desktop)
  const [toggleAddtoWatchlist, setToggleAddtoWatchlist] = useState(false)

  useEffect(() => {
    setStocksData(props.stocksData)
    setDevice(getDeviceType())
  }, [props.stocksData])

  window.addEventListener('resize', function () {
    setDevice(getDeviceType())
  })

  if (stocksAllData.isLoading) {
    return (<div className={`${styles['stocks-main-container']}`}>
      <Loader ready={false}
        spinnerSize={'2x'}
        msg={'Loading'}
      />
    </div>)
  }

  return (
    <React.Fragment>
      {props.moduleName === 'AISnapShot' ? stockAISnapShotRenderData() : stockRenderData()}
      {
        toggleAddtoWatchlist && <AddToWatchlists symbol={props.symbol} xid={props.xid} onClose={() => { setToggleAddtoWatchlist(false) }} />
      }
    </React.Fragment>
  )

  function stockRenderData () {
    return <div className={`${styles['stocks-main-container']} ${props.isETF && styles['stocks-main-container-etf']}`}>
      {upperContainerInformation()}
      <div className={`${styles['stock-quote-bar-container']} ${props.isETF && styles['stock-quote-bar-container-etf']}`}>
        {device === deviceType.Desktop &&
        <React.Fragment>
          {getLastNASDAQSection()}
          {getDayChangeSection()}
          {getBidNASDAQSection()}
          {getAskNASDAQSection()}
          {getWeekrangeSection()}
          {getVolumeSection()}
          {lastTradeDateSection()}
        </React.Fragment>
        }
        {device === deviceType.Ipad &&
        <React.Fragment>
          <div className={`${styles['ipad-display']}`}>
            <div className={`${styles['display-content']}`}>
              {getLastNASDAQSection()}
              {getBidNASDAQSection()}
            </div>
            <div className={`${styles['display-content']}`}>
              {getDayChangeSection()}
              {getAskNASDAQSection()}
            </div>
            <div className={`${styles['display-content']}`}>
              {getVolumeSection()}
              {getWeekrangeSection()}
            </div>
          </div>
          <div className={`${styles['ipad-display']}`}>
            {lastTradeDateSection()}
          </div>
        </React.Fragment>
        }
        {device === deviceType.Mobile &&
        <React.Fragment>
          <div className={`${styles['mobile-display']}`}>
            {getLastNASDAQSection()}
            {getDayChangeSection()}
          </div>
          <div className={`${styles['mobile-display']}`}>
            {getBidNASDAQSection()}
          </div>
          <hr className={`${styles['spacing']}`} />
          <div className={`${styles['mobile-display']}`}>
            {getAskNASDAQSection()}
          </div>
          <hr className={`${styles['spacing']}`} />
          <div className={`${styles['mobile-display']}`}>
            {getWeekrangeSection()}
          </div>
          <hr className={`${styles['spacing']}`} />
          <div className={`${styles['mobile-display']}`}>
            {getVolumeSection()}
          </div>
          <hr className={`${styles['spacing']}`} />
          <div className={`${styles['mobile-display']}`}>
            {lastTradeDateSection()}
          </div>
        </React.Fragment>
        }
      </div>
    </div>
  }

  function stockAISnapShotRenderData () {
    return <div className={`${styles['stocks-main-container']} ${props.isETF && styles['stocks-main-container-etf']}`}>
      {upperAIContainerInformation()}
    </div>
  }

  function upperAIContainerInformation () {
    return (
      <div className={`${styles['upper_container']}`}>
        <div className={`${styles['row-1']}`}>
          <div className={`${styles['left-align']}`}>
            <span className={`${styles['ai-main-share-name']}`}>{stocksAllData.shareName}</span>
            <span className={`${styles['ai-main-share-code']}`}>{stocksAllData.cusip}</span>
          </div>
        </div>
        <div className={`${styles['row-2']} ${styles['row-2-ETF']}`}>
          <div className={`${styles['ETF']}`}>
            <span className={`${styles['pill']}`} aria-label='FundVest ETF'>{stocksAllData.symbol}</span>
          </div>
        </div>
      </div>
    )
  }

  function renderPrice (price, pricePercent) {
    return (
      <div className={`${styles['font-20']} ${styles[renderColorClass(pricePercent)]}`} >
        <span>
          {formatNumber(price, {
            negSign: true,
            posSign: true,
            precision: 2,
            parentheses: false
          })} </span>
        <span> {
          formatNumber(pricePercent, {
            negSign: true,
            postFix: true,
            postFixValue: '%',
            precision: 2,
            parentheses: true
          })} </span>
      </div>
    )
  }

  function getDateForSection () {
    let date = formatDate(stocksAllData.delayedQuoteDate, {UTCtoEST: true, postFix: 'ET', format: DATE_FORMAT.MONTH_DAY_YEAR_TIMEINSEC})

    if (date === '--') {
      return ''
    }

    return <div className={`${styles['date-info']}`}>{!props.showRealTime && <span>Delayed</span> } Quote as of {date}</div>
  }

  // need to handle it later when dynamically binding data
  function refreshDelayedQuoteDate (e) {
    props.refresh(e)
  }

  function upperContainerInformation () {
    return (
      <div className={`${styles['upper_container']}`}>
        <div className={`${styles['row-1']}`}>
          <div className={`${styles['left-align']}`}>
            <span data-testid='share-name' className={`${styles['main-share-name']}`}>{stocksAllData.shareName}</span>
            <span data-testid='share-code' className={`${styles['main-share-code']}`}>{stocksAllData.shareCode}</span>
            {!props.isETF && <div
              role='button'
              tabIndex='0'
              data-testid='eyeButton'
              className={`${styles['eye-icon']}`}
              onKeyDown={() => { setToggleAddtoWatchlist(true) }}
              onClick={() => { setToggleAddtoWatchlist(true) }}>
              <span className={`${styles['tooltip-text']}`}>Add to Watchlist</span>
              <FontAwesomeIcon icon={['far', 'eye']} data-icon-style='fa-blue-eye' />
            </div>
            }
            {props.tierType === TIER_TYPES.METERED && device === deviceType.Desktop && <div className={styles.realtime}><Toggle id='QuoteBar' isDefaultChecked={props.showRealTime} label='Real-Time' onClick={props.handleRealToggleChange} /></div>}
          </div>
        </div>
        <div className={`${styles['row-2']} ${props.isETF && styles['row-2-ETF']}`}>
          {props.isETF
            ? <div className={`${styles['ETF']}`}>
              {stocksAllData.isFundvestEtf && <span className={`${styles['pill']}`} aria-label='FundVest ETF'>FundVest ETF</span> }
              <span className={`${styles['multiAsset']} ${!(stocksAllData.isFundvestEtf) && styles.ETFMargin}`}> {stocksAllData.categoryType}</span>
            </div> : <div className={`${styles['domain-info']} ${styles['stock-name']} ${props.isETF && styles['stock-name-etf']}`} >
              <div>{`${(stocksAllData.domainOfTechnology || stocksAllData.equipmentType) ? (stocksAllData.domainOfTechnology + ' | ' + stocksAllData.equipmentType) : ''}`}</div>
            </div>
          }
          <div className={`${styles['delayed-quote-info']}`}>
            {
              getDateForSection()
            }
            <div className={`${styles['refresh-icon']}`} onClick={refreshDelayedQuoteDate}
              onKeyDown={refreshDelayedQuoteDate} role='button' tabIndex='0' aria-label='Refresh quote data'>
              <FontAwesomeIcon icon={['far', 'sync-alt']} data-icon-style='fa-blue-refresh' />
            </div>
            {props.tierType === TIER_TYPES.METERED && (device === deviceType.Mobile || device === deviceType.Ipad) && <div className={styles.realtime}><Toggle id='QuoteBar' isDefaultChecked={props.showRealTime} label='Real-Time' onClick={props.handleRealToggleChange} /></div>}
          </div>
        </div>
      </div>
    )
  }
  function getLastNASDAQSection () {
    return (
      <div className={`${styles['box']} ${props.isETF && styles['boxETF']}`} >
        <div className={`${styles['stock-name']} ${props.isETF && styles['stock-name-etf']} ${props.isETF && device === deviceType.Mobile && styles['firstbox']}`} >
          <span data-testid='last-data'>{stocksAllData.LastNASDAQData.label}</span>
        </div>
        <div className={`${styles['stock-pricing-value']} ${props.isETF && device === deviceType.Mobile && styles['stock-pricing-value-first']}`}>
          <span className={`${device === deviceType.Mobile ? styles['font-20'] : styles['pricing-value']}`}>{formatPriceNumber(stocksAllData.LastNASDAQData.price)}</span>
          <span className={`${styles['pricing-factor']}`}>{` x ${formatNumber(stocksAllData.LastNASDAQData.multiplyFactor, {precision: 0})}`}</span>
        </div>
      </div>
    )
  }
  function getDayChangeSection () {
    return (
      <div className={`${styles['box']} ${props.isETF && styles['boxETF']}`} >
        <div className={`${styles['stock-name']} ${props.isETF && styles['stock-name-etf']} ${props.isETF && device === deviceType.Mobile && styles['firstbox']}`}>
          <span data-testid='day-change-info'>{stocksAllData.DayChangeInfo.label}</span>
        </div>
        <div className={`${styles['stock-pricing-value']}`}>
          {renderPrice(stocksAllData.DayChangeInfo.price, stocksAllData.DayChangeInfo.percentChange)}
        </div>
      </div>
    )
  }
  function lastTradeDateSection () {
    let date = formatDate(stocksAllData.lastTradeDate, {UTCtoEST: true, postFix: 'ET', format: DATE_FORMAT.MONTH_DAY_YEAR_TIMEINSEC})

    if (date === '--') {
      return ''
    }

    return (
      <div className={`${styles['last-trade-info']} ${props.isETF && styles['last-trade-info-etf']}`}>
        <span>Last Trade on {date}</span>
      </div>
    )
  }
  function getBidNASDAQSection () {
    return (
      <div className={`${styles['box']} ${props.isETF && styles['boxETF']}`} >
        <div className={`${styles['stock-name']} ${props.isETF && styles['stock-name-etf']} ${device === deviceType.Mobile ? styles['left-align'] : ''}`}>
          <span data-testid='bid-data'>{stocksAllData.BidNASDAQData.label || '--'}</span>
        </div>
        <div className={`${styles['stock-pricing']}
          ${device === deviceType.Mobile ? styles['right-align'] : ''}`}>
          <span className={`${styles['pricing-value']}`}>{formatPriceNumber(stocksAllData.BidNASDAQData.price)}</span>
          <span className={`${styles['pricing-factor']}`}>{` x `} {stocksAllData.BidNASDAQData.multiplyFactor
          ? formatNumber(stocksAllData.BidNASDAQData.multiplyFactor, {precision: 0})
           : <span className={`${styles['pricing-value']}`}>{'--'}</span>}</span>
        </div>
      </div>
    )
  }
  function getAskNASDAQSection () {
    return (
      <div className={`${styles['box']} ${props.isETF && styles['boxETF']}`} >
        <div className={`${styles['stock-name']} ${props.isETF && styles['stock-name-etf']} ${device === deviceType.Mobile ? styles['left-align'] : ''}`}>
          <span data-testid='ask-data'>{stocksAllData.AskNASDAQData.label || '--'}</span>
        </div>
        <div className={`${styles['stock-pricing']}
          ${device === deviceType.Mobile ? styles['right-align'] : ''}`}>
          <span className={`${styles['pricing-value']}`}>{formatPriceNumber(stocksAllData.AskNASDAQData.price)}</span>
          <span className={`${styles['pricing-factor']}`}>{` x `} {stocksAllData.AskNASDAQData.multiplyFactor
          ? formatNumber(stocksAllData.AskNASDAQData.multiplyFactor, {precision: 0})
           : <span className={`${styles['pricing-value']}`}>{'--'}</span>}</span>
        </div>
      </div>
    )
  }
  function changeRange () {}
  function getWeekrangeSection () {
    return (
      <div className={`${styles['box']} ${props.isETF && styles['boxETF']}`} >
        <div className={`${styles['stock-name']} ${props.isETF && styles['stock-name-etf']} ${device === deviceType.Mobile ? styles['left-align'] : ''}`}>
          <span data-testid='range-data'>{stocksAllData.RangeData.label}</span>
        </div>
        <div className={`${styles['stock-pricing']}
          ${device === deviceType.Mobile ? styles['right-align'] : ''}`}>
          <span className={`${styles['pricing-value']}`}>{`${formatNumber(stocksAllData.RangeData.min, {precision: 2})} - ${formatNumber(stocksAllData.RangeData.max, {precision: 2})}`}</span>
        </div>
        {device !== deviceType.Mobile && stocksAllData.RangeData.date &&
        <div className={`
        ${styles['progress-bar']} ${props.isETF && styles['progress-bar-etf']} ${device === deviceType.Mobile ? styles['hide'] : ''}`}>
          <input type='range' min={stocksAllData.RangeData.min} max={stocksAllData.RangeData.max}
            value={stocksAllData.RangeData.value} onChange={changeRange} step={getRangeStep(stocksAllData.RangeData.min, stocksAllData.RangeData.max)} className={`${styles['slider']}`} aria-label={`${stocksAllData.RangeData.label}: ${stocksAllData.RangeData.value}`} disabled />
        </div>
        }
      </div>
    )
  } function getVolumeSection () {
    return (
      <div className={`${styles['box']} ${props.isETF && styles['boxETF']}`} >
        <div className={`${styles['stock-name']} ${props.isETF && styles['stock-name-etf']} ${device === deviceType.Mobile ? styles['left-align'] : ''}`}>
          <span data-testid='volume'>{stocksAllData.Volume.label}</span>
        </div>
        <div className={`${styles['volume-section']}
          ${device === deviceType.Mobile ? styles['right-align'] : ''}
          ${device === deviceType.Mobile ? styles['font-bold'] : ''}`}>
          <span className={`${styles['volume-data']}`}>{stocksAllData.Volume.value}</span>
        </div>
      </div>
    )
  }
}
StocksQuote.propTypes = {
  stocksData: PropTypes.object,
  isETF: PropTypes.bool,
  refresh: PropTypes.func,
  symbol: PropTypes.string,
  xid: PropTypes.number,
  moduleName: PropTypes.string,
  showRealTime: PropTypes.bool,
  tierType: PropTypes.number,
  handleRealToggleChange: PropTypes.func
}
