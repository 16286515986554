import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { handleWindowResize, deviceType, getDeviceType } from '../../../utils/utilities'
import styles from './CommonTabularStructure.module.scss'
import Loader from '../common/Loader'
function CommonTabularStructure ({dataObj, children, containerStyle, handlePageClick}) {
  const [device, setDevice] = useState(getDeviceType())
  useEffect(() => {
    handleWindowResize((response) => {
      setDevice(response)
    })
  }, [])
  function getMainContent () {
    return deviceType.Ipad !== device ? getBodyStructure(dataObj.data) : getBodyStructureIpad()
  }

  function handleClick () {
    handlePageClick && handlePageClick()
  }

  function getBodyStructure (data) {
    let mainContent = data.map((item, idx) => {
      return (
        <div key={idx} className={`${styles.mainContent} ${!dataObj.footer ? styles.noBottomLastChild : ''}`}>
          <div className={styles.mainContentLabel}>{item.label}</div>
          <div className={styles.mainContentValue} style={item.valueStyling}>{item.value}</div>
        </div>)
    })
    return (<section> {mainContent} </section>)
  }
  function getBodyStructureIpad () {
    let leftMainContent = getBodyStructure(dataObj.data.filter(e => !e.column))
    let rightMainContent = getBodyStructure(dataObj.data.filter(e => e.column))
    return (<div className={styles.ipadContentContainer} data-table-structure='table-ipad-container'>
      <div className={styles.ipadContentLeft}>
        {leftMainContent}
      </div>
      <div className={styles.ipadContentRight}>
        {rightMainContent}
      </div>
    </div>)
  }
  const content = dataObj.isLoading ? (<div className={styles.commonTabularStructureContainer}>
    <Loader spinnerSize={'2x'} ready={!dataObj.isLoading} />
  </div>) : !children ? getMainContent() : null

  return (
    <div className={styles.commonTabularStructureContainer} style={containerStyle}>
      <header>
        <div className={styles.headerTabularStructure}>{dataObj.header}{dataObj.subHeader ? <span className={styles.headerSubTabularStructure}>{dataObj.subHeader}</span> : ''}</div>
      </header>
      {children || content}
      {dataObj.footer && <footer>
        <button data-testid='footerId' className={styles.footerButton} onClick={handleClick}>{dataObj.footer}</button>
      </footer>
      }
    </div>
  )
}

export default CommonTabularStructure

CommonTabularStructure.propTypes = {
  dataObj: PropTypes.object.isRequired,
  children: PropTypes.node,
  containerStyle: PropTypes.object,
  handlePageClick: PropTypes.func
}
