'use strict'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { remvoveHTMLTags, splitNewsContentByDash, enterKeyEvent } from '../../../utils/utilities'
import NewsHeader from '../NewsHeader/NewsHeader'
import styles from './News.module.scss'
export default function News ({articleId, headline, teaser, provider, date, onReadArticle, setRefs}) {
  const [ screenWidth, setScreenWidth ] = useState(594)

  useEffect(() => {
    function handleResize () {
      setScreenWidth(screen.width)
    }
    window.addEventListener('resize', handleResize)
  })

  let numberofChar = 125
  if (screenWidth < 620) {
    numberofChar = 135
  } else if (screenWidth < 1024) {
    numberofChar = 155
  } else {
    numberofChar = 200
  }
  return (
    <div className={styles.newsContainer}>
      <NewsHeader provider={provider} date={date} headline={headline} noOfLinesHeader={3} />
      <div className={styles.teaser}>
        <span dangerouslySetInnerHTML={remvoveHTMLTags(splitNewsContentByDash(teaser), numberofChar)} />
        <button
          className={styles.readArticle}
          onClick={onReadArticle}
          onKeyUp={(e) => enterKeyEvent(e, onReadArticle)}
          ref={(ref) => setRefs(ref, articleId)}
          data-testid='test1'
        >Read Article</button>
      </div>
    </div>
  )
}

News.propTypes = {
  articleId: PropTypes.string,
  headline: PropTypes.string,
  teaser: PropTypes.string,
  provider: PropTypes.string,
  date: PropTypes.string,
  onReadArticle: PropTypes.func,
  setRefs: PropTypes.func
}
