import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { formatNumber, renderColorClass, formatDate, convertToInternationalCurrencySystem } from '../../../../components/utils/formatter'
import { getDeviceType, deviceType } from '../../../../../../../utils/utilities'
import { DATE_FORMAT } from '../../../../../../../utils/appConstants'
import Loader from '../../../../../../Lib/common/Loader'
import styles from './FundStocksQuote.module.scss'
export default function FundStocksQuote (props) {
  let [stocksAllData, setStocksData] = useState(props.stocksData)
  const [device, setDevice] = useState(deviceType.Desktop)

  useEffect(() => {
    setStocksData(props.stocksData)
    setDevice(getDeviceType())
  }, [props.stocksData])

  window.addEventListener('resize', function () {
    setDevice(getDeviceType())
  })

  if (stocksAllData.isLoading) {
    return (<div className={`${styles['stocks-main-container']}`}>
      <Loader ready={false}
        spinnerSize={'2x'}
        msg={'Loading'}
      />
    </div>)
  }
  return (
    <React.Fragment>
      {stockRenderData()}
    </React.Fragment>
  )

  function stockRenderData () {
    return <div className={`${styles['stocks-main-container']} ${styles['stocks-main-container-fund']}`}>
      {upperContainerInformation()}
      <div className={styles['stock-quote-bar-container']}>
        {device === deviceType.Desktop &&
        <React.Fragment>
          {getLastNAVSection()}
          {getChangeSection()}
          {getYTDChange()}
          {getFundNetAssestSection()}
          {getGrossExpenseRatioSection()}
          {getTrailing1YrDistributionYieldSection()}
          {getLastTradeDateSection()}
        </React.Fragment>
        }
        {device === deviceType.Ipad &&
        <React.Fragment>
          <div className={`${styles['ipad-display']}`}>
            <div className={`${styles['display-content']}`}>
              {getLastNAVSection()}
              {getFundNetAssestSection()}
            </div>
            <div className={`${styles['display-content']}`}>
              {getChangeSection()}
              {getGrossExpenseRatioSection()}
            </div>
            <div className={`${styles['display-content']}`}>
              {getYTDChange()}
              {getTrailing1YrDistributionYieldSection()}
            </div>
          </div>
          <div className={`${styles['ipad-display']}`}>
            {getLastTradeDateSection()}
          </div>
        </React.Fragment>
        }
        {device === deviceType.Mobile &&
        <React.Fragment>
          <div className={`${styles['mobile-display']}`}>
            {getLastNAVSection()}
            {getChangeSection()}
          </div>
          <div className={`${styles['mobile-display']}`}>
            {getYTDChange()}
          </div>
          <hr className={`${styles['spacing']}`} />
          <div className={`${styles['mobile-display']}`}>
            {getFundNetAssestSection()}
          </div>
          <hr className={`${styles['spacing']}`} />
          <div className={`${styles['mobile-display']}`}>
            {getGrossExpenseRatioSection()}
          </div>
          <hr className={`${styles['spacing']}`} />
          <div className={`${styles['mobile-display']}`}>
            {getTrailing1YrDistributionYieldSection()}
          </div>
          <hr className={`${styles['spacing']}`} />
          <div className={`${styles['mobile-display']}`}>
            {getLastTradeDateSection()}
          </div>
        </React.Fragment>
        }
      </div>
    </div>
  }

  function renderChangeValue (price, pricePercent) {
    return (
      <div className={`${styles['font-20']} ${styles[renderColorClass(pricePercent)]}`} >
        <span>
          {formatNumber(price, {
            negSign: true,
            posSign: true,
            precision: 2,
            parentheses: false
          })} </span>
        <span> {
          formatNumber(pricePercent, {
            negSign: true,
            postFix: true,
            postFixValue: '%',
            precision: 2,
            parentheses: true
          })} </span>
      </div>
    )
  }

  function renderValue (value) {
    return (
      <div className={styles[renderColorClass(value)]} >
        <span> {
          formatNumber(value, {
            posSign: true,
            negSign: true,
            postFix: true,
            postFixValue: '%',
            precision: 2
          })} </span>
      </div>
    )
  }

  function formatToMillion (value) {
    if (!value || value === '--') return '--'
    return '$' + convertToInternationalCurrencySystem(value)
  }

  function getDateForSection () {
    return formatDate(stocksAllData.DelayedQuoteDate.value, {UTCtoEST: true, postFix: 'ET', format: DATE_FORMAT.MONTH_DAY_YEAR_TIMEINSEC})
  }

  function upperContainerInformation () {
    return (
      <div className={`${styles['upper_container']}`}>
        <div className={`${styles['row-1']}`}>
          <div className={`${styles['left-align']}`}>
            <span className={`${styles['main-share-name']}  ${styles['font-20']}`}>{stocksAllData.shareName}</span>
          </div>
        </div>
        <div className={styles['row-2']}>
          {<div id='fund-pill-container'>
            {stocksAllData.isFundvestEtf && <span className={`${styles['pill']}`} aria-label={`${stocksAllData.isFundvestEtf}`}>{stocksAllData.isFundvestEtf}</span> }
            <span className={`${styles['multiAsset']}`}> {stocksAllData.categoryType}</span>
          </div>
          }
          <div className={`${styles['delayed-quote-info']}`}>
            <div className={`${styles['date-info']}`}>{`${stocksAllData.DelayedQuoteDate.label} ${getDateForSection()}`}</div>
            {/* TODO - It's Day2 item --> will be required later
            <div className={`${styles['refresh-icon']}`} onClick={refreshDelayedQuoteDate}
              onKeyDown={refreshDelayedQuoteDate} role='button' tabIndex='0' aria-label='Refresh quote data'>
              <FontAwesomeIcon icon={['far', 'sync-alt']} data-icon-style='fa-blue-refresh' />
            </div> */}
          </div>
        </div>
      </div>
    )
  }
  function getLastNAVSection () {
    return (
      <div className={`${styles['box']} ${styles['boxMF']}`} >
        <div className={`${styles['stock-name']} ${styles['stock-name-fund']} ${device === deviceType.Mobile && styles['firstbox']}`} >
          <span>{stocksAllData.LastNAV.label}</span>
        </div>
        <div className={`${styles['stock-pricing-value']} ${device === deviceType.Mobile && styles['stock-pricing-value-first']}`}>
          <span className={`${device === deviceType.Mobile ? styles['font-20'] : styles['pricing-value']}`}>{formatNumber(stocksAllData.LastNAV.value, {precision: 2})}</span>
        </div>
      </div>
    )
  }
  function getChangeSection () {
    return (
      <div className={`${styles['box']} ${styles['boxMF']}`} >
        <div className={`${styles['stock-name']} ${styles['stock-name-fund']} ${device === deviceType.Mobile && styles['firstbox']}`}>
          <span>{stocksAllData.Change.label}</span>
        </div>
        <div className={`${styles['stock-pricing-value']} ${device === deviceType.Mobile && styles['stock-pricing-value-first']}`}>
          <span className={`${device === deviceType.Mobile ? styles['font-20'] : styles['pricing-value']}`}>{renderChangeValue(stocksAllData.Change.value, stocksAllData.Change.percentChange)}</span>
        </div>
      </div>
    )
  }
  function getYTDChange () {
    return (
      <div className={`${styles['box']} ${styles['boxMF']}`} >
        <div className={`${styles['stock-name']} ${styles['stock-name-fund']} ${device === deviceType.Mobile ? styles['left-align'] : ''}`}>
          <span>{stocksAllData.YTDChange.label}</span>
        </div>
        <div className={`${styles['stock-pricing']} ${device === deviceType.Mobile ? styles['right-align'] : ''}`}>
          <span className={`${styles['pricing-value']}`}>{renderValue(stocksAllData.YTDChange.value)}</span>
        </div>
      </div>
    )
  }
  function getFundNetAssestSection () {
    return (
      <div className={`${styles['box']} ${styles['boxMF']}`} >
        <div className={`${styles['stock-name']} ${styles['stock-name-fund']} ${device === deviceType.Mobile ? styles['left-align'] : ''}`}>
          <span>{stocksAllData.FundNetAssets.label}</span>
        </div>
        <div className={`${styles['stock-pricing']} ${device === deviceType.Mobile ? styles['right-align'] : ''}`}>
          <span className={`${styles['pricing-value']}`}>{formatToMillion(stocksAllData.FundNetAssets.value)}</span>
        </div>
      </div>
    )
  }
  function getGrossExpenseRatioSection () {
    return (
      <div className={`${styles['box']} ${styles['boxMF']}`} >
        <div className={`${styles['stock-name']} ${styles['stock-name-fund']} ${device === deviceType.Mobile ? styles['left-align'] : ''}`}>
          <span>{stocksAllData.GrossExpenseRatio.label}</span>
        </div>
        <div className={`${styles['stock-pricing']} ${device === deviceType.Mobile ? styles['right-align'] : ''}`}>
          <span className={`${styles['pricing-value']}`}>{formatNumber(stocksAllData.GrossExpenseRatio.value, { postFix: true, postFixValue: '%', precision: 2 })}</span>
        </div>
      </div>
    )
  } function getTrailing1YrDistributionYieldSection () {
    return (
      <div className={`${styles['box']} ${styles['boxMF']}`} >
        <div className={`${styles['stock-name']} ${styles['stock-name-fund']} ${device === deviceType.Mobile ? styles['left-align'] : ''}`}>
          <span>{stocksAllData.Trailing1YrDistributionYield.label}</span>
        </div>
        <div className={`${styles['stock-pricing']} ${device === deviceType.Mobile ? styles['right-align'] : ''} ${device === deviceType.Mobile ? styles['font-bold'] : ''}`}>
          <span className={`${styles['pricing-value']}`}>{formatNumber(stocksAllData.Trailing1YrDistributionYield.value, { postFix: true, postFixValue: '%', precision: 2 })}</span>
        </div>
      </div>
    )
  }
  function getLastTradeDateSection () {
    return (
      <div className={`${styles['last-trade-info']} ${styles['last-trade-info-fund']}`}>
        <span>{`${stocksAllData.LastTradeDate.label} ${formatDate(stocksAllData.LastTradeDate.value, {UTCtoEST: true, postFix: 'ET', format: DATE_FORMAT.MONTH_DAY_YEAR_TIMEINSEC})}`}</span>
      </div>
    )
  }
}
FundStocksQuote.propTypes = {
  stocksData: PropTypes.object
}
