import React from 'react'
import PropsTypes from 'prop-types'
import LinkButton from '../LinkButton/LinkButton'
import styles from './ExpandCollapseButtons.module.scss'

export default function ExpandCollapseButtons ({expandCollapseHandler, disableExpandAllBtn = false, disableCollapseAllBtn = false}) {
  const handleClick = (name) => {
    expandCollapseHandler(name)
  }
  return <div className={styles.expandCollapseContainer}>
    <LinkButton label='Expand All' onClick={() => { handleClick('expand') }} disabled={disableExpandAllBtn} />
    <div className={styles.seprator}>|</div>
    <LinkButton label='Collapse All' onClick={() => { handleClick('collapse') }} disabled={disableCollapseAllBtn} />
  </div>
}
ExpandCollapseButtons.propTypes = {
  expandCollapseHandler: PropsTypes.func,
  disableExpandAllBtn: PropsTypes.bool,
  disableCollapseAllBtn: PropsTypes.bool
}
