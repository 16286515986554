import React from 'react'
import styles from './SnapshotShareclass.module.scss'
import SimilarFunds from '../SimilarFunds/SimilarFunds'
import PropTypes from 'prop-types'
import QuoteCard from '../QuoteCardWithStockHighlights/QuoteCardWithStockHighlights'
import Loader from '../../../../../Lib/common/Loader'
import Nodata from '../../../../../Lib/NoData/NoData'
export default function SnapshotShareclass (props) {
  function getStyles () {
    let styles = {}
    if (props.deviceTypeItem === 'MOBILE') {
      styles = {width: 'calc(100vw - 60px', background: '#F7F7F7'}
    } else {
      styles = {width: '228px', background: '#F7F7F7'}
    }
    return styles
  }
  function renderBody () {
    let html = ''
    if (props.data) {
      if (props.data.isNoDataAvailable) {
        html = <Nodata msg='Data not available' />
      } else {
        html = <React.Fragment>
          <div className={styles.fixedCard}>
            <div className={styles.header}>{'This Fund'}</div>
            <div className={styles.cardContainer}>
              {props.data.fixedCardData[0] && <QuoteCard type='MFSnapshot' icon='' data={props.data.fixedCardData[0]} style={getStyles()} />}
            </div>
          </div>
          <div className={styles.scrollableCards}>
            {props.data.shareClassCardData && <SimilarFunds header={props.header} moduleName={props.moduleName} data={props.data.shareClassCardData} xid={props.venueXid} />}
          </div>
        </React.Fragment>
      }
    } else {
      html = <div className={styles.loader}><Loader spinnerSize='2x' /></div>
    }
    return html
  }
  return (
    <div className={styles.shareClassContainer} >
      <div className={styles.heading}>{'Share Classes'}</div>
      <div className={styles.shareClassContainer}>
        {renderBody()}
      </div>
    </div>
  )
}
SnapshotShareclass.propTypes = {
  data: PropTypes.object,
  header: PropTypes.string,
  deviceTypeItem: PropTypes.string,
  venueXid: PropTypes.number,
  moduleName: PropTypes.string,
  isNoDataAvailable: PropTypes.bool
}
