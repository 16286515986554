import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './OptionsQuoteBar.module.scss'
import { deviceType, getDeviceType, handleWindowResize } from '../../../../utils/utilities'
import { formatDate, formatNumber } from '../../../../utils/formatter'
import Divider from '../../../Lib/Divider/Divider'
import { TICKER_LABELS } from '../OptionsConstants'
import { DEFAULT_VALUE } from '../../../../utils/appConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddToWatchlists from '../../../Watchlists/AddToWatchlists/AddToWatchlists'

export default function OptionsQuoteBar ({symbol, greeksData, tab, expirationDate, handleOptionsDetails}) {
  console.log(greeksData)
  const [device, setDevice] = useState(getDeviceType())
  const [toggleAddtoWatchlist, setToggleAddtoWatchlist] = useState(false)

  useEffect(() => {
    handleOptionsDetails()
    handleWindowResize((response) => { setDevice(response) })
  }, [])

  function renderSection (label, value) {
    return (
      <div className={`${styles['box']} ${((device === deviceType.Ipad) && (['Deliverable Shares', 'Volume', 'Open Interest'].includes(label))) ? styles['box-2'] : ''}`} >
        <div className={`${styles['options-label']}`} >
          <span>{label}</span>
        </div>
        <div className={`${styles['options-value']}`}>
          <span>{ value}</span>
        </div>
      </div>
    )
  }

  function getChangeSection () {
    return (
      <div className={`${styles['box']}`} >
        <div className={`${styles['options-label']}`}>
          <span>{TICKER_LABELS.CHANGE}</span>
        </div>
        <div className={`${styles['options-value']}`}>
          {(greeksData.greeks.isError === false) ? DEFAULT_VALUE
            : <span>
              <span className={greeksData.quote.lastTrade.change < 0 ? styles['red'] : styles['green']}>{formatNumber(greeksData.quote.lastTrade.change)}</span>
              <span className={greeksData.quote.changePercent.today < 0 ? styles['red'] : styles['green']}> ({formatNumber(greeksData.quote.changePercent.today, {postFix: true})})</span>
            </span>
          }
        </div>
      </div>
    )
  }

  function getDayRangeSection () {
    return (
      <div className={`${styles['box']} ${device === deviceType.Ipad ? styles['box-2'] : ''}`}>
        <div className={`${styles['options-label']}`}>
          <span>{TICKER_LABELS.DAYRANGE}</span>
        </div>
        <div className={`${styles['options-value']}`}>
          {(greeksData.greeks.isError === false) ? DEFAULT_VALUE
            : <span>{formatNumber(greeksData.quote.lastTrade.low)} - {formatNumber(greeksData.quote.lastTrade.high)}</span>
          }
        </div>
      </div>
    )
  }

  const getSymbol = () => {
    let symbolLocal = ''
    symbolLocal += symbol ? symbol.toUpperCase() : ' '
    symbolLocal += ' '
    symbolLocal += formatDate(expirationDate, {format: 'MMDDYYYY'})
    symbolLocal += tab !== null ? (tab === 'Calls' ? ' C ' : ' P ') : ''
    symbolLocal += greeksData.strikePrice
    return symbolLocal
  }

  return (
    <div className={styles.container} id='optionsQuoteBar' >
      <div className={styles.divider}>
        <Divider />
      </div>
      {
        toggleAddtoWatchlist && <AddToWatchlists isOption symbol={getSymbol()} xid={greeksData.xid} onClose={() => { setToggleAddtoWatchlist(false) }} />
      }
      <div className={styles.header}>
        {symbol ? symbol.toUpperCase() : ' '}
        {' '}
        {formatDate(expirationDate, {format: 'MMDDYYYY'})}
        {tab !== null ? (tab === 'Calls' ? ' C ' : ' P ') : ''}
        {greeksData.strikePrice}
        <div className={styles.eyeIcon} role='button' tabIndex='0' data-testid='eyeButton' onKeyDown={() => { setToggleAddtoWatchlist(true) }} onClick={() => { setToggleAddtoWatchlist(true) }}>
          <span className={styles.tooltipText}>Add to Watchlist</span>
          <FontAwesomeIcon icon={['far', 'eye']} data-icon-style='fa-blue-eye' className={styles.eyeIconFont} />
        </div>
      </div>
      {greeksData.isAdjusted && <div className={styles.adjustmentHeader}>
        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={styles.iconNoData} />
        <div className={styles.adjustmentMsg}><span className={styles.adjustmentBoldMsg}>Non-Standard Option:</span> Please note this contract may have different delivery instructions.</div>
      </div>}
      {(device === deviceType.Desktop || device === deviceType.Mobile) &&
      <div className={styles.optionsBar}>
        {renderSection(TICKER_LABELS.STRIKE, formatNumber(greeksData.strikePrice))}
        {(device === deviceType.Mobile) && <Divider />}
        {renderSection(TICKER_LABELS.BID, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : formatNumber(greeksData.quote.bid.price))}
        {(device === deviceType.Mobile) && <Divider />}
        {renderSection(TICKER_LABELS.ASK, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : formatNumber(greeksData.quote.ask.price))}
        {(device === deviceType.Mobile) && <Divider />}
        {renderSection(TICKER_LABELS.LAST_PRICE, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : formatNumber(greeksData.quote.lastTrade.last))}
        {(device === deviceType.Mobile) && <Divider />}
        {getChangeSection()}
        {(device === deviceType.Mobile) && <Divider />}
        {getDayRangeSection()}
        {(device === deviceType.Mobile) && <Divider />}
        {renderSection(TICKER_LABELS.DELIVERABLE_SHARES, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : greeksData.lotSize)}
        {(device === deviceType.Mobile) && <Divider />}
        {renderSection(TICKER_LABELS.VOLUME, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : greeksData.quote.volume.last)}
        {(device === deviceType.Mobile) && <Divider />}
        {renderSection(TICKER_LABELS.OPEN_INTEREST, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : greeksData.quote.openInterest)}
      </div>
      }
      {(device === deviceType.Ipad) &&
      <div className={styles.optionsBar}>
        <div className={styles.Row}>
          {renderSection(TICKER_LABELS.STRIKE, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : formatNumber(greeksData.strikePrice))}
          {getDayRangeSection()}
        </div>
        <div className={styles.Row}>
          {renderSection(TICKER_LABELS.BID, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : formatNumber(greeksData.quote.bid.price))}
          {renderSection(TICKER_LABELS.DELIVERABLE_SHARES, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : greeksData.lotSize)}
        </div>
        <div className={styles.Row}>
          {renderSection(TICKER_LABELS.ASK, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : formatNumber(greeksData.quote.ask.price))}
          {renderSection(TICKER_LABELS.VOLUME, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : greeksData.quote.volume.last)}
        </div>
        <div className={styles.Row}>
          {renderSection(TICKER_LABELS.LAST_PRICE, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : formatNumber(greeksData.quote.lastTrade.last))}
          {renderSection(TICKER_LABELS.OPEN_INTEREST, (greeksData.greeks.isError === false) ? DEFAULT_VALUE : greeksData.quote.openInterest)}
        </div>
        <div className={styles.Row}>
          {getChangeSection()}
          <div />
        </div>
      </div>
      }
    </div>
  )
}
OptionsQuoteBar.propTypes = {
  symbol: PropTypes.string,
  greeksData: PropTypes.object,
  tab: PropTypes.string,
  expirationDate: PropTypes.string,
  handleOptionsDetails: PropTypes.func
}
