/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import {formatNumber, numberFormatter, TEXT_NULL_VALUE} from '../../../../utils/formatter'
import styles from './OptionsTable.module.scss'

export default function OptionsTable ({name, header, data, expirationDate, reverseColumn, handleOptionTableRowClick}) {
  const changeBackgroundColor = (intrinsicValue) => {
    if (intrinsicValue && intrinsicValue !== 0) {
      return styles.blueBg
    }
  }

  const bidAskColumn = (value, item, testId) => {
    return <div className={`${styles.tableCell} ${styles.blueText} ${styles.cursorPointer}`} onClick={() => {
      handleOptionTableRowClick(item, expirationDate, name)
    }} data-testid={testId}>
      {value}
    </div>
  }
  const otherCommonColumn = (value, formatFunc) => {
    return <div className={styles.tableCell}>
      {value ? formatFunc && formatFunc(value) : TEXT_NULL_VALUE}
    </div>
  }
  const createEmptyRow = (index) => {
    return <div key={index} className={styles.tableRow}>
      {header.map(() => {
        return <div className={styles.tableCell}>
          {TEXT_NULL_VALUE}
        </div>
      })
      }
    </div>
  }
  return (
    <div className={styles.optionsTableContainer} data-testid='options_container'>
      <div className={`${styles.tableHeader} ${styles.tableRow} ${reverseColumn && styles.reverseColumn}`}>
        {header.map(item => {
          return <div key={item}>{item}</div>
        })}
      </div>
      <div className={styles.tableBody}>
        {reverseColumn
          ? data.map((items, index) => {
            let quote = items.quote
            if (!quote) {
              return createEmptyRow(index)
            } else {
              return <div key={index} className={`${styles.tableRow} ${changeBackgroundColor(items.calculation && items.calculation.intrinsicValue)}`}>
                {otherCommonColumn(items.greeks && items.greeks.delta, formatNumber)}
                {otherCommonColumn(quote.openInterest, numberFormatter)}
                {otherCommonColumn(quote.volume && quote.volume.last, numberFormatter)}
                {bidAskColumn(quote.bid && quote.bid.price, items, 'calls_bid_' + index)}
                {bidAskColumn(quote.ask && quote.ask.price, items, 'calls_ask_' + index)}
                {otherCommonColumn((quote.lastTrade && quote.lastTrade.last), formatNumber)}
              </div>
            }
          })
          : data.map((items, index) => {
            let quote = items.quote
            if (!quote) {
              return createEmptyRow(index)
            } else {
              return <div key={index} className={`${styles.tableRow} ${changeBackgroundColor(items.calculation && items.calculation.intrinsicValue)}`}>
                {bidAskColumn(quote.bid && quote.bid.price, items, 'puts_bid_' + index)}
                {bidAskColumn(quote.ask && quote.ask.price, items, 'puts_ask_' + index)}
                {otherCommonColumn(quote.lastTrade && quote.lastTrade.last, formatNumber)}
                {otherCommonColumn(quote.volume && quote.volume.last, numberFormatter)}
                {otherCommonColumn(quote.openInterest, numberFormatter)}
                {otherCommonColumn(items.greeks && items.greeks.delta, formatNumber)}
              </div>
            }
          })
        }
      </div>
    </div>
  )
}
OptionsTable.propTypes = {
  name: PropTypes.string,
  header: PropTypes.array,
  data: PropTypes.array,
  expirationDate: PropTypes.string,
  reverseColumn: PropTypes.bool,
  handleOptionTableRowClick: PropTypes.func
}
