import React from 'react'
import Divider from '../../../Lib/Divider/Divider'
import styles from './AdjustedOptionPopover.module.scss'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function AdjustedOptionPopover ({delivarable, underlyingValue, strikePrice, closeHandler}) {
  return (<div className={styles.adjustedOptionContainer}>
    <div className={styles.adjustedHeaderRow}>
      <div>Adjusted Option</div>
      <button className={styles.btnPopUpClose} aria-label='Close' onClick={closeHandler}>
        <FontAwesomeIcon icon={['fal', 'times']} className={styles.btnPopUpCloseIcon} />
      </button>
    </div>
    <div className={styles.adjustedItemRow}>
      <div className={`${styles.adjustedLabel} ${styles.col1}`}>Deliverables</div>
      <div className={`${styles.adjustedValue} ${styles.col2}`}>{delivarable}</div>
    </div>
    <Divider />
    <div className={styles.adjustedItemRow}>
      <div className={`${styles.adjustedLabel} ${styles.col}`}>Underlying Value</div>
      <div className={`${styles.adjustedValue} ${styles.col}`}>{underlyingValue}</div>
    </div>
    <Divider />
    <div className={styles.adjustedItemRow}>
      <div className={`${styles.adjustedLabel} ${styles.col}`}>Strike Price</div>
      <div className={`${styles.adjustedValue} ${styles.col}`}>{strikePrice}</div>
    </div>
  </div>)
}
AdjustedOptionPopover.propTypes = {
  delivarable: PropTypes.string,
  underlyingValue: PropTypes.string,
  strikePrice: PropTypes.string,
  closeHandler: PropTypes.func
}
