import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TabControl from '../Shared/TabControl/TabControl'
import styles from './FundResearch.module.scss'
import TopHolding from '../Shared/TopTenHolding/TopHolding'
import DataRequest from '../../../../../services/DataRequest'
import SnapshotShareclass from '../Shared/SnapshotShareclass/SnapshotShareclass'
import {screenerBoolDictionary, moveScrollToTopById} from '../Helpers/CommonFunctions'
import {convertToInternationalCurrencySystem, formatNumber, textFormatter, formatDate, isBankCustody} from '../utils/formatter'
import {deviceType, handleWindowResize, getDeviceType} from '../../../../../utils/utilities'
import {URLS, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS, FundShareClassSnapshotResultArr, MFSnapshotResultColumnArr} from '../../components/utils/appConstants'
import SnapshotRatings from '../Shared/SnapshotRatings/SnapshotRatings'
import StyleBox from '../Shared/EquityAndFixedIncome/EquityAndFixedIncome'
import SharpeRatio from '../Shared/BetaRisk/BetaRisk'
import SnapshotStrategy from '../Shared/SnapshotStrategy/SnapshotStrategy'
import SimilarFunds from '../Shared/SimilarFunds/SimilarFunds'
import SnapshotProfile from '../Shared/SnapshotProfile/SnapshotProfile'
import AssetAllocationTable from '../Shared/AssestAllocationChart/AssestAllocationTable'
import ToolTip from '../Shared/Overlay/ToolTip'
import SnapshotPerformance from '../Shared/SnapshotPerformance/SnapshotPerformance'
import FundQuoteBar from './FundQuoteBar/FundQuoteBar'
import SnapshotHighlights from '../Shared/SnapshotHighlights/SnapshotHighlights'
import { getAPIData } from '../utils/utilities'
import SummaryTable from '../Shared/SummaryTable/SummaryTable'
import FundResearchConstants from './FundResearchConstants.json'
import Performance10KFund from '../Shared/GrowthOf10K/Performance10KFund'
import ImportantDisclosure from '../Shared/Disclosures/ImportantDisclosures/ImportantDisclosure'
import Footer from '../Shared/Disclosures/Footer/Footer'
import PerformanceDisclosure from '../Shared/Disclosures/PerformanceDisclosures/PerformanceDisclosure'
import ESGOverAllScore from '../Shared/CompareProgressBar/ESGOverAllScore'
import ESGScores from '../Shared/ESGScores/ESGScores'
import FundPortfolio from '../FundPortfolio/FundPortfolio'
import { registerF2OnEvent } from '../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../utils/f2Constants'
import Loader from '../../../../Lib/common/Loader'
import {getAnalystRatings} from "../Shared/CompareProgressBar/ESGCommonMethods";

const FundResearch = (props) => {
  const [fundResearchData, setFundResearchData] = useState({
    symbol: props.symbol,
    xid: props.xid,
    shareName: props.name,
    exchange: props.exchange,
    isFundvestEtf: props.IsFVETF,
    productAccess: props.productAccess,
    isMF: true,
    riskTitle: 'Sharpe Ratio',
    type: 'MF'
  })
  const [activeTabKey, setActiveTabKey] = useState('Snapshot')
  const [shareClassData, setShareClassData] = useState()
  const [mfPershingMfdsData, setMfPershingMfdsData] = useState({formattedsettlePurchaseDaysFundsrv: null, formattedsettleRedemptionDaysFundsrv: null})
  const [deviceTypeItem, setDeviceType] = useState(deviceType.Desktop)
  const TEXT_NULL_VALUE = '--'
  const [showStyleBox, setShowStyleBox] = useState(true)
  // TODO. Tech debt needs to be created to to remove isETF and refactor code accordingly. Need this to make various child components work as they have isETF checks e.g Asset Allocation table.
  const isETF = true
  const [categoryData, setCategoryData] = useState({ name: null })
  const [benchmarkData, setBenchmarkData] = useState()
  const [purchaseDetailsData, setPurchaseDetailsData] = useState({
    minimumInvestment:
      {
        initial: {
          amount: undefined
        },
        subsequent: {
          amount: undefined
        }
      }
  })
  const [platfromShareclassData, setPlatformShareclassData] = useState({platformData: {fundShareClassNetAssets: null, dividendDistributionFrequency: null, fundShareClassNetAssetsEndDate: null}})
  const [prospectusData, setProspectusData] = useState({prospectus: {grossExpenseRatio: undefined, netExpenseRatio: undefined}})
  const [yieldData, setYieldData] = useState({value: undefined, date: null})
  const [highlightsTableData, setHighlightsTableData] = useState()
  const [screenerData, setScreenerData] = useState()
  const [fundAttributesData, setFundAttributesData] = useState({is529Only: null, activePassive: null})
  const [annualFeesData, setAnnualFeesData] = useState({ turnoverRatio: undefined })
  const [companyInformationData, setCompanyInformationData] = useState({ fundFamilyName: null })
  const [operationalIndicatorsData, setOperationalIndicatorsData] = useState()
  const [investmentVehicleID, setInvestmentVehicleID] = useState()
  const [mmfProductType, setMMFproductType] = useState('--')
  const [analystRatingsData, setAnalystRatingsData] = useState({});

  function resetStates () {
    setPlatformShareclassData({platformData: {fundShareClassNetAssets: null, dividendDistributionFrequency: null, fundShareClassNetAssetsEndDate: null}})
    setProspectusData({prospectus: {grossExpenseRatio: undefined, netExpenseRatio: undefined}})
    setYieldData({value: undefined, date: null})
    setHighlightsTableData()
    setScreenerData()
    setFundAttributesData({is529Only: null, activePassive: null})
    setAnnualFeesData({ turnoverRatio: undefined })
    setCompanyInformationData({ fundFamilyName: null })
    setOperationalIndicatorsData()
    moveScrollToTopById('fundmarketplace')
  }
  useEffect(() => {
    setFundResearchData({
      ...fundResearchData,
      symbol: props.symbol,
      xid: props.xid,
      shareName: props.name,
      exchange: props.exchange,
      isFundvestEtf: props.IsFVETF,
      productAccess: props.productAccess,
      isMF: true,
      riskTitle: 'Sharpe Ratio',
      type: 'MF'
    })
    // Whenever fund changes - resets states of each sub-component
    resetStates()
    getSimilarShareClasses(props.xid)
    getMfPershingMfdsData(props.CUSIP)
    getFundAttributesData(props.xid)
    getCategoryType(props.xid)
    getCompanyInformationData(props.xid)
    getAnnualFeesData(props.xid)
    getPlatfromShareClassData(props.xid)
    getProspectusData(props.xid)
    getPurchaseDetailsData(props.xid)
    getYieldData(props.xid)
    getUnderlyingBenchmark(props.xid)
    setDeviceType(getDeviceType())
    handleWindowResize((deviceType) => {
      setDeviceType(getDeviceType())
    })
    setShowStyleBox(true)
    // Move scroll bar to top whenever fund changes
    moveScrollToTopById('fundmarketplace')
    if (props?.xid) {
      let callback = (response) => {
        if (!response.isDataRequestComplete) {
          return;
        }
        setAnalystRatingsData({...response});
      }
      getAnalystRatings(props?.xid, callback)
    }
  }, [props.xid])

  useEffect(() => {
    registerF2OnEvent(EVENT_NAMES.GOTO_FUND_PROFILE_TAB, (data) => {
      setActiveTabKey(data.activeTabKey)
      window.scrollTo(0, 0)
    })
  }, [])

  const showStyleBoxHandle = (value) => {
    setShowStyleBox(value)
  }

  const changeHandlerTab = (e) => {
    setActiveTabKey(e)
  }
  function getMfPershingMfdsData (cusip) {
    const callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(responseData)) {
          if (MFSnapshotResultColumnArr.includes(key)) responseData['formatted' + key] = screenerBoolDictionary[value]
        }
        responseData.formattedsettlePurchaseDaysFundsrv = responseData.fundservIndicator === 'Y' ? responseData.settlePurchaseDaysFundsrv : responseData.settlePurchaseDayNonFundsrv
        responseData.formattedsettleRedemptionDaysFundsrv = responseData.fundservIndicator === 'Y' ? responseData.settleRedemptionDaysFundsrv : responseData.settleRedemptionDaysNonFundsrv
        responseData.formattedbnyMellon = isBankCustody(responseData.cusip && !responseData.bnyMellon ? 'N' : responseData.bnyMellon)
        responseData.formattedSPCFTenderIndicator = screenerBoolDictionary[responseData.tenderIndicator]
        responseData.formattedTradeStatus = responseData.tradeStatus === 'Y' ? 'Yes' : 'No'
        responseData.formattedOmnibusIndicator = responseData.omnibusIndicator === 'Y' ? 'Yes' : 'No'
        setMfPershingMfdsData(responseData)
      }
    }

    DataRequest.execute(
      `${URLS.CUSTOM.MF_PERSHING_MFDS_DATA}/`,
      {
        params: {CUSIP: cusip}
      },
      callback,
      null
    )
  }

  function getInvestmentVehicleId (xid) {
    const callback = async (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
         if (response?.data?.investmentVehicleId) {
          const vid = response.data.investmentVehicleId
          setInvestmentVehicleID(vid)
          await getMMFProductType(vid)
         }
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.ASSET_ALLOCATION}/${xid}`,
      null,
      callback
    )
  }

  function getMMFProductType (vid) {
    const callBack = (response) => {
      if (response?.data?.productType) {
        setMMFproductType(response.data.productType)
      }
    }

    DataRequest.execute(
      `${URLS.CUSTOM.MMF_PRODUCT_TYPE}`,
      {
        params: {
          investmentVehicleId: vid || investmentVehicleID
        }
      },
      callBack,
      null
    )
  }

  function getFundAttributesData (venueXid) {
    const callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        responseData.is529Only = responseData.availability.isOnly529 ? 'Yes' : 'No'
        responseData.activePassive = responseData.characteristics.isIndex ? 'Passive' : 'Active'
        setFundAttributesData(responseData)
        setMMFproductType(<Loader />)

        if (responseData?.characteristics?.isMoneyMarket) {
          getInvestmentVehicleId(venueXid)
        }
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_FUNDATTRIBUTE}/${venueXid}`,
      {
        venueXid: venueXid
      },
      callback,
      null
    )
  }
  const getCategoryType = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data) {
        const responseData = response.data || {}
        responseData.items.forEach(element => {
          if (element.type === 'MorningstarCategory') {
            setCategoryData(element)
          }
        })
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_BENCHMARK_INFORMATION, callbackFunction)
  }

  const getPlatfromShareClassData = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data) {
        const responseData = response.data || {}
        let platformData = {}
        responseData.dates.forEach(element => {
          if (element.type === 'inception') {
            platformData = {...element}
          }
        })
        platformData.fundShareClassNetAssets = responseData.fundShareClassNetAssets
        platformData.dividendDistributionFrequency = responseData.dividendDistributionFrequency
        platformData.fundShareClassNetAssetsEndDate = responseData.fundShareClassNetAssetsEndDate
        setPlatformShareclassData(platformData)
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_SHARECLASS, callbackFunction)
  }
  const getProspectusData = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data) {
        const responseData = response.data || {}
        setProspectusData(responseData.items.fundsData[0])
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_PROSPECTUS, callbackFunction)
  }
  const getPurchaseDetailsData = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data) {
        const responseData = response.data || {}
        setPurchaseDetailsData(responseData)
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_PURCHASE_DETAILS, callbackFunction)
  }
  const getAnnualFeesData = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data) {
        const responseData = response.data || {}
        setAnnualFeesData(responseData.fees.annual)
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_ANNUAL_FEES, callbackFunction)
  }
  const getYieldData = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data) {
        const responseData = response.data || {}
        setYieldData(responseData.items[0])
      } else {
        setYieldData({})
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_YIELD, callbackFunction)
  }
  const getCompanyInformationData = (venueXid) => {
    let callbackFunction = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError && response.data) {
        const responseData = response.data || {}
        setCompanyInformationData(responseData)
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_COMPANY_INFORMATION, callbackFunction)
  }
  const getUnderlyingBenchmark = (venueXid) => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data.items.filter(item => item.type === 'ProspectusPrimaryBenchmark')
        let fundHighlightsDataUpdated = {}
        fundHighlightsDataUpdated.underlyingBenchmark = responseData ? responseData[0].name : TEXT_NULL_VALUE
        setBenchmarkData(fundHighlightsDataUpdated)
      } else {
        setBenchmarkData({})
      }
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_BENCHMARK, callback, `venueXids: ${venueXid}`, true)
  }
  function getSimilarShareClasses (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete || response.isError) {
        setShareClassData({isNoDataAvailable: true})
        return
      }
      if (!response.isError) {
        let shareClassesData = response &&
          response.data
        if (shareClassesData.relatedShareclasses && shareClassesData.relatedShareclasses.length > 0) {
          getShareClassCardData(shareClassesData.relatedShareclasses)
        }
      }
    }
    DataRequest.execute(
      URLS.PLATFORM.VIEWSHARECLASSES,
      {
        params: {
          venueXid: [venueXid]
        }
      },
      callback
    )
  }
  function getShareClassCardData (relatedShareclasses) {
    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        responseData.items.map(x => {
          x.formattedSPCFFundservIndicator = x.SPCFFundservIndicator ? screenerBoolDictionary[x.SPCFFundservIndicator] : TEXT_NULL_VALUE
          x.formattedSPCFGrossExpenseRatio = formatNumber(x.SPCFGrossExpenseRatio, {postFix: true})
          x.formattedSPCFProspectusNetExpenseRatio = formatNumber(x.SPCFProspectusNetExpenseRatio, {postFix: true})
          x.formattedSPCFFundNetAssets = x.SPCFFundNetAssets ? '$' + convertToInternationalCurrencySystem(x.SPCFFundNetAssets) : TEXT_NULL_VALUE
          x.label = x.SPCFMStarOverrideFundShareClass ? 'Share Class: ' + x.SPCFMStarOverrideFundShareClass : TEXT_NULL_VALUE
          x.formattedSPCFLoadType = textFormatter(x.SPCFLoadType)
          x.formattedSPCFMStarOverrideCUSIP = textFormatter(x.SPCFMStarOverrideCUSIP)
        })
        const dataForHighlightTable = responseData.items.filter(item => item.SPCFWSODIssue === parseInt(props.xid))
        setScreenerData(dataForHighlightTable[0])
        responseData.shareClassCardData = responseData.items.filter(item => item.SPCFWSODIssue !== props.xid)
        responseData.fixedCardData = responseData.items.filter(item => item.SPCFWSODIssue === parseInt(props.xid))
        setShareClassData(responseData)
      } else setShareClassData({data: null, isNoDataAvailable: true})
    }
    let xidValues = []
    relatedShareclasses.map(item => {
      xidValues.push(item.venueXID.toString())
    })
    let screenerArguments = []
    let newArgument =
    {
      field: 'SPCFWSODIssue',
      conditions: [
        {
          operator: 'Like',
          values: xidValues
        }
      ]
    }
    screenerArguments.push(newArgument)
    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN + '?screenType=MF_ETF'}`,
      buildScreenInputs(0, false, screenerArguments),
      screenDataCallback
    )
  }
  const buildScreenInputs = (_offset, isExportToCsv, screenerArguments, sortArguments) => {
    const screenInputs = {...DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS,
      offset: _offset,
      arguments: screenerArguments || [],
      sortArguments: sortArguments || [
        {
          direction: 'A',
          field: 'SPCFticker'
        }
      ],
      limit: 100,
      resultFields: FundShareClassSnapshotResultArr
    }

    return {
      method: 'POST',
      postData: screenInputs
    }
  }
  const getDataForTooltip = () => {
    return {'CUSIP': props.CUSIP, 'name': props.name}
  }
  const getHighlightsTableData = () => {
    let highlightsData = {}
    highlightsData.symbol = '--'
    highlightsData.Category = '--'
    highlightsData.fundFamilyName = '--'
    highlightsData.Benchmark = '--'
    highlightsData.inceptionDate = '--'
    highlightsData.activePassive = '--'
    highlightsData.grossExpenseRatio = '--'
    highlightsData.netExpenseRatio = '--'
    highlightsData.fundShareClassNetAssets = '--'
    highlightsData.secYield = '--'
    highlightsData.dividendfrequency = '--'
    highlightsData.turnOverRatio = '--'
    highlightsData.minInvestment = '--'
    highlightsData.minSubsequentInvestment = '--'
    highlightsData.load = '--'
    highlightsData.Cusip = '--'

    highlightsData.symbol = (screenerData && screenerData.SPCFticker) || fundResearchData.symbol || TEXT_NULL_VALUE
    highlightsData.Category = (categoryData && categoryData.name) || TEXT_NULL_VALUE

    if (fundAttributesData?.characteristics?.isMoneyMarket) {
      highlightsData.productType = mmfProductType || '--'
    }

    highlightsData.fundFamilyName = (companyInformationData && companyInformationData.fundFamilyName) || TEXT_NULL_VALUE
    highlightsData.Benchmark = (benchmarkData && benchmarkData.underlyingBenchmark) || TEXT_NULL_VALUE
    highlightsData.inceptionDate = platfromShareclassData && formatDate(platfromShareclassData.value, {format: 'MMM D, yyyy'})
    highlightsData.activePassive = (fundAttributesData && fundAttributesData.activePassive) || TEXT_NULL_VALUE
    highlightsData.grossExpenseRatio = prospectusData && formatNumber(prospectusData.prospectus.grossExpenseRatio, {postFix: true})
    highlightsData.netExpenseRatio = prospectusData && formatNumber(prospectusData.prospectus.netExpenseRatio, {postFix: true})
    highlightsData.fundShareClassNetAssets = platfromShareclassData && convertToInternationalCurrencySystem(platfromShareclassData.fundShareClassNetAssets) + ' as of ' + (platfromShareclassData.fundShareClassNetAssetsEndDate ? formatDate(platfromShareclassData.fundShareClassNetAssetsEndDate, {format: 'MMM D, yyyy'}) : '--')
    highlightsData.secYield = yieldData && formatNumber(yieldData.value, {postFix: true}) + ' as of ' + (yieldData.date ? formatDate(yieldData.date, {format: 'MMM D, yyyy'}) : '--')
    highlightsData.dividendfrequency = (mfPershingMfdsData && mfPershingMfdsData.dividendFrequencyText) ? mfPershingMfdsData.dividendFrequencyText : (platfromShareclassData && platfromShareclassData.dividendDistributionFrequency)
    highlightsData.turnOverRatio = annualFeesData && formatNumber(annualFeesData.turnoverRatio, {postFix: true})
    highlightsData.minInvestment = (mfPershingMfdsData || purchaseDetailsData) && formatNumber(!isNaN(mfPershingMfdsData.minimumInitialPurchaseAmount) ? mfPershingMfdsData.minimumInitialPurchaseAmount : purchaseDetailsData.minimumInvestment.initial.amount, {currencyPreFix: true, precision: 2})
    highlightsData.minSubsequentInvestment = (mfPershingMfdsData || purchaseDetailsData) && formatNumber(!isNaN(mfPershingMfdsData.minimumSubsequentPurchaseAmount) ? mfPershingMfdsData.minimumSubsequentPurchaseAmount : purchaseDetailsData.minimumInvestment.subsequent ? purchaseDetailsData.minimumInvestment.subsequent.amount : highlightsData.minSubsequentInvestment, {currencyPreFix: true, precision: 2})
    highlightsData.load = (mfPershingMfdsData && mfPershingMfdsData.loadOrNoLoadIndicatorText) ? mfPershingMfdsData.loadOrNoLoadIndicatorText : (screenerData && screenerData.SPCFLoadType)
    highlightsData.Cusip = (screenerData && screenerData.formattedSPCFMStarOverrideCUSIP) || props.CUSIP || TEXT_NULL_VALUE

    setHighlightsTableData(highlightsData)
  }
  const getOperationalIndicatorsData = () => {
    if ((mfPershingMfdsData && fundAttributesData)) {
      let highlightsData = {}
      highlightsData.minInvestment = '--'
      highlightsData.minSubsequentInvestment = '--'
      highlightsData.minInvestment = (mfPershingMfdsData || purchaseDetailsData) && formatNumber(!isNaN(mfPershingMfdsData.minimumInitialPurchaseAmount) ? mfPershingMfdsData.minimumInitialPurchaseAmount : purchaseDetailsData.minimumInvestment.initial.amount, {currencyPreFix: true, precision: 2})
      highlightsData.minSubsequentInvestment = (mfPershingMfdsData || purchaseDetailsData) && formatNumber(!isNaN(mfPershingMfdsData.minimumSubsequentPurchaseAmount) ? mfPershingMfdsData.minimumSubsequentPurchaseAmount : purchaseDetailsData.minimumInvestment.subsequent ? purchaseDetailsData.minimumInvestment.subsequent.amount : highlightsData.minSubsequentInvestment, {currencyPreFix: true, precision: 2})
      setOperationalIndicatorsData({...mfPershingMfdsData, ...fundAttributesData, ...highlightsData})
    }
  }
  useEffect(() => {
    getOperationalIndicatorsData()
  }, [mfPershingMfdsData, fundAttributesData])
  useEffect(() => {
    getHighlightsTableData()
  }, [categoryData, benchmarkData, purchaseDetailsData, platfromShareclassData, prospectusData, yieldData, fundAttributesData, mfPershingMfdsData, screenerData, annualFeesData, companyInformationData, mmfProductType, investmentVehicleID])
  return (
    <div className={`${styles.fundResearchContainer}`} id='main'>
      <div className={styles.fundQuoteBarContainer}>
        <FundQuoteBar {...fundResearchData} />
      </div>
      <div className={styles.etfResearch}>
        <div className={styles.actionsContainer}>
          <ToolTip id={`${'ActionDropDown'}`} index={-1} type={'MFSnapshot'} icon={'ActionDropDown'} productAccess={props.productAccess} data={getDataForTooltip()} symbol={props.symbol} />
        </div>
        <TabControl id={'MainPage_tab'} ariaLabel={'Snapshot tabs'} activeTab={activeTabKey} changeHandler={(e) => changeHandlerTab(e)} isStickyTabControl={false}>
          <div key={'Snapshot'} label='Snapshot'>
            {
              activeTabKey === 'Snapshot' &&
              <div key='Snapshot' label='Snapshot'>
                <div className={styles.snapshotContainer}>
                  <div className={`${styles.leftSectionContainer} ${styles.marginSetter}`}>
                    <Performance10KFund wsodIssue={fundResearchData.xid} />
                  </div>
                  <div className={styles.mobilePerformance}>
                    <PerformanceDisclosure />
                  </div>
                  <div className={styles.rightSectionContainer} >
                    <div className={styles.snapshotRating} >
                      <SnapshotRatings xid={fundResearchData.xid} analystRatingsData={analystRatingsData} />
                    </div>
                  </div>
                </div>
                <div className={styles.desktopPerformance}>
                  <PerformanceDisclosure />
                </div>

                <div className={styles.appGap} />
                <div className={styles.holdingAndAllocationContainer}>
                  <div className={`${styles.leftSectionContainer} ${styles.leftSectionAdjust}`}>
                    <div>
                      <div className={styles.headerHighlightTable}> {props.symbol + ' Highlights'} </div>
                      <SummaryTable data={highlightsTableData} columnDef={FundResearchConstants.highlightsTable} type='MFSnapshot' />
                    </div>
                  </div>
                  <div className={styles.rightSectionContainer}>
                    <div className={styles.operationalIndicators} >
                      <SnapshotHighlights data={operationalIndicatorsData} symbol={fundResearchData.symbol} type='MFOperationalIndicators' header='Operational Indicators' />
                    </div>
                  </div>
                </div>
                <div className={styles.appGap} />
                <div className={styles.holdingAndAllocationContainer}>
                  <div className={`${styles.leftSectionContainer} ${styles.leftSectionAdjust}`}>
                    <div className={styles.profile}>
                      <SnapshotProfile xid={fundResearchData.xid} isMF={fundResearchData.isMF} />
                    </div>
                  </div>
                  <div className={styles.rightSectionContainer}>
                    <div className={styles.fundStrategy}>
                      <SnapshotStrategy xid={fundResearchData.xid} />
                    </div>
                  </div>
                </div>
                <div className={styles.appGap} />
                <div className={styles.holdingAndAllocationContainer}>
                  <div className={`${styles.leftSectionContainer} ${styles.leftSectionAdjust}`}>
                    <div>
                      <TopHolding xid={fundResearchData.xid} isPortfolioEnabled />
                    </div>
                  </div>
                  <div className={styles.rightSectionContainer}>
                    <div className={styles.assetAllocation} >
                      <AssetAllocationTable xid={fundResearchData.xid} isETF={isETF} />
                    </div>
                  </div>
                </div>
                <div className={styles.snapshotContainer}>
                  <div className={`${styles.leftSectionContainer} ${styles.leftSectionAdjust}`}>
                    <div>
                      <ESGScores symbol={fundResearchData.symbol} analystRatingsData={analystRatingsData} />
                    </div>
                  </div>
                  <div className={styles.rightSectionContainer}>
                    <ESGOverAllScore symbol={fundResearchData.symbol} analystRatingsData={analystRatingsData} />
                    <div />
                  </div>
                </div>
                <div className={styles.appGap} />

                <div className={styles.snapshotContainer}>
                  <div className={styles.leftSectionContainer} >
                    <SnapshotPerformance symbol={fundResearchData.symbol} isMF={fundResearchData.isMF} />

                  </div>
                  <div className={styles.rightSectionContainer} />
                </div>

                <div className={styles.appGapOnlyBorder} />
                <div className={`${styles.stockValuationContainer} ${styles.sharpeRatioContainer}`}>
                  <div className={styles.leftSectionContainer} >
                    <SharpeRatio {...fundResearchData} />
                  </div>
                  <div className={styles.rightSectionContainer} >
                    <div className={styles.ratingsContainer} >
                      {/* <SnapshotRatings xid={fundResearchData.xid} /> */}
                    </div>
                    <div className={styles.styleBoxContainer}>
                      {showStyleBox &&
                      <div className={`${styles.styleBox} ${styles.styleBoxAlign}`}>
                        <StyleBox xid={fundResearchData.xid} showStyleBoxHandle={showStyleBoxHandle} />
                      </div>
                      }
                    </div>
                  </div>
                </div>
                <div className={showStyleBox ? styles.appGap : styles.appGapOnlyBorder} />
                <div className={styles.snapshotShareClass}>
                  <SnapshotShareclass header={'Share Classes'} moduleName={'MFResearch'} data={shareClassData} venueXid={props.xid} deviceTypeItem={deviceTypeItem} />
                </div>
                <div className={styles.appGapOnlyBorder} />
                <div className={styles.similarFundsContainer}>
                  <SimilarFunds xid={fundResearchData.xid} header={'Similar Funds'} textLine={'Based on category, 3Yr RSquared, and expense ratio. '} moduleName={'MFSimilarFunds'} />
                </div>
              </div>
            }
          </div>
          <div key={'ESG Metrics'} label='ESG Metrics'>
            {
              activeTabKey === 'ESG Metrics' && <FundPortfolio {...props} />
            }
          </div>
        </TabControl>
        <footer>
          <ImportantDisclosure />
          <Footer />
        </footer>
      </div>
    </div>
  )
}

FundResearch.propTypes = {
  symbol: PropTypes.string,
  xid: PropTypes.number,
  name: PropTypes.string,
  exchange: PropTypes.string,
  IsFVETF: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool]),
  productAccess: PropTypes.object,
  CUSIP: PropTypes.string,
  isMF: PropTypes.bool
}
export default FundResearch
