import React from 'react'
import styles from './SummaryTable.module.scss'
import PropTypes from 'prop-types'
import Loader from '../../../../../Lib/common/Loader'
import LinkButton from '../../../../../Lib/LinkButton/LinkButton'
import { emitF2Event } from '../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import { TAB_NAMES } from '../../../../../../utils/appConstants'

const SummaryTable = (props) => {
  const portfolioHandler = () => {
    emitF2Event(EVENT_NAMES.GOTO_FUND_PROFILE_TAB, {activeTabKey: TAB_NAMES.PORTFOLIO})
  }
  return (
    !props.data
      ? <Loader spinnerSize={'2x'} msg={'Loading'} />
      : <div className={`${styles.summaryTableContainer} ${props.isNonREIT ? styles.nonReit : ''} ${props.type === 'MFSnapshot' ? styles.MFSnapshot : ''}`}>
        <table className={`${styles.table}`} role='presentation'>
          <tbody>
            { props.columnDef.map((x, index) =>
            (x.isMoneyMarket && !props.data.productType)
              ? '' : <tr key={`summary-tr-${index}`}>
                <td>
                  {(x.labelDescription)
                    ? <div className={styles.tooltip}>
                      {x.label}
                      <span className={styles.tooltiptext}>{x.labelDescription}</span>
                    </div>
                    : x.label === '' ? <div className={styles.headerWithSeparator} /> : x.label
                  }
                </td>
                <td><span className={`${styles.tooltiptext} ${styles['float-right']}`}>{ props.data[x.field] || 'n.a.'}</span>
                </td>
              </tr>
            )
            }
          </tbody>
        </table>
        {props.type === 'MFSnapshot' && <div className={styles.linkButton}>
          <LinkButton label={'Go to ESG Metrics'} onClick={portfolioHandler} />
        </div>}
      </div>
  )
}

SummaryTable.propTypes = {
  data: PropTypes.object,
  columnDef: PropTypes.array,
  isNonREIT: PropTypes.bool,
  type: PropTypes.string
}

export default SummaryTable
