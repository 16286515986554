/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styles from './FundPortfolio.module.scss'
import SustainabilityScore from '../Portfolio/SustainabilityScore/SustainabilityScore'
import ESGOverallScore from '../Portfolio/ESGOverallScore/ESGOverallScore'
import EsgAUMRiskScore from '../Portfolio/EsgAUMRiskScore/EsgAUMRiskScore'
import AUMControversies from '../Portfolio/AUMControversies/AUMControversies'
import Divider from '../../../../Lib/Divider/Divider'
import CarbonRiskHighlights from '../Portfolio/CarbonRiskHighlights/CarbonRiskHighlights'
import CarbonRiskScore from '../Portfolio/CarbonRiskScore/CarbonRiskScore'
import FossilFuelInvolvement from '../Portfolio/FossilFuelInvolvement/FossilFuelInvolvement'
import {getAnalystRatings} from "../Shared/CompareProgressBar/ESGCommonMethods";


const MFPortfolio = (props) => {
  const [analystRatingsData, setAnalystRatingsData] = useState({});
  useEffect(() => {
    if (props?.xid) {
      let callback = (response) => {
        if (!response.isDataRequestComplete) {
          return;
        }
        setAnalystRatingsData({...response});
      }
      getAnalystRatings(props?.xid, callback)
    }
}, [props?.xid]);

  return (
    <div className={styles.fundPortfolioContainer}>
      <div className={styles.container}>
        <div className={`${styles.leftSectionContainer}`}>
          {
            <SustainabilityScore xid={props.xid} analystRatingsData={analystRatingsData} />
          }
        </div>
        <div className={styles.rightSectionContainer}>
          {
            <ESGOverallScore xid={props.xid} analystRatingsData={analystRatingsData} />
          }
        </div>
      </div>
      <Divider />
      <div className={styles.container}>
        <div className={`${styles.leftSectionContainer}`}>
          {
            <EsgAUMRiskScore xid={props.xid} analystRatingsData={analystRatingsData} />
          }
        </div>
        <div className={styles.rightSectionContainer}>
          {
            <AUMControversies xid={props.xid} analystRatingsData={analystRatingsData} />
          }
        </div>
      </div>
      <Divider />
      <div className={styles.container}>
        <div className={`${styles.leftSectionContainer}`}>
          {
            <CarbonRiskScore xid={props.xid} />
          }
        </div>
        <div className={styles.rightSectionContainer}>
          {
            <React.Fragment><CarbonRiskHighlights xid={props.xid} /><FossilFuelInvolvement xid={props.xid} /></React.Fragment>
          }
        </div>
      </div>
    </div>
  )
}

MFPortfolio.propTypes = {
  symbol: PropTypes.string,
  type: PropTypes.string,
  xid: PropTypes.number
}

export default MFPortfolio
