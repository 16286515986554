/* eslint-disable */
import "babel-polyfill";
import React from "react";
import TestTokenEvents from "./ClientApplication/TokenEvents";
import styles from './DevelopComponentPage.module.scss'
import ChartsContainer from '../Charts/ChartsContainer'
import NewsFilterBar from "../News/NewsFilters/NewsFilterBar";
// import OtherWaysToInvestContainer from "../Stocks/ESGScores/OtherWaysToInvest/OtherWaysToInvestContainer";
import FundMarketplace from '../WorkstreamII/pershing-netxwealth-universalsearch/components/FundMarkePlace/FundMarkePlace'
import WatchlistDetails from '../Watchlists/View/WatchlistDetails/WatchlistDetails'
import WatchlistsLanding from '../Watchlists/View/Watchlists'
import Ownership from '../Stocks/Ownership/Ownership'
import OptionsChainContainer from '../Stocks/Options/OptionsChainContainer'
class TestPage extends React.Component {
  constructor() {
    super()
  }
  componentDidMount () {
    // setAccessToken()
  }

  render () {
    return <div className={styles.container}>
      <FundMarketplace />
      {/* <NewsForYou /> */}
      {/* <ChartsContainer symbol='aapl' /> */}
      {/* <HistoricalPrices symbol='CHRA'/> */}
      {/* <OtherWaysToInvestContainer symbol='msft'/> */}
      {/* <NewsFilterBar /> */}
      {/* <NewsAndResearch /> */}
      {/* <WatchlistsLanding /> */}
      {/* <WatchlistDetails watchlistId='40C90D43-CB69-4700-B7C6-B1229BA708EB' /> */}
      {/* <FundRightPanelEsgContainer symbol='AAAIX' /> */}
      {/* <NXIEsgFundContainer symbol='AAAIX'  /> */}
      {/* <Ownership symbol={'AAPL'} /> */}
      
      <OptionsChainContainer symbol={'AAPL'} />
    <TestTokenEvents />
    </div>
  }
}

export default TestPage