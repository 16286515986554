import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import ETFPortfolio from './ETFPortfolio'
import styles from './FundPortfolio.module.scss'
import MFPortfolio from './MFPortfolio'

const FundPortfolio = (props) => {
  useEffect(() => {
  }, [])

  return (
    <div className={styles.fundPortfolioContainer}>
      {props.type === 'MF' && <MFPortfolio {...props} />}
      {props.type === 'ETF' && <ETFPortfolio {...props} />}
    </div>
  )
}

FundPortfolio.propTypes = {
  symbol: PropTypes.string,
  type: PropTypes.string,
  xid: PropTypes.number
}

export default FundPortfolio
