export const CALLS = 'Calls'
export const PUTS = 'Puts'
export const CALLS_AND_PUTS_LABELS_MAPPING = {
  ALL: 'Calls & Puts',
  CALLS: 'Calls Only',
  PUTS: 'Puts Only'
}

export const CALLS_AND_PUTS_VALUES_MAPPING = {
  'Calls & Puts': 'all',
  'Calls Only': 'call',
  'Puts Only': 'put'
}

export const STRIKES_LABELS_MAPPING = {
  5: '10 Strikes',
  10: '20 Strikes',
  15: '30 Strikes',
  ALL: 'All'
}

export const STRIKES_VALUES_MAPPING = {
  '10 Strikes': 5,
  '20 Strikes': 10,
  '30 Strikes': 15,
  'All': 'all'
}

export const OPTIONS_CHAIN_FILTERS_NAMES = {
  CALLSaNDPUTS: 'callsAndPuts',
  STRIKE: 'strike'
}

export const OPTIONS_CHAIN_FILTERS = [
  {
    'name': OPTIONS_CHAIN_FILTERS_NAMES.CALLSaNDPUTS,
    'resetDefaultValue': CALLS_AND_PUTS_LABELS_MAPPING.ALL,
    'options': [
      CALLS_AND_PUTS_LABELS_MAPPING.ALL,
      CALLS_AND_PUTS_LABELS_MAPPING.CALLS,
      CALLS_AND_PUTS_LABELS_MAPPING.PUTS
    ]
  },
  {
    'name': OPTIONS_CHAIN_FILTERS_NAMES.STRIKE,
    'resetDefaultValue': STRIKES_LABELS_MAPPING['10'],
    'options': [
      STRIKES_LABELS_MAPPING['5'],
      STRIKES_LABELS_MAPPING['10'],
      STRIKES_LABELS_MAPPING['15'],
      STRIKES_LABELS_MAPPING.ALL
    ]
  }
]

export const TICKER_LABELS = {
  STRIKE: 'Strike',
  BID: 'Bid',
  ASK: 'Ask',
  CHANGE: 'Change',
  DAYRANGE: 'Day Range',
  DELIVERABLE_SHARES: 'Deliverable Shares',
  VOLUME: 'Volume',
  OPEN_INTEREST: 'Open Interest',
  LAST_PRICE: 'Last Price'
}
