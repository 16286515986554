import React, {useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './TopInstitutionalHolders.module.scss'
import { registerAccessTokenUpdatedF2OnEvent, registerF2OnEvent } from '../../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../utils/f2Constants'
import {TOP_INSTITUTIONAL_HOLDER_CONSTANTS} from './TopInstitutionalHoldersConstants.js'
import {formatNumber, numberFormatter} from '../../../../utils/formatter'
import DataRequest from '../../../../services/DataRequest'
import {URLS, TYPE_OF_VALUE} from '../../../../utils/appConstants'
import Loader from '../../../Lib/common/Loader'
// import { sortByKey } from '../../../../utils/utilities'
import {makeXrefCall} from '../../../../services/XrefAPICall'
import TopInstitutionalHoldersTable from './TopInstitutionalHoldersTable/TopInstitutionalHoldersTable'

function TopInstitutionalHolders ({symbol}) {
  const [tableData, setTableData] = useState(setTopInstitutionalHoldersState(true, null, false))
  const [apiInput, setApiInput] = useState(setInitialStateInput())

  useEffect(() => {
    makeXrefCall(symbol, xrefCallBackHandler)
    registerAccessTokenUpdatedF2OnEvent(() => {
      setTableData(setTopInstitutionalHoldersState(true, null, false))
      makeXrefCall(symbol, xrefCallBackHandler)
    })
    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (symbolUpdatedObject) => {
      setTableData(setTopInstitutionalHoldersState(true, null, false))
      makeXrefCall(symbolUpdatedObject.symbol, xrefCallBackHandler)
    })
  }, [])

  useEffect(() => {
    if (apiInput.venueXid) {
      setTableData(setTopInstitutionalHoldersState(true, null, false))
      getTopFundsholders()
    }
  }, [apiInput])

  function xrefCallBackHandler (response) {
    if (!response.isDataRequestComplete) {
      return
    }
    if (response.isError) {
      setTableData(setTopInstitutionalHoldersState(false, null, true))
    } else {
      let data = response && response.data
      setInputApiState('venueXid', data.xids && data.xids.venue)
    }
  }
  function setTopInstitutionalHoldersState (isLoading, data, isError) {
    return {
      isLoading: isLoading,
      data: data,
      isError: isError,
      isUpward: false
    }
  }

  function setInputApiState (keytoUpdate, value) {
    setApiInput({...apiInput, ...{ [keytoUpdate]: value }})
  }

  function getTopFundsholders () {
    let newApiInput = {...apiInput}
    DataRequest.execute(`${URLS.PLATFORM.MORNINGSTAR_OWNERSHIP_INSTITUTION_DETAILS}/${newApiInput.venueXid}`,
      {
        params: newApiInput
      },
      setTopInstitutionalHolders,
      null
    )
  }

  function setTopInstitutionalHolders (response) {
    if (!response.isDataRequestComplete) {
      return
    }
    if (response.isError) {
      setTableData(setTopInstitutionalHoldersState(false, null, true))
    } else {
      let data = response && response.data
      prepareData(data)
    }
  }

  function prepareData (responseData) {
    let dataDocList = []
    if (responseData && responseData.items) {
      responseData.items.forEach((_item) => {
        let dataObj = {}
        TOP_INSTITUTIONAL_HOLDER_CONSTANTS.HEADERS_TABLE.forEach(labelMap => {
          dataObj[labelMap.label] = getFormattedValue(_item[labelMap.map], labelMap.type, labelMap.defaultValue)
        })
        dataDocList.push(dataObj)
      })
    }

    setTableData(setTopInstitutionalHoldersState(false, dataDocList, false))
  }

  function getFormattedValue (value, type, defaultValue) {
    if (type && value !== defaultValue) {
      if (type === TYPE_OF_VALUE.PERCENTAGE) {
        return formatNumber(value, { precison: TOP_INSTITUTIONAL_HOLDER_CONSTANTS.PRECISION, postFix: true, negSign: true })
      } else if (type === TYPE_OF_VALUE.NUMBER) {
        return numberFormatter(value, '')
      }
    }
    return value
  }
  function setInitialStateInput () {
    return {
      rankType: 'netPercentOfSharesOut',
      numberOfResults: TOP_INSTITUTIONAL_HOLDER_CONSTANTS.LIMIT_RECORDS,
      venueXid: null
    }
  }
  return (
    <div className={styles.TopInstitutionalHoldersContainer}>
      <header className={styles.headerContainer}>{TOP_INSTITUTIONAL_HOLDER_CONSTANTS.HEADER}</header>
      {
        tableData.isLoading ? <Loader ready={false} spinnerSize={'2x'} />
          : <React.Fragment>
            <div className={styles.tableParent}>
              <TopInstitutionalHoldersTable data={tableData.data} />
            </div>
          </React.Fragment>
      }
    </div>
  )
}

export default TopInstitutionalHolders
TopInstitutionalHolders.propTypes = {
  symbol: PropTypes.string.isRequired
}
