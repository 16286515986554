/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react'
import { Modcharts } from '@markit/modcharts/dist/modcharts.umd.js'
import '../../../../../Lib/ModChartsCss/ModCharts.scss'
import { GROWTH_10K_PERFORMANCE } from '../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import styles from './PerformanceChart10K.module.scss'
import PropTypes from 'prop-types'
import Loader from '../../../../../Lib/common/Loader'
import NoData from '../../../../../Lib/NoData/NoData'
import { convertFromOADate } from '../../utils/formatter'

const PerformanceChart = (props) => {
  const [chartLoading, setChartLoading] = useState(true)
  const [performanceChart, setChart] = useState(null)
  const [timePeriod, setTimePeriod] = useState(props.chartInputs['timePeriod'])
  const [chartType, setChartType] = useState(props.chartInputs.chartType)
  const [chartData, setChartData] = useState(props.ChartData)
  if ((timePeriod !== props.chartInputs.timePeriod || chartType !== props.chartInputs.chartType) && chartData !== props.ChartData) {
    document.getElementById('performanceChartDomElement').innerHTML = ''
    setChart(null)
    setTimePeriod(props.chartInputs.timePeriod)
    setChartType(props.chartInputs.chartType)
    setChartLoading(true)
    setChartData(props.ChartData)
  }

  useEffect(() => {
    if (!performanceChart && document.getElementById('performanceChartDomElement')) {
      initNewChart()
    }
  }, [performanceChart])

  const initNewChart = useCallback(() => {
    const chart = new Modcharts('#performanceChartDomElement')
    const authToken = DataRequest.getAccessToken()
    chart.setAuthToken(authToken)
    const chartConfig = {
      params: {
      customData: {
         'chartData': props.ChartData
        },
        style: {
          panel: {
            border: {
              color: {
                bottom: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.BORDERBOTTOMCOLOR,
                top: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                default: 'transparent'
              }
            },
            grid: {
              color: 'transparent',
              horizontal: {
                color: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                lineDash: ''
              },
              vertical: {
                alt: { color: 'transparent' }
              }
            },
            xAxis: {
              label: {
                padding: {
                  top: 10,
                  bottom: 5
                },
                margin: {
                  top: 30,
                },
                textAlign: "center",
              },
              font: {
                color: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                family: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                size: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
              }
            },
            yAxis: {
              label: {
                padding: {
                  left: 13
                }
              },
              font: {
                color: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                family: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                size: GROWTH_10K_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
              }
            },
            margin: {
              bottom: 30
            }
          }
        }
   },
   panels: [{
      indicators: [{
         id: 'custom',
         markerType: chartType,
         datasetId: 'chartData',
         style: {
          fillColor: GROWTH_10K_PERFORMANCE.INDEXES.lightColor,
          fillColorStop: GROWTH_10K_PERFORMANCE.INDEXES.lightColor,
          shape: { type: 'circle', color: GROWTH_10K_PERFORMANCE.INDEXES.lightColor, fillColor: GROWTH_10K_PERFORMANCE.INDEXES.lightColor },
          width: 1
        }
     },
     {
      id: 'custom',
      markerType: 'line',
      datasetId: 'chartData',
      style: {
        color: GROWTH_10K_PERFORMANCE.INDEXES.darkColor,
        shape: { type: 'circle', color: GROWTH_10K_PERFORMANCE.INDEXES.darkColor, fillColor: GROWTH_10K_PERFORMANCE.INDEXES.darkColor, length: 4 },
        width: 1
      }
    }]
   }]
    }
    chart.load(chartConfig, () => {
      setChartLoading(false)
      setChart(chart)
      setChartData(chart.data.chartData)
    })

    chart.eventEmitter.on('DATA_LOAD_STOP', () => {
      setChartLoading(false)
    })
  })

  return (
    <div className={styles.performanceChartContainer} >
      {chartLoading && <div className={`${styles.modalPopUpContentContainer} ${styles.loaderContainer}`}>
        <Loader ready={!chartLoading}
          spinnerSize={'2x'}
          msg={'Loading'}
        />
      </div>
      }
      <div id={'performanceChartDomElement'} aria-hidden='true' className={styles.performanceChartDomElement} name={props.symbol || props.name} />
      {(!chartData || !chartData.length) && <div className={styles.noDataDiv}><NoData /></div>}
      <div className='sr-only'>
        <table>
          <caption>Hypothetical Growth of 10K Data</caption>
          <thead>
          <tr>
            <th>Date</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          {
            props.ChartData.map((item, index) => {
             return <tr key={'chartData-' + index}>
                <td>{convertFromOADate(item.date)}</td>
                <td>{item.value}</td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}

PerformanceChart.propTypes = {
  chartInputs: PropTypes.object,
  ChartData: PropTypes.array,
  symbol: PropTypes.string,
  name: PropTypes.string
}

export default PerformanceChart