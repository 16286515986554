import React, { useEffect, useState, useRef } from 'react'
import TabControl from '../Shared/TabControl/TabControl'
import FundScreener from '../Screener/Fund/FundScreener'
import FundCompare from '../FundCompare/FundCompare'
import EtfSnapshot from '../ETFResearch/ETFResearch'
import FundSnapshot from '../FundResearch/FundResearch'
import { registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent } from '../../../../../utils/f2Methods'
import { URLS } from '../../../../../utils/appConstants'
import DataRequest from '../../../../../services/DataRequest'
import { EVENT_NAMES } from '../../../../../utils/f2Constants'
import { getSymbolContext, isInternalEnvironment, getContextBySymbol } from '../utils/utilities'
import { FUNDMARKETPLACE_TABS, EtfType, MOD_GOTO_PAGES } from '../utils/appConstants'
import styles from './FundMarketPlace.module.scss'
import NoResults from '../Shared/NoResults/NoResults'
import PropTypes from 'prop-types'

const FundMarkePlace = (props) => {
  const [activeTabKey, setActiveTabKey] = useState('Screener')
  const [modCompareContext, setModCompareContext] = useState({})
  const [modProfileContext, setModProfileContext] = useState({})
  const [modScreenerContext, setModScreenerContext] = useState({})
  const [productAccess, setProductAccess] = useState({})
  const [isMF, setIsMF] = useState(true)
  const [screenerName, setScreenerName] = useState([])
  const fundRef = useRef(null)
  useEffect(() => {
    getProductAccess()
    registerAccessTokenUpdatedF2OnEvent(() => {
      getProductAccess()
    })
    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (dataEmit) => {
      if (dataEmit && dataEmit.skipListenerExecution === true) {
        return
      }

      registeringF2Event(dataEmit)
    })
    // EVENT_NAMES.MOD_GOTO is listened when we run this application in our internal environment,
    // Client do not listen this event on this page. For internal environment, when navigation is
    // from global search to here, then EVENT_NAMES.MOD_GOTO is to be listened and when navigation from internal
    // components then EVENT_NAMES.SYMBOL_CHANGED_EVENT is listened
    if (isInternalEnvironment()) {
      registerF2OnEvent(EVENT_NAMES.MOD_GOTO, (dataEmit) => {
        registeringF2Event(dataEmit)
      })
    }
    if (props.symbol && props.gotoPage === MOD_GOTO_PAGES.fund) { // changes for PRW-1795
      getContext(props)
    } else {
      getSavedContext()
    }
  }, [])

  const updateScreenerName = (screenerName) => {
    setScreenerName(screenerName)
  }

  function registeringF2Event (dataEmit) {
    if (dataEmit && dataEmit.gotoPage === MOD_GOTO_PAGES.fund) {
      getContext(dataEmit)
    }
  }

  async function getContext (dataEmit) {
    let symbol = decodeURIComponent(dataEmit.symbol)
    if (['FundVest', 'FundVest ETF', 'FundCenter', 'AllMF', 'AllETF'].includes(decodeURIComponent(symbol))) { // Changes for PRW-1895
      let data = getContextBySymbol(symbol)
      setFundContext(data)
    } else {
      if (dataEmit.compareData) {
        setFundContext(dataEmit)
      } else {
        await getSymbolContextData(symbol, dataEmit.activeTabKey).then(function (result) {
          setFundContext(result)
        })
      }
    }
  }

  function getSavedContext () {
    let data = JSON.parse(sessionStorage.getItem('fund-context'))
    if (data !== null) {
      let activeTab = FUNDMARKETPLACE_TABS[data.activeTabKey]
      if (activeTab.toLowerCase() === 'compare') setModCompareContext(data)
      else if (activeTab.toLowerCase() === 'fund profile') setModProfileContext(data)
      else setModScreenerContext(data)
      setIsMF(!EtfType.includes(data.type))
      setActiveTabKey(activeTab)
    } else {
      setModScreenerContext({ symbol: 'pageLoad' })
    }
  }

  function changeHandlerTab (e) {
    setActiveTabKey(e)
    setModScreenerContext({ ...modScreenerContext, screenerPillChange: undefined, navigationFromViewAllLinkFlag: undefined, symbol: undefined, isTabChange: true, screenerName: screenerName })
  }

  function fundProfile () {
    if (activeTabKey.toLowerCase() === 'fund profile') {
      if (modProfileContext && (modProfileContext.CUSIP || modProfileContext.xid)) {
        return isMF ? <FundSnapshot {...modProfileContext} productAccess={productAccess} /> : <EtfSnapshot {...modProfileContext} />
      } else {
        return <NoResults iconName={'fileMagnifyingGlass'} data={[`You haven't selected a fund to view yet.`, `Use the above search bar to search for an individual fund to view its details.`]} iconStyles={'noSymbolSelectedIcon'} msgStyles={'noSymbolSelectedMsg'} containerHeight={{ 'height': '764px' }} />
      }
    }
    return ''
  }

  function setFundContext (data) {
    let activeTab = FUNDMARKETPLACE_TABS[data.activeTabKey]
    if (activeTab.toLowerCase() === 'compare') { setModCompareContext(data) } else if (activeTab.toLowerCase() === 'fund profile') { setModProfileContext(data) } else { setModScreenerContext(data) }
    if (data.type) {
      setIsMF(!EtfType.includes(data.type))
    }
    setActiveTabKey(activeTab)
    sessionStorage.setItem('fund-context', JSON.stringify(data))
  }
  const getProductAccess = () => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response && response.data
        setProductAccess(responseData)
      }
    }

    DataRequest.execute(
      `${URLS.CUSTOM.PRODUCTACCESS}/`,
      {},
      callback,
      null
    )
  }

  async function getSymbolContextData (symbol, activeTabKey) {
    let modContext = await getSymbolContext(symbol, activeTabKey)
    return modContext
  }

  function tabChangePersistState (screenerFilterOptions) {
    setModScreenerContext({ ...modScreenerContext, ...screenerFilterOptions })
  }
  return (
    <div key='fundmarketplace'>
      <div>
        <TabControl className={styles.fundMarketPlaceTabs} ref={fundRef} id={'fundmarketplace'} ariaLabel={'Fund MarketPlace'} activeTab={activeTabKey} changeHandler={(e) => changeHandlerTab(e)} >
          <div key={'Screener'} label='Screener'>
            {activeTabKey.toLowerCase() === 'screener' && <FundScreener tabChangePersistState={tabChangePersistState} {...modScreenerContext} updateScreenerName={updateScreenerName} />}
          </div>
          <div key={'Compare'} label='Compare'>
            {activeTabKey.toLowerCase() === 'compare' && <FundCompare {...modCompareContext} />}
          </div>
          <div key='profile' label='Fund Profile' >
            {fundProfile()}
          </div>
        </TabControl>
      </div>
    </div>
  )
}
FundMarkePlace.propTypes = {
  symbol: PropTypes.string,
  gotoPage: PropTypes.string,
  activeTabKey: PropTypes.string
}

export default FundMarkePlace
