import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Loader from '../../Lib/common/Loader'
import Nodata from '../../Lib/NoData/NoData'
import ExpandCollapseButtons from '../../Lib/ExpandCollapseButtons/ExpandCollapseButtons'
import CollapsibleCard from '../../Lib/CollapsibleCard/CollapsibleCard'
import OptionsChainFilters from './OptionsChainFilters/OptionsChainFilters'
import OptionsTable from './OptionsTable/OptionsTable'
import StrikeColumn from './StrikeColumn/StrikeColumn'
import DataRequest from '../../../services/DataRequest'
import { URLS } from '../../../utils/appConstants'
import { getDeviceType, deviceType } from '../../../utils/utilities'
import { diffInDays, getCurrentDate, formatDate } from '../../../utils/formatter'
import {
  CALLS,
  PUTS,
  OPTIONS_CHAIN_FILTERS_NAMES,
  OPTIONS_CHAIN_FILTERS,
  CALLS_AND_PUTS_LABELS_MAPPING,
  CALLS_AND_PUTS_VALUES_MAPPING,
  STRIKES_LABELS_MAPPING,
  STRIKES_VALUES_MAPPING
} from './OptionsConstants'
import styles from './OptionsChain.module.scss'
import PremiumPriceMovement from './PremiumPriceMovement/PremiumPriceMovement'
import OptionsQuoteBar from './OptionsQuoteBar/OptionsQuoteBar'

export default function OptionsChain ({xref, symbol}) {
  const callsPutsInitialState = () => {
    if (deviceType.Mobile === getDeviceType()) {
      callPut = CALLS_AND_PUTS_LABELS_MAPPING.CALLS
      return CALLS_AND_PUTS_LABELS_MAPPING.CALLS
    }
    return CALLS_AND_PUTS_LABELS_MAPPING.ALL
  }

  const headers = ['Bid', 'Ask', 'Last Price', 'Volume', 'Open Int', 'Delta']
  const callsHeader = ['Last Price', 'Ask', 'Bid', 'Volume', 'Open Int', 'Delta']
  const WEEKLY = 'all'
  const TIMEFRAME = {
    'Weekly': 'W',
    'Monthly': 'M',
    'Quaterly': 'Q'
  }
  let callPut = CALLS_AND_PUTS_LABELS_MAPPING.ALL
  let [strike, setStrike] = useState(10)

  const [optionChainFilters, setOptionChainFilter] = useState(OPTIONS_CHAIN_FILTERS)

  const [callsAndPutsselectedOption, setCallsAndPutsSelectedOption] = useState(callsPutsInitialState())

  const [expirationDates, setExpirationDates] = useState([])
  const [optionChainData, setOptionChainData] = useState([])

  const [selectedTab, setSelectedtab] = useState(CALLS)

  const [isReady, setIsReady] = useState(false)
  const [isError, setError] = useState(false)
  const [optionChainTableRowData, setOptionChainTableRowData] = useState({greeks: {isError: false, isLoading: false}})

  const [currentExpirationDate, setCurrentExpirationDate] = useState(null)
  const [clickedTab, setClickedTab] = useState(null)
  const [isPriceMoment, setIsPriceMoment] = useState(false)
  const [adjustmentFlag, setAdjustmentFlag] = useState(false)
  const [disableExpandAllBtn, setDisableeExpandAllBtn] = useState(false)
  const [disableCollapseAllBtn, setDisableCollapseAllBtn] = useState(false)

  useEffect(() => {
    const allExpanded = optionChainData.every(x => x.isCollapsed)
    const allCollapsed = optionChainData.every(x => !x.isCollapsed)
    setDisableeExpandAllBtn(allExpanded)
    setDisableCollapseAllBtn(allCollapsed)
  }, [JSON.stringify(optionChainData)])

  const dropDownsHandler = (element, name) => {
    const selected = element.getAttribute('data-value')
    if (name === OPTIONS_CHAIN_FILTERS_NAMES.CALLSaNDPUTS) {
      optionChainFilters[0].resetDefaultValue = selected
      callPut = selected
      setCallsAndPutsSelectedOption(selected)
    } else if (name === OPTIONS_CHAIN_FILTERS_NAMES.STRIKE) {
      optionChainFilters[1].resetDefaultValue = selected
      strike = STRIKES_VALUES_MAPPING[selected]
      setStrike(strike)
    }
    setOptionChainFilter([...optionChainFilters])
    expirationDates?.length && getOptionChain(expirationDates, strike)
    setIsPriceMoment(false)
  }

  const tabsHandler = (tab) => {
    setSelectedtab(tab)
    if (tab === CALLS) {
      callPut = CALLS_AND_PUTS_LABELS_MAPPING.CALLS
      optionChainFilters[0].resetDefaultValue = CALLS_AND_PUTS_LABELS_MAPPING.CALLS
      setCallsAndPutsSelectedOption(CALLS_AND_PUTS_LABELS_MAPPING.CALLS)
    } else {
      callPut = CALLS_AND_PUTS_LABELS_MAPPING.PUTS
      optionChainFilters[0].resetDefaultValue = CALLS_AND_PUTS_LABELS_MAPPING.PUTS
      setCallsAndPutsSelectedOption(CALLS_AND_PUTS_LABELS_MAPPING.PUTS)
    }
    expirationDates?.length && getOptionChain(expirationDates, strike)
    setIsPriceMoment(false)
  }

  const resetOptionFilters = () => {
    callPut = callsPutsInitialState()
    optionChainFilters[0].resetDefaultValue = callsPutsInitialState()
    setCallsAndPutsSelectedOption(callsPutsInitialState())

    optionChainFilters[1].resetDefaultValue = STRIKES_LABELS_MAPPING[10]

    setOptionChainFilter([...optionChainFilters])
    setSelectedtab(CALLS)
    expirationDates?.length && getOptionChain(expirationDates)
    setIsPriceMoment(false)
    setStrike(10)
  }

  const expandCollapseHandler = (action) => {
    const temp = optionChainData.map(options => {
      options.isCollapsed = action !== 'collapse'
      return options
    })
    setOptionChainData([...temp])
  }

  const getExpirationDates = () => {
    DataRequest.execute(
      `${URLS.PLATFORM.OPTION_CHAINS_EXPIRATION_DATES}/${xref.venueXid}`,
      {
        params: {
          contractTerm: WEEKLY
        }
      },
      (response) => {
        if (response.isDataRequestComplete) {
          if (response.isError) {
            setError(true)
            setIsReady(true)
          } else {
            const dates = response?.data?.dates
            const datesArr = dates?.map(item => item.expirationDate)
            const distinctDates = [...new Set(datesArr)]
            setExpirationDates(distinctDates)
            getOptionChain(distinctDates)
          }
        }
      },
      null
    )
  }

  const getOptionChain = (expdates, strikeCount = 10) => {
    setIsReady(false)
    setError(false)
    DataRequest.execute(
      `${URLS.PLATFORM.OPTION_CHAINS_WITH_GREEKS}/${xref.venueXid}`,
      {
        params: {
          callsPuts: CALLS_AND_PUTS_VALUES_MAPPING[callPut],
          strikeCount,
          expirationDates: expdates,
          riskFreeRate: 0.01,
          dividendRate: 0.0,
          realTimeQuotes: false,
          preMarketClear: false,
          contractTerm: WEEKLY

        }
      },
      mapOptionChainData,
      null
    )
  }

  const getValuefromCallAndPut = (dataObject, key) => {
    if (dataObject) {
      if (key === 'isAdjusted') {
        setAdjustmentFlag(dataObject[key])
      }
      return dataObject[key]
    }
    return false
  }

  const getFrequency = (data) => {
    return callPut === CALLS_AND_PUTS_LABELS_MAPPING.PUTS ? TIMEFRAME[data[0] && data[0].put && data[0].put.periodicity] : TIMEFRAME[data[0] && data[0].call && data[0].call.periodicity]
  }

  const mapOptionChainData = (response) => {
    if (!response.isDataRequestComplete) return

    if (!response.data) {
      setError(true)
      setIsReady(true)
      setOptionChainData([])
    }

    if (response.data) {
      const data = response.data
      if (data.items) {
        const mappeddata = data.items.map((item, index) => {
          return {
            isCollapsed: index === 0,
            expirationDate: item.expirationDate,
            frequency: getFrequency(item.options),
            call: item.options.map(option => {
              return {
                ...option.call,
                strikePrice: option.strikePrice
              }
            }),
            put: item.options.map(option => {
              return {
                ...option.put,
                strikePrice: option.strikePrice
              }
            }),
            strike: item.options.map(option => {
              return {
                strikePrice: option.strikePrice,
                lotSize: getValuefromCallAndPut(option.call, 'lotSize') || getValuefromCallAndPut(option.put, 'lotSize'),
                isAdjusted: getValuefromCallAndPut(option.call, 'isAdjusted') || getValuefromCallAndPut(option.put, 'isAdjusted')
              }
            }),
            underlyingValue: data.underlyingQuote.lastTrade.last
          }
        })
        setAdjustmentFlag(false)
        setOptionChainData([...mappeddata])
        setIsReady(true)
        setError(false)
      }
    }
  }

  const getDays = (expDate) => {
    let currentDate = formatDate(getCurrentDate(), {format: 'YYYY-MM-DD'})
    const days = diffInDays(currentDate, expDate, 'YYYY-MM-DD')
    const text = days === 0 ? 'Expires Today' : `Expired`
    return days > 0 ? `${days + 1} Days` : text
  }

  /* All Effect for this components */
  useEffect(() => {
    if (xref?.venueXid) {
      resetOptionFilters()
      getExpirationDates()
    }
  }, [xref?.venueXid])

  /* End */

  const handleOptionsDetails = () => {
    document.getElementById('optionsQuoteBar').scrollIntoView({ behavior: 'smooth' })
  }

  const handleTableRowClick = (data, expirationDate, name) => {
    setClickedTab(name)
    setCurrentExpirationDate(expirationDate)
    setOptionChainTableRowData(data)
    setIsPriceMoment(true)
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    })
  }

  const collapseCardIcon = { height: '18px', width: '18px' }
  const customStyleHeader = { fontSize: '14px', fontFamily: 'Roboto-Medium', lineHeight: '24px' }
  const collapseCardContainerStyle = { background: '#F7F7F7', padding: '8px 0', marginBottom: '1px' }
  const expandCardContainerStyle = { background: '#F7F7F7', paddingTop: '8px', marginBottom: '1px' }

  const collapsibleCardHandler = (title) => {
    let temp = optionChainData
    let clickedItem = temp.find(x => formatDate(x.expirationDate, {format: 'MMMM DD, YYYY'}) === title.split(' (')[0])
    clickedItem.isCollapsed = !clickedItem.isCollapsed
    setOptionChainData([...temp])
  }

  return <div className={styles.optionsContainer}>
    <OptionsChainFilters
      showAdjustmentHeader={adjustmentFlag}
      filters={optionChainFilters}
      dropDownsHandler={dropDownsHandler}
      tabsHandler={tabsHandler}
      selectedTab={selectedTab}
      symbol={symbol}
      resetHandler={resetOptionFilters} />
    <Loader ready={isReady} spinnerSize='2x' >
      {isError && <Nodata msg='No Option Chain Data for selected filter' />}
      {!isError &&
        <ExpandCollapseButtons expandCollapseHandler={expandCollapseHandler}disableExpandAllBtn={disableExpandAllBtn} disableCollapseAllBtn={disableCollapseAllBtn} />
      }

      {optionChainData.map(items => {
        return <CollapsibleCard key={items.expirationDate}
          title={formatDate(items.expirationDate, {format: 'MMMM DD, YYYY'}) + ` (${items.frequency})`}
          rightSideTitle={getDays(items.expirationDate)}
          styleIcon={collapseCardIcon}
          customStyleTitle={customStyleHeader}
          customStyleContainer={items.isCollapsed ? expandCardContainerStyle : collapseCardContainerStyle}
          clickHandler={collapsibleCardHandler}
          isCollapsed={items.isCollapsed}>
          { callsAndPutsselectedOption === CALLS_AND_PUTS_LABELS_MAPPING.CALLS &&
          <div className={styles.optionTable}>
            <StrikeColumn data={items.strike} underlyingValue={items.underlyingValue} symbol={symbol} />
            <div className={styles.callsOption}>
              <OptionsTable name={CALLS} header={headers} data={items.call} expirationDate={items.expirationDate} handleOptionTableRowClick={handleTableRowClick} />
            </div>
          </div>
          }
          { callsAndPutsselectedOption === CALLS_AND_PUTS_LABELS_MAPPING.ALL &&
          <div className={styles.optionTable}>
            <div className={`${styles.callsOption} ${styles.table}`}>
              <div className={styles.header}>Calls</div>
              <div className={`${styles.tableScroll} ${styles.ipadScroll}`}>
                <OptionsTable name={CALLS} header={callsHeader} data={items.call} expirationDate={items.expirationDate} reverseColumn handleOptionTableRowClick={handleTableRowClick} />
              </div>
            </div>
            <div className={styles.strikeColumn}>
              <div className={styles.divider}><div className={styles.seprator} /></div>
              <StrikeColumn data={items.strike} underlyingValue={items.underlyingValue} symbol={symbol} />
            </div>
            <div className={`${styles.putsOption} ${styles.table}`}>
              <div className={styles.header}>Puts</div>
              <div className={`${styles.tableScroll}`}>
                <OptionsTable name={PUTS} header={headers} data={items.put} expirationDate={items.expirationDate} handleOptionTableRowClick={handleTableRowClick} />
              </div>
            </div>
          </div>
          }
          { callsAndPutsselectedOption === CALLS_AND_PUTS_LABELS_MAPPING.PUTS &&
          <div className={styles.optionTable}>
            <StrikeColumn data={items.strike} underlyingValue={items.underlyingValue} symbol={symbol} />
            <div className={styles.putsOption}>
              <OptionsTable name={PUTS} header={headers} data={items.put} expirationDate={items.expirationDate} handleOptionTableRowClick={handleTableRowClick} />
            </div>
          </div>
          }
        </CollapsibleCard>
      })}
    </Loader>
    {
      isPriceMoment &&
        <React.Fragment>
          <OptionsQuoteBar symbol={symbol} greeksData={optionChainTableRowData} tab={clickedTab} handleOptionsDetails={handleOptionsDetails} expirationDate={currentExpirationDate} />
          <PremiumPriceMovement symbol={symbol} xref={xref} greeksData={optionChainTableRowData && optionChainTableRowData.greeks} />
        </React.Fragment>
    }
  </div>
}

OptionsChain.propTypes = {
  xref: PropTypes.object,
  symbol: PropTypes.string
}
