import React from 'react'
import PropTypes from 'prop-types'
import styles from './QuoteCard.module.scss'
import Popover from '../../Lib/Popover/Popover'
import { URLS, ChartTimeFrames, DATE_FORMAT, ISSUE_TYPES_MAP, ISSUE_TYPES } from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import { formatNumber, toFormattedTimeWithET, convertUTCToEST, formatDate, formatPriceNumber } from '../../../utils/formatter'
import PerformanceChart from '../QuoteCard/PerformanceChart/PerformanceChart'
import Loader from '../../Lib/common/Loader'
import NoData from '../../Lib/NoData/NoData'
import { checkIfUserIsRealTime, deviceType, getDeviceType, handleWindowResize } from '../../../utils/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { emitF2Event } from '../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../utils/f2Constants'
import ShowMoreText from 'react-show-more-text'
import { MOD_GOTO_PAGES, PROFILE } from '../../WorkstreamII/pershing-netxwealth-universalsearch/components/utils/appConstants'
import { makeEntitlementsCall } from '../../../services/EntitlementsAPICall.js'
import { getQuoteData } from '../../../shared/dataMethods'
const TEXT_NULL_VALUE = '--'
class QuoteCard extends React.Component {
  constructor (props) {
    super(props)
    this.venueXid = props.venueXid
    this.id = props.id
    this.style = props.style
    this.state = {
      device: getDeviceType(),
      quoteData: {
        price: null,
        priceChange: null,
        priceChangePct: null,
        prevClose: null,
        ytdChange: null,
        dayRange: null,
        _52WkRange: null,
        volume: null,
        avg30DayVolume: null,
        bid: null,
        ask: null,
        openInterest: null,
        asOfDate: TEXT_NULL_VALUE,
        isMF: false
      },
      chartDays: props.chartDaysInput || ChartTimeFrames.Week,
      equipmentType: props.sector,
      markitConsensus: '',
      morningStarRating: '',
      netAssets: '--',
      moduleName: '',
      xrefData: {
        symbol: null,
        name: null
      },
      isBottomPopoverOpen: false,
      isLoading: true,
      isError: false,
      error: {}
    }
    this.bottomPopoverRef = React.createRef()
    this.headerStyle = {'borderTop': `4px solid ${props.headerColor}`}
    this.spinnerStyle = props.spinnerStyle || {'minWidth': '220px', 'height': '345px'}
  }

  componentDidMount () {
    handleWindowResize((response) => {
      this.setState({device: response})
    })
    this.getData()
  }

  getData = () => {
    makeEntitlementsCall((response) => {
      this.state.device === deviceType.Ipad && this.props.updateQuoteCardPosition && this.props.updateQuoteCardPosition()
      let isRealTimeUser = false

      if (response && response.data) {
        isRealTimeUser = checkIfUserIsRealTime(response.data.userTier)
      }
      if (this.props?.venueXid) {
        this.getQuote(this.props.venueXid, isRealTimeUser)
        this.getSymbolData(this.props.venueXid)
        this.getConsensusRatingData(this.props.venueXid)
      } else {
        this.setState({
          isLoading: false
        })
      }
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.venueXid !== prevProps.venueXid) {
      this.getData()
    }
  }

  getQuote (venueXid, isRealTimeUser) {
    let callback = (response) => {
      if (!response.isError) {
        let data =
          response &&
          response.data.quotes &&
          response.data.quotes.length > 0 &&
          response.data.quotes[0].data
        const isMF = ISSUE_TYPES_MAP[response.data.quotes[0].data.instrumentIssueType] === ISSUE_TYPES.MutualFund
        this.setState({
          isLoading: false,
          quoteData: {
            price: formatNumber(data.lastTrade.last, {
              precision: 2,
              negSign: true
            }),
            priceChange: formatNumber(data.lastTrade.change, {
              precision: 2,
              negSign: true,
              posSign: true
            }),
            priceChangePct: formatNumber(data.changePercent.today, {
              precision: 2,
              negSign: true,
              postFix: true,
              postFixValue: '%',
              posSign: true
            }),
            prevClose: formatNumber(data.lastTrade.close, {
              precision: 2,
              negSign: true
            }),
            ytdChange: formatNumber(data.changePercent.yearToDate, {
              precision: 2,
              postFix: true,
              postFixValue: '%',
              negSign: true
            }),
            dayRange: data.lastTrade.low || data.lastTrade.high ? `${formatNumber(data.lastTrade.low, 2)} - ${formatNumber(
              data.lastTrade.high,
              2
            )}` : '--',
            _52WkRange: data.price52Week.low || data.price52Week.high ? `${formatNumber(
              data.price52Week.low,
              2
            )} - ${formatNumber(data.price52Week.high, 2)}` : '--',
            volume: formatNumber(data.volume.last, {
              precision: 2,
              showMagnitude: true
            }),
            avg30DayVolume: formatNumber(data.volume.average30Day, {
              precision: 2,
              showMagnitude: true
            }),
            bid: formatPriceNumber(data.bid.price),
            ask: formatPriceNumber(data.ask.price),
            openInterest: data.openInterest,
            asOfDate: data.lastTrade.date ? toFormattedTimeWithET(convertUTCToEST(data.lastTrade.date)) : TEXT_NULL_VALUE,
            isMF: isMF,
            isRealTime: this.getIsRealTime(isRealTimeUser)
          }
        })
      } else {
        this.setState({
          isLoading: false,
          error: response.error
        })
      }
    }
    getQuoteData(venueXid, callback, this.getIsRealTime(isRealTimeUser))
  }

  getIsRealTime (isRealTimeUser) {
    return isRealTimeUser || this.props.isRealTime || false
  }

  getSymbolData (venueXid) {
    let callback = (response) => {
      if (!response.isError) {
        let symbols = response && response.data
        this.setState({
          xrefData: {
            symbol: symbols.symbol || TEXT_NULL_VALUE,
            name: symbols.name || TEXT_NULL_VALUE
          }
        })
        if (symbols.classification.name === 'ETF' || symbols.classification.name === 'Fund') {
          this.getMorningStarRating(venueXid)
          this.getFundData(venueXid)
          this.setState({moduleName: 'WatchlistFunds'})
        } else if (symbols.classification.name === 'Derivative') {
          this.setState({moduleName: 'WatchlistOptions'})
        }
      } else {
        this.setState({
          isLoading: false,
          error: response.error
        })
      }
    }
    DataRequest.execute(
      `${URLS.PLATFORM.XREF}/${venueXid}`,
      null,
      callback
    )
  }

  getFundData (venueXid) {
    let callback = (response) => {
      if (!response.isError) {
        let assets = response && response.data
        this.setState({netAssets: formatNumber(assets.fundNetAssets, {showMagnitude: true})})
      } else {
        this.setState({
          isLoading: false
        })
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.SHARECLASS}/${venueXid}`,
      null,
      callback
    )
  }

  getMorningStarRating (venueXid) {
    let callback = (response) => {
      if (!response.isError) {
        let rating = response && response.data.items
        this.setState({morningStarRating: rating[0].values[0]})
      } else {
        this.setState({
          isLoading: false,
          morningStarRating: null
        })
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.MANAGEDFUNDS_MORNINGSTAR_RATING_RISK}/${venueXid}`,
      {
        params: {
          periodTypes: 'overall',
          dataPoints: 'rating'
        }
      },
      callback,
      null
    )
  }

  getConsensusRatingData (venueXid) {
    let callback = (response) => {
      if (!response.isError) {
        let consensus = response && response.data
        this.setState({markitConsensus: consensus.rating})
      } else {
        this.setState({
          isLoading: false
        })
      }
    }

    DataRequest.execute(
      `${URLS.CUSTOM.CONSENSUS_RATING}`,
      {
        params: {
          WsodIssue: venueXid
        }
      },
      callback,
      null
    )
  }

  handleClick (e) {
    if (e.cancelable) {
      e.preventDefault()
    }
    e && e.stopPropagation()
  }

  handleKeyDown (e) {
    e.keyCode === 'Enter' && this.handleClick(e)
  }

  bindMarkitConsenusContent (val) {
    if (!val || !['Sell', 'Hold', 'Buy'].includes(val)) {
      return <div className={styles.mainSectionContent}>N/A</div>
    }

    let negative =
      val === 'Sell' ? `${styles.consensus} ${styles.negative}` : ''
    let neutral =
      val === 'Hold' ? `${styles.consensus} ${styles.neutral}` : ''
    let positive =
      val === 'Buy' ? `${styles.consensus} ${styles.positive}` : ''
    return (
      <div className={styles.markitConsensusContentContainer}>
        <div className={`${styles.markitConsensusContent} ${negative}`}>
          <span>{val === 'Sell' ? 'Sell' : ''}</span>
        </div>
        <div className={`${styles.markitConsensusContent} ${neutral}`}>
          <span>{val === 'Hold' ? 'Hold' : ''}</span>
        </div>
        <div className={`${styles.markitConsensusContent} ${positive}`}>
          <span>{val === 'Buy' ? 'Buy' : ''}</span>
        </div>
      </div>
    )
  }
  toggleBottomPopover = () => {
    this.setState({ isBottomPopoverOpen: !this.state.isBottomPopoverOpen })
  };

  quotePopOverData = () => {
    return (
      <div className={styles.popOverContainer}>
        <div className={styles.popOverHeader}>
          <span>Markit Consensus</span>
        </div>
        <div className={styles.popOverBody}>
          <span>
            Data based on consensus of mid-market prices from the leading active
            market participants for each product, along with information on the
            range of submissions and where the client is an outlier.
          </span>
        </div>
      </div>
    )
  };

  optionFundCard (moduleName) {
    return <div className={`${styles.quoteCardMainContent} ${this.props.defaultQuoteCard === false ? styles.quoteCardMainContentDisplay : ''}`}>
      {moduleName === 'WatchlistFunds' && this.id !== 'OtherWaysToInvest' && <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>Morningstar Rating</div>
        <div className={`${styles.mainSectionContent}`}>
          {this.state.morningStarRating === null ? '--'
            : [...Array(this.state.morningStarRating)].map((item) => (
              <FontAwesomeIcon icon={['fas', 'star']} color='#004480' />
            )
            )}
        </div>
      </div>}
      {this.id !== 'OtherWaysToInvest' &&
      <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>Last Price/Change</div>
        <div className={styles.mainSectionContent}>
          {`${formatPriceNumber(this.state.quoteData.price)} `}
          <span className={`${styles.priceChange} ${this.state.quoteData.priceChangePct < 0 ? styles.red : styles.green} `}>
            ({this.state.quoteData.priceChangePct})
          </span>
        </div>
      </div>
      }
      {moduleName === 'WatchlistFunds' && <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>YTD Change (%)</div>
        <div className={`${styles.mainSectionContent} ${this.getColorCssClass(this.state.quoteData.ytdChange)}`}>
          {this.state.quoteData.ytdChange}
        </div>
      </div>}
      {this.id === 'OtherWaysToInvest' && this.state.equipmentType === 'ETF' && <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>Volume</div>
        <div className={styles.mainSectionContent}>
          {this.state.quoteData.avg30DayVolume}
        </div>
      </div>}
      {this.id === 'OtherWaysToInvest' && this.state.equipmentType === 'MF' && <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>NAV Return (5Yr - Ann)<div>As of {formatDate(this.props.performance.date, { format: DATE_FORMAT.MONTH_DAY_YEAR_DATE })}</div></div>
        <div className={`${styles.mainSectionContent} ${this.getColorCssClass(this.props.performance.nav)}`}>
          {formatNumber(this.props.performance.nav, {precision: 2, negSign: true, posSign: true, postFix: true, postFixValue: '%'})}
        </div>
      </div>}
      {moduleName === 'WatchlistFunds' && <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>52 Wk Range</div>
        <div className={styles.mainSectionContent}>
          {this.state.quoteData._52WkRange}
        </div>
      </div>}
      {moduleName === 'WatchlistFunds' && <div className={styles.volumeSectionContainer}>
        <div className={styles.mainSectionLabel}>Net Assets</div>
        <div className={styles.mainSectionContent}>
          {this.state.netAssets}
        </div>
      </div>}
      {moduleName === 'WatchlistOptions' && <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>Bid/Ask</div>
        <div className={`${styles.mainSectionContent}`}>
          {this.state.quoteData.bid}/{this.state.quoteData.ask}
        </div>
      </div>
      }
      {moduleName === 'WatchlistOptions' && <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>Volume</div>
        <div className={styles.mainSectionContent}>
          {this.state.quoteData.volume}
        </div>
      </div>
      }
      {moduleName === 'WatchlistOptions' && <div className={styles.volumeSectionContainer}>
        <div className={styles.mainSectionLabel}>Open Interest</div>
        <div className={styles.mainSectionContent}>
          {this.state.quoteData.openInterest}
        </div>
      </div>
      }
    </div>
  }

  renderEquityData = () => {
    return <div data-val={'equityContainer'} className={`${styles.quoteCardMainContent} ${this.props.defaultQuoteCard === false ? styles.quoteCardMainContentDisplay : ''}`}>
      <div className={styles.mainSectionContainer}>
        <div className={`${styles.mainSectionLabel} ${styles.markitConsensusLabel}`}>
          <button
            data-testid='popover'
            ref={this.bottomPopoverRef}
            onMouseOver={this.toggleBottomPopover}
            onFocus={this.toggleBottomPopover}
            onMouseOut={this.toggleBottomPopover}
            onBlur={this.toggleBottomPopover}
            className={styles.buttonPopOver}>
            Markit Consensus
          </button>
        </div>
        <div className={`${styles.mainSectionContent} ${styles.markitConsensusContent}`}>
          {this.bindMarkitConsenusContent(this.state.markitConsensus)}
        </div>
      </div>
      { this.id !== 'quoteCardCompare' &&
      <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>Previous Close</div>
        <div className={styles.mainSectionContent}>
          {this.state.quoteData.prevClose}
        </div>
      </div>
      }
      <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>YTD Change (%)</div>
        <div className={`${styles.mainSectionContent} ${this.getColorCssClass(this.state.quoteData.ytdChange)}`}>
          {this.state.quoteData.ytdChange}
        </div>
      </div>
      { this.id !== 'quoteCardCompare' &&
      <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>Day Range</div>
        <div className={styles.mainSectionContent}>
          {this.state.quoteData.dayRange}
        </div>
      </div>
      }
      <div className={styles.mainSectionContainer}>
        <div className={styles.mainSectionLabel}>52 Wk Range</div>
        <div className={styles.mainSectionContent}>
          {this.state.quoteData._52WkRange}
        </div>
      </div>
      <div className={styles.volumeSectionContainer}>
        <div className={styles.mainSectionLabel}>Volume</div>
        <div className={styles.mainSectionContent}>
          {this.state.quoteData.volume}
        </div>
      </div>
    </div>
  }

  redirectToViewQuotePageHandler = () => {
    if (this.props.id === 'OtherWaysToInvest') {
      emitF2Event(EVENT_NAMES.MOD_GOTO, {
        gotoPage: this.props.sector === 'EQ' ? MOD_GOTO_PAGES.EQ : MOD_GOTO_PAGES.MF,
        symbol: this.state.xrefData.symbol,
        activeTabKey: this.props.sector === 'EQ' ? 'snapshot' : 'profile',
        issueType: this.props.sector
      })
    } else {
      let isSymbolFund = this.state.moduleName === 'WatchlistFunds'

      emitF2Event(EVENT_NAMES.MOD_GOTO, {
        gotoPage: isSymbolFund ? MOD_GOTO_PAGES.fund : MOD_GOTO_PAGES.EQ,
        symbol: this.state.xrefData.symbol,
        activeTabKey: isSymbolFund ? 'profile' : 'snapshot',
        issueType: isSymbolFund ? 'MF' : 'EQ'
      })
    }
  }

  renderViewButton = () => {
    if (this.state.moduleName !== 'WatchlistOptions' && (this.props.showViewQuoteCardButton === true || this.props.id === 'OtherWaysToInvest')) {
      return <div className={styles.btnViewQuote}>
        <button disabled={!this.state.xrefData.symbol} onClick={this.redirectToViewQuotePageHandler}>View Quote</button>
      </div>
    }
  }

  getColorCssClass (conditionVal) {
    if (isNaN(conditionVal)) {
      conditionVal = parseInt(conditionVal.replace(/[,]/g, ''))
    }
    if (conditionVal > 0 || (conditionVal && conditionVal[0] === '+')) {
      return styles.green
    } else if (conditionVal < 0 || (conditionVal && conditionVal[0] === '-')) {
      return styles.red
    } else {
      return styles.black
    }
  }
  viewProfile = (e) => {
    if (!e) {
      return
    }
    let dataEmit = {
      gotoPage: MOD_GOTO_PAGES.EQ,
      symbol: this.state.xrefData.symbol,
      activeTabKey: PROFILE
    }
    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, dataEmit)
  }
  render () {
    let quoteHeaderStyle = Object.assign(this.headerStyle, this.style)
    if (this.state.isLoading) {
      return <Loader ready={false} spinnerStyle={this.spinnerStyle} spinnerSize={'2x'} />
    }
    let renderQuoteCard = true
    if (!this.props.showOnMobile && this.state.device === deviceType.Mobile) {
      renderQuoteCard = false
    }
    return (
      renderQuoteCard &&
      <div data-value='QuoteCard' className={`${styles.quoteCardParentContainer} ${this.id === 'quoteCardCompare' && styles.quoteCardCompareParentContainer}`} style={this.props.valueStyling}>
        <div data-testid={'quoteCardContainer'} id={this.id} className={`${styles.quoteCardContainer} ${this.props.defaultQuoteCard === false ? styles.compressedQuoteCard : ''}`} style={quoteHeaderStyle}>
          {this.state.isError ? <NoData hideBellIcon={false} style={this.spinnerStyle} />
            : <React.Fragment>
              <div className={styles.quoteCardHeader}>
                <div className={styles.quoteCardHeaderMain}>
                  <div className={styles.quoteCardHeaderMainSymbol} role='button' tabIndex='0' onClick={e => this.viewProfile(e)} onKeyDown={e => this.viewProfile(e)} >
                    {this.state.xrefData.symbol}
                  </div>
                  <div className={styles.quoteCardHeaderMainActionMenu}>
                    {
                      this.id === 'quoteCardCompare' && this.props.actionMenu
                    }
                  </div>
                </div>
                <div className={styles.quoteCardHeaderDesc}>
                  <ShowMoreText lines={1} more='' less='' expanded={false} width={180}>
                    {this.state.xrefData.name}
                  </ShowMoreText>
                  <span className={`${styles.popup}`} id='namePopover'>
                    {this.state.xrefData.name}
                  </span>
                </div>
                { this.id === 'quoteCardCompare' &&
                  <div className={styles.pill}>
                    {this.state.equipmentType}
                  </div>}
              </div>
              <div className={styles.quoteCardModeChart} >
                <PerformanceChart
                  symbol={this.venueXid}
                  days={this.state.chartDays}
                  isMF={this.state.quoteData.isMF} />
              </div>
              <div className={`${this.id === 'quoteCardCompare' ? styles.quoteCardChartCompare : styles.quoteCardChart}`} >
                <div className={styles.quoteCardChartMain}>
                  {formatPriceNumber(this.state.quoteData.price)}
                </div>
                <div className={` ${styles.quoteCardChartDesc} ${this.getColorCssClass(this.state.quoteData.priceChange)}`}>
                  {this.state.quoteData.priceChange} (
                  {this.state.quoteData.priceChangePct})
                </div>
              </div>
              {
                ['WatchlistOptions', 'WatchlistFunds'].indexOf(this.state.moduleName) !== -1 ? this.optionFundCard(this.state.moduleName) : this.renderEquityData()
              }

              {
                this.renderViewButton()
              }
              {!this.props.hideDelayedQuote && <div className={styles.asOfDate}>
                {`${this.state.quoteData.isRealTime ? 'Quote as' : 'Delayed quote as'} of ${this.state.quoteData.asOfDate}`}
              </div>}
            </React.Fragment>}
        </div>
        {this.state.isBottomPopoverOpen && (
          <Popover
            id='bottom-popover'
            width='250px'
            closePopover={this.toggleBottomPopover}
            body={this.quotePopOverData()}
            referenceElement={this.bottomPopoverRef.current}
            showPointingArrow={false}
            placement={this.props.isAutoPosition ? 'auto' : 'bottom'}
          />
        )}
      </div>
    )
  }
}
QuoteCard.propTypes = {
  venueXid: PropTypes.number,
  id: PropTypes.string,
  style: PropTypes.object,
  spinnerStyle: PropTypes.object,
  isRealTime: PropTypes.bool,
  headerColor: PropTypes.string,
  sector: PropTypes.string,
  actionMenu: PropTypes.element,
  defaultQuoteCard: PropTypes.bool,
  hideDelayedQuote: PropTypes.bool,
  showOnMobile: PropTypes.bool,
  valueStyling: PropTypes.object,
  showViewQuoteCardButton: PropTypes.bool,
  chartDaysInput: PropTypes.number,
  performance: PropTypes.object,
  isAutoPosition: PropTypes.bool,
  updateQuoteCardPosition: PropTypes.func
}
export default QuoteCard
