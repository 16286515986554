import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { addArgument } from '../../../../../../utils/utilities'
import { URLS, DEFAULT_NEWS_INPUTS } from '../../../../../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import { registerAccessTokenUpdatedF2OnEvent } from '../../../../../../utils/f2Methods'
import Loader from '../../../../../Lib/common/Loader'
import WatchlistTopNewsSection from './WatchlistTopNewsSection'
function WatchlistTopNews (props) {
  const [WatchlistTopNewsList, setWatchlistTopNewsList] = useState({
    isError: false,
    isLoading: true,
    data: []
  })

  useEffect(() => {
    loadNewsData()
  }, [props.venueXids])

  useEffect(() => {
    registerAccessTokenUpdatedF2OnEvent(() => {
      loadNewsData()
    })
  }, [])

  function loadNewsData () {
    props.venueXids && props.venueXids.length > 0 && getNews(props.venueXids)
  }
  const buildPostNewsInputs = (venueXid) => {
    let newsInputs = DEFAULT_NEWS_INPUTS
    newsInputs.arguments = [
      addArgument('Provider', ['All Providers']),
      addArgument('WsodIssue', venueXid)]
    newsInputs.limit = 4
    return {
      method: 'POST',
      postData: newsInputs
    }
  }
  const getNews = (venueXid) => {
    let callback = (response) => {
      let newsData = null
      if (!response.isError && response.data && response.data.news) {
        newsData = response.data.news
      }
      setWatchlistTopNewsList({
        isError: response.isError,
        isLoading: false,
        data: newsData
      })
    }
    DataRequest.execute(URLS.CUSTOM.NEWS, buildPostNewsInputs(venueXid), callback, null)
  }

  return (<Loader ready={!WatchlistTopNewsList.isLoading} spinnerSize='2x' >
    {WatchlistTopNewsList && !WatchlistTopNewsList.isLoading &&
    <WatchlistTopNewsSection data={WatchlistTopNewsList.data || []} isRealTime={props.isRealTime} userInfo={props.userInfo} />}
  </Loader>
  )
}
WatchlistTopNews.propTypes = {
  venueXids: PropTypes.array.isRequired,
  isRealTime: PropTypes.bool,
  userInfo: PropTypes.object
}
export default WatchlistTopNews
