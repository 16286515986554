import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styles from './FundPortfolio.module.scss'

const ETFPortfolio = (props) => {
  useEffect(() => {
  }, [])

  return (
    <div className={styles.fundPortfolioContainer}>
      <h3>ETF Portfolio</h3>
      <h5>Type: {props.type}</h5>
      <h5>Symbol: {props.symbol}</h5>
    </div>
  )
}

ETFPortfolio.propTypes = {
  symbol: PropTypes.string,
  type: PropTypes.string
}

export default ETFPortfolio
