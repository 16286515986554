/**
 * f2Constants.js defines constant values used for F2
 */

// Defines F2 app ids
export const APP_IDS = {
  COM_PERSHING_NETXWEALTH_NEWS_HEADERTOPSTORIES: 'com_pershing_netxwealth_news_headertopstories',
  COM_PERSHING_NETXWEALTH_RESEARCH_LIST: 'com_pershing_netxwealth_research_list',
  COM_PERSHING_NETXWEALTH_NEWS_ARTICLELIST: 'com_pershing_netxwealth_news_articlelist',
  COM_PERSHING_NETXWEALTH_NEWS_FILTER: 'com_pershing_netxwealth_news_filter',
  COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR: 'com_pershing_netxwealth_stock_quote_bar',
  COM_PERSHING_NETXWEALTH_STOCK_HIGHLIGHTS: 'com_pershing_netxwealth_stock_highlights',
  COM_PERSHING_NETXWEALTH_TODAY_EVENTS_NEWS_RESEARCH: 'com_pershing_netxwealth_today_events_news_research',
  COM_PERSHING_NETXWEALTH_MARKETS_UNUSUAL_VOLUME: 'com_pershing_netxwealth_markets_unusual_volume',
  COM_PERSHING_NETXWEALTH_MARKETS_SECTOR_PERFORMANCE: 'com_pershing_netxwealth_markets_sector_performance',
  COM_PERSHING_NETXWEALTH_MARKETS_CRYPTOCURRENCY_PERFORMANCE: 'com_pershing_netxwealth_markets_cryptocurrency_performance',
  COM_PERSHING_NETXWEALTH_MARKETS_GAINERS_AND_LOSERS: 'com_pershing_netxwealth_markets_gainers_and_losers',
  COM_PERSHING_NETXWEALTH_MARKET_MOSTACTIVE: 'com_pershing_netxwealth_markets_mostactive',
  COM_PERSHING_NETXWEALTH_MARKETS_PERFORMANCE: 'com_pershing_netxwealth_markets_performance',
  COM_PERSHING_NETXWEALTH_MARKETS_HIGHLIGHTS: 'com_pershing_netxwealth_markets_highlights',
  COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST: 'com_pershing_netxwealth_stock_news_articlelist',
  COM_PERSHING_NETXWEALTH_STOCK_PEER_NEWS: 'com_pershing_netxwealth_stock_peer_news',
  COM_PERSHING_NETXWEALTH_DIVIDEND_DETAILS: 'com_pershing_netxwealth_dividend_details',
  COM_PERSHING_NETXWEALTH_YEARLY_PAYMENT_TREND: 'com_pershing_netxwealth_yearly_payment_trend',
  COM_PERSHING_NETXWEALTH_DIVIDEND_GROWTH: 'com_pershing_netxwealth_dividend_growth',
  COM_PERSHING_NETXWEALTH_STOCK_PERFORMANCE: 'com_pershing_netxwealth_stock_performance',
  COM_PERSHING_NETXWEALTH_STOCK_COMPANY_DESCRIPTION: 'com_pershing_netxwealth_stock_company_description',
  COM_PERSHING_NETXWEALTH_STOCK_PRICE_CHARTS: 'com_pershing_netxwealth_stock_price_charts',
  COM_PERSHING_NETXWEALTH_KEY_MARKETS_TREASURY_BILL: 'com_pershing_netxwealth_key_markets_treasury_bill',
  COM_PERSHING_NETXWEALTH_MARKET_EVENTS_CALENDAR: 'com_pershing_netxwealth_market_events_calendar',
  COM_PERSHING_NETXWEALTH_STOCK_REVENUE: 'com_pershing_netxwealth_stock_revenue',
  COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH: 'com_pershing_netxwealth_universalsearch',
  COM_PERSHING_NETXWEALTH_MARKET_EVENTS_CURRENCY_COVERSION: 'com_pershing_netxwealth_market_events_currency_conversion',
  COM_PERSHING_NETXWEALTH_STOCK_VALUATION: 'com_pershing_netxwealth_stock_valuation',
  COM_PERSHING_NETXWEALTH_HISTORICAL_PRICES: 'com_pershing_netxwealth_key_historical_prices',
  COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_AND_ESTIMATES: 'com_pershing_netxwealth_stock_earnings_and_estimates',
  COM_PERSHING_NETXWEALTH_STOCK_DIVIDEND_PAYOUT: 'com_pershing_netxwealth_stock_dividend_payout',
  COM_PERSHING_NETXWEALTH_ETF_SNAPSHOT: 'com_pershing_netxwealth_etf_snapshot',
COM_PERSHING_NETXWEALTH_MODELS_TOP10HOLDINGS: 'com_pershing_netxwealth_models_top10Holdings',
  COM_PERSHING_NETXWEALTH_MODELS_ASSETALLOCATION: 'com_pershing_netxwealth_models_assetAllocation',
  COM_PERSHING_NETXWEALTH_STOCK_HISTORICAL_DIVIDEND_CHART: 'com_pershing_netxwealth_stock_historical_dividend_chart',
  COM_PERSHING_NETXWEALTH_PEER_ANNUAL_YIELDS: 'com_pershing_netxwealth_peer_annual_yields',
  COM_PERSHING_NETXWEALTH_DIVIDEND_PAYOUT_CALENDAR_YEAR: 'com_pershing_netxwealth_dividend_payout_calendar_year',
  COM_PERSHING_NETXWEALTH_STOCK_PROFIT_MARGINS: 'com_pershing_netxwealth_stock_profit_margins',
  COM_PERSHING_NETXWEALTH_STOCK_EFFECTIVENESS: 'com_pershing_netxwealth_stock_effectiveness',
  COM_PERSHING_NETXWEALTH_STOCK_PEER_VALUATIONS: 'com_pershing_netxwealth_stock_peer_valuations',
  COM_PERSHING_NETXWEALTH_STOCK_SNAPSHOT_EVENTS: 'com_pershing_netxwealth_stock_snapshot_events',
  COM_PERSHING_NETXWEALTH_STOCK_LEVERAGERATIOS: 'com_pershing_netxwealth_stock_leverageratios',
  COM_PERSHING_NETXWEALTH_STOCK_VALUATION_MEASURES: 'com_pershing_netxwealth_stock_valuation_measures',
  COM_PERSHING_NETXWEALTH_STOCK_PEER_GROWTH_RATES: 'com_pershing_netxwealth_stock_peer_growth_rates',
  COM_PERSHING_NETXWEALTH_STOCK_PEER_PE: 'com_pershing_netxwealth_stock_peer_pe',
  COM_PERSHING_NETXWEALTH_STOCK_GROWTH_MEASURES: 'com_pershing_netxwealth_stock_growth_measures',
  COM_PERSHING_NETXWEALTH_STOCK_CASHFLOW_TRENDS: 'com_pershing_netxwealth_stock_cashflow_trends',
  COM_PERSHING_NETXWEALTH_STOCK_ONE_YEAR_TARGET_PRICE: 'com_pershing_netxwealth_stock_one_year_target_price',
  COM_PERSHING_NETXWEALTH_LONG_TERM_GROWTH_RATES: 'com_pershing_netxwealth_long_term_growth_rates',
  COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_ESTIMATES: 'com_pershing_netxwealth_stock_earnings_estimates',
  COM_PERSHING_NETXWEALTH_EARNINGS_ESTIMATES_ACTUAL: 'com_pershing_netxwealth_earnings_estimates_actual',
  COM_PERSHING_NETXWEALTH_REVENUE_ESTIMATES_AND_ACTUALS: 'com_pershing_netxwealth_revenue_estimates_and_actuals',
  COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW: 'com_pershing_netxwealth_stock_cash_flow',
  COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET: 'com_pershing_netxwealth_stock_balance_sheet',
  COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT: 'com_pershing_netxwealth_stock_income_statement',
  COM_PERSHING_NETXWEALTH_SEC_FILINGS: 'com_pershing_netxwealth_sec_filings',
  COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_ASSETS: 'com_pershing_netxwealth_stock_balance_sheet_total_assets',
  COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_LIABILITIES: 'com_pershing_netxwealth_stock_balance_sheet_total_liabilities',
  COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_SHAREHOLDER_EQUITY: 'com_pershing_netxwealth_stock_balance_sheet_total_shareholder_equity',
  COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_CASH_DIVIDEND_PAID: 'com_pershing_netxwealth_stock_cash_flow_cash_dividend_paid',
  COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_FREE_CASH_FLOW: 'com_pershing_netxwealth_stock_cash_flow_free_cash_flow',
  COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_OPERATING_CASH_FLOW: 'com_pershing_netxwealth_stock_cash_flow_operating_cash_flow',
  COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_NET_INCOME: 'com_pershing_netxwealth_stock_income_statement_total_net_income',
  COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_OPERATING_INCOME: 'com_pershing_netxwealth_stock_income_statement_total_operating_income',
  COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_REVENUE: 'com_pershing_netxwealth_stock_income_statement_total_revenue',
  COM_PERSHING_NETXWEALTH_TOP_FUND_HOLDERS: 'com_pershing_netxwealth_top_fund_holders',
  COM_PERSHING_NETXWEALTH_STOCK_RATINGS: 'com_pershing_netxwealth_stock_ratings',
  COM_PERSHING_NETXWEALTH_STOCK_Fundamenatals: 'com_pershing_netxwealth_stock_fundamentals',
  COM_PERSHING_NETXWEALTH_EQUITY_SCREENER: 'com_pershing_netxwealth_equity_screener',
  COM_PERSHING_NETXWEALTH_FUND_SCREENER: 'com_pershing_netxwealth_fund_screener',
  COM_PERSHING_NETXWEALTH_TABS: 'com_pershing_netxwealth_tabs',
  COM_PERSHING_NETXWEALTH_STOCK_OPTIONS: 'com_pershing_netxwealth_stock_options',
  COM_PERSHING_NETXWEALTH_WATCHLISTS: 'com_pershing_netxwealth_watchlists',
  COM_PERSHING_NETXWEALTH_SYMBOL_SNAPSHOT: 'com_pershing_netxwealth_symbol_snapshot',
  COM_PERSHING_NETXWEALTH_TOPSTOCKS: 'com_pershing_netxwealth_topstocks',
  COM_PERSHING_NETXWEALTH_WATCHLIST_DETAILS: 'com_pershing_netxwealth_watchlist_details',
  COM_PERSHING_NETXWEALTH_AI_MARKETPLACE: 'com_pershing_netxwealth_ai_marketplace',
  COM_PERSHING_NETXWEALTH_FUND_MARKETPLACE: 'com_pershing_netxwealth_fund_marketplace',
  COM_PERSHING_NETXWEALTH_LANDING_PAGE_OVERVIEW: 'com_pershing_netxwealth_landing_page_overview',
  COM_PERSHING_NETXWEALTH_STOCK_MIND_EVENTS: 'com_pershing_netxwealth_stock_mind_events',
  COM_PERSHING_NETXWEALTH_COMPARE_EQUITIES: 'com_pershing_netxwealth_compare_equities',
  COM_PERSHING_NETXWEALTH_STOCK_ESG_SCORES: 'com_pershing_netxwealth_stock_esg_scores',
  COM_PERSHING_NETXWEALTH_STOCK_OTHER_WAYS_TO_INVEST: 'com_pershing_netxwealth_stock_other_ways_to_invest',
  COM_PERSHING_NETXWEALTH_FUND_ESG_SCORES: 'com_pershing_netxwealth_fund_esg_scores',
  COM_PERSHING_NETXWEALTH_EDUCATION_RESOURCE: 'com_pershing_netxwealth_education_resource',
  COM_PERSHING_NETXWEALTH_MARKET_EVENTS: 'com_pershing_netxwealth_market_events',
  COM_PERSHING_NXI_EQUITY_ESG: 'com_pershing_nxi_equity_esg',
  COM_PERSHING_NETXWEALTH_NXI_ESG_FUND: 'com_pershing_netxwealth_nxi_esg_fund'

}

export const F2_APPS_INPUTS = {
  [APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_DETAILS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_SYMBOL_SNAPSHOT]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_GROWTH]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_EARNINGS_ESTIMATES_ACTUAL]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_HISTORICAL_PRICES]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_LONG_TERM_GROWTH_RATES]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_PEER_ANNUAL_YIELDS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_REVENUE_ESTIMATES_AND_ACTUALS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_SEC_FILINGS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_DIVIDEND_PAYOUT_CALENDAR_YEAR]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_ASSETS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_LIABILITIES]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET_TOTAL_SHAREHOLDER_EQUITY]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_BALANCE_SHEET]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_CASH_DIVIDEND_PAID]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_FREE_CASH_FLOW]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW_OPERATING_CASH_FLOW]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_CASH_FLOW]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_CASHFLOW_TRENDS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_COMPANY_DESCRIPTION]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_DIVIDEND_PAYOUT]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_AND_ESTIMATES]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EARNINGS_ESTIMATES]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_EFFECTIVENESS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_Fundamenatals]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_GROWTH_MEASURES]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_HIGHLIGHTS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_HISTORICAL_DIVIDEND_CHART]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_NET_INCOME]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_OPERATING_INCOME]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT_TOTAL_REVENUE]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_INCOME_STATEMENT]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_LEVERAGERATIOS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_NEWS_ARTICLELIST]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_ONE_YEAR_TARGET_PRICE]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_GROWTH_RATES]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_NEWS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_PE]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PEER_VALUATIONS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_SNAPSHOT_EVENTS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PERFORMANCE]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PRICE_CHARTS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_PROFIT_MARGINS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_QUOTE_BAR]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_RATINGS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_REVENUE]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_VALUATION_MEASURES]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_VALUATION]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_TOP_FUND_HOLDERS]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_YEARLY_PAYMENT_TREND]: { 'symbol': {'required': true} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_WATCHLIST_DETAILS]: {'watchlistId': {'required': true}},
  [APP_IDS.COM_PERSHING_NETXWEALTH_MODELS_SCREENER]: { 'symbol': {'required': false} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_FUND_SCREENER]: { 'symbol': {'required': false} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_UNIVERSALSEARCH]: { 'symbol': {'required': false} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_FUND_MARKETPLACE]: { 'symbol': {'required': false} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_AI_MARKETPLACE]: { 'symbol': {'required': false} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_ETF_SNAPSHOT]: { 'symbol': {'required': false} },
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_MIND_EVENTS]: {'symbol': {'required': true}},
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_OPTIONS]: {'symbol': {'required': true}},
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_ESG_SCORES]: {'symbol': {'required': true}},
  [APP_IDS.COM_PERSHING_NETXWEALTH_STOCK_OTHER_WAYS_TO_INVEST]: {'symbol': {'required': true}},
  [APP_IDS.COM_PERSHING_NETXWEALTH_FUND_ESG_SCORES]: {'symbol': {'required': true}},
  [APP_IDS.COM_PERSHING_NXI_EQUITY_ESG]: {'symbol': {'required': true}},
  [APP_IDS.COM_PERSHING_NETXWEALTH_NXI_ESG_FUND]: {'symbol': {'required': true}}
}

// Test Center F2 Apps
export const TEST_CENTER_F2_APPS = {
  'News and Research': {
    'com_pershing_netxwealth_news_headertopstories': {
      context: {hideLink: 'false'},
      rootId: 'com_pershing_netxwealth_news_headertopstories',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_news_filter': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_news_filter',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_news_articlelist': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_news_articlelist',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_research_list': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_research_list',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    }
  },
  'Stock Overview': {
    'com_pershing_netxwealth_stock_quote_bar': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_quote_bar',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_symbol_snapshot': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_symbol_snapshot',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_snapshot_events': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_snapshot_events',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_highlights': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_highlights',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_news_articlelist': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_news_articlelist',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_stock_peer_news': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_peer_news',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_dividend_details': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_dividend_details',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_yearly_payment_trend': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_yearly_payment_trend',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_dividend_growth': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_dividend_growth',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_performance': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_performance',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_stock_company_description': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_company_description',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_stock_price_charts': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_price_charts',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_stock_valuation': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_valuation',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_key_historical_prices': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_key_historical_prices',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_earnings_and_estimates': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_earnings_and_estimates',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_revenue': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_revenue',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_dividend_payout': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_dividend_payout',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_historical_dividend_chart': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_historical_dividend_chart',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_peer_annual_yields': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_peer_annual_yields',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_dividend_payout_calendar_year': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_dividend_payout_calendar_year',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_other_ways_to_invest': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_other_ways_to_invest',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
  },
  'Valuations': {
    'com_pershing_netxwealth_stock_valuation_measures': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_valuation_measures',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_peer_valuations': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_peer_valuations',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_growth_measures': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_growth_measures',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_leverageratios': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_leverageratios',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_profit_margins': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_profit_margins',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_effectiveness': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_effectiveness',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_cashflow_trends': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_cashflow_trends',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
  },
  'Options': {
    'com_pershing_netxwealth_stock_options': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_options',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
  },
  'Fundamentals': {
    'com_pershing_netxwealth_stock_fundamentals': {
      context: {symbol: 'AAPL', period: 'Annual'},
      rootId: 'com_pershing_netxwealth_stock_fundamentals',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    // Start - Balance sheet ************************************
    'com_pershing_netxwealth_stock_balance_sheet_total_assets': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_balance_sheet_total_assets',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_balance_sheet_total_liabilities': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_balance_sheet_total_liabilities',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_balance_sheet_total_shareholder_equity': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_balance_sheet_total_shareholder_equity',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_balance_sheet': {
      context: {symbol: 'AAPL', periodType: 'Annual'},
      rootId: 'com_pershing_netxwealth_stock_balance_sheet',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    // End - Balance sheet ************************************

    // Start - Income Statement ************************************
    'com_pershing_netxwealth_stock_income_statement_total_net_income': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_income_statement_total_net_income',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_income_statement_total_operating_income': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_income_statement_total_operating_income',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_income_statement_total_revenue': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_income_statement_total_revenue',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_income_statement': {
      context: {symbol: 'AAPL', periodType: 'Annual'},
      rootId: 'com_pershing_netxwealth_stock_income_statement',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    // End - Income Statement ************************************

    // Start - Cash Flow ************************************
    'com_pershing_netxwealth_stock_cash_flow_cash_dividend_paid': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_cash_flow_cash_dividend_paid',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_cash_flow_free_cash_flow': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_cash_flow_free_cash_flow',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_cash_flow_operating_cash_flow': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_cash_flow_operating_cash_flow',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_cash_flow': {
      context: {symbol: 'AAPL', periodType: 'Annual'},
      rootId: 'com_pershing_netxwealth_stock_cash_flow',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    // End - Cash Flow ************************************

    // Start - Sec Fillings ************************************
    'com_pershing_netxwealth_sec_filings': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_sec_filings',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
    // End - Sec Fillings ************************************

  },
  'Earnings & Estimates': {
    'com_pershing_netxwealth_stock_earnings_estimates': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_earnings_estimates',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_earnings_estimates_actual': {
      context: {symbol: 'MSFT'},
      rootId: 'com_pershing_netxwealth_earnings_estimates_actual',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_mind_events': {
      context: {symbol: 'MSFT'},
      rootId: 'com_pershing_netxwealth_stock_mind_events',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_revenue_estimates_and_actuals': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_revenue_estimates_and_actuals',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_one_year_target_price': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_one_year_target_price',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_long_term_growth_rates': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_long_term_growth_rates',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_peer_growth_rates': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_peer_growth_rates',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_peer_pe': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_peer_pe',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_top_fund_holders': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_top_fund_holders',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_stock_ratings': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_ratings',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
  },
  'Markets Overview': {
    'com_pershing_netxwealth_today_events_news_research': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_today_events_news_research',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_markets_unusual_volume': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_markets_unusual_volume',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_markets_sector_performance': {
      context: {},
      rootId: 'com_pershing_netxwealth_markets_sector_performance',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_markets_cryptocurrency_performance': {
      context: {},
      rootId: 'com_pershing_netxwealth_markets_cryptocurrency_performance',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_markets_gainers_and_losers': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_markets_gainers_and_losers',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_markets_mostactive': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_markets_mostactive',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_markets_performance': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_markets_performance',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_markets_highlights': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_markets_highlights',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    }
  },
  'Markets Events': {
    'com_pershing_netxwealth_market_events': {
      context: {},
      rootId: 'com_pershing_netxwealth_market_events',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_market_events_calendar': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_market_events_calendar',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_key_markets_treasury_bill': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_key_markets_treasury_bill',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_market_events_currency_conversion': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_market_events_currency_conversion',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
  },
  'WorkstreamII': {
    'com_pershing_netxwealth_universalsearch': {
      context: {},
      rootId: 'com_pershing_netxwealth_universalsearch',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_etf_snapshot': {
      context: {symbol: 'IVV', issueType: 'ETF', exchange: 'NASDAQ', xid: '171184', isETF: true, shareName: 'Ishares S&P 500'},
      rootId: 'com_pershing_netxwealth_etf_snapshot',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
'com_pershing_netxwealth_models_top10Holdings': {
      context: {},
      rootId: 'com_pershing_netxwealth_models_top10Holdings',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_models_assetAllocation': {
      context: {},
      rootId: 'com_pershing_netxwealth_models_assetAllocation',
      availableInEnvironments: ['localhost']
    },
    'com_pershing_netxwealth_equity_screener': {
      context: {},
      rootId: 'com_pershing_netxwealth_equity_screener',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_fund_screener': {
      context: {},
      rootId: 'com_pershing_netxwealth_fund_screener',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_tabs': {
      context: {},
      rootId: 'com_pershing_netxwealth_tabs',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_ai_marketplace': {
      context: {},
      rootId: 'com_pershing_netxwealth_ai_marketplace',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_fund_marketplace': {
      context: {},
      rootId: 'com_pershing_netxwealth_fund_marketplace',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_landing_page_overview': {
      context: {},
      rootId: 'com_pershing_netxwealth_landing_page_overview',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_compare_equities': {
      context: {},
      rootId: 'com_pershing_netxwealth_compare_equities',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_fund_esg_scores': {
      context: {symbol: ''},
      rootId: 'com_pershing_netxwealth_fund_esg_scores',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    },
    'com_pershing_netxwealth_education_resource': {
      context: {},
      rootId: 'com_pershing_netxwealth_education_resource',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'acceptance-3', 'acceptance-4', 'production']
    }
  },
  'Watchlists': {
    'com_pershing_netxwealth_watchlists': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_watchlists',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_topstocks': {
      context: {},
      rootId: 'com_pershing_netxwealth_topstocks',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_watchlist_details': {
      context: {watchlistId: null},
      rootId: 'com_pershing_netxwealth_watchlist_details',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
  },
  'ESG Scores': {
    'com_pershing_netxwealth_stock_esg_scores': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_netxwealth_stock_esg_scores',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
  },
  'NXI_ESG': {
    'com_pershing_nxi_equity_esg': {
      context: {symbol: 'AAPL'},
      rootId: 'com_pershing_nxi_equity_esg',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    },
    'com_pershing_netxwealth_nxi_esg_fund': {
      context: {symbol: 'AAAIX'},
      rootId: 'com_pershing_netxwealth_nxi_esg_fund',
      availableInEnvironments: ['localhost', 'development', 'acceptance', 'acceptance-2', 'production']
    }
  }
}

// Defines F2 event names
export const EVENT_NAMES = {
  SYMBOL_CHANGED_EVENT: 'SYMBOL_CHANGED_EVENT',
  PERIOD_CHANGED_EVENT: 'PERIOD_CHANGED_EVENT',
  STOCK_CARD_LOADED_EVENT: 'STOCK_CARD_LOADED_EVENT',
  NEWS_FILTER_CHANGED_EVENT: 'NEWS_FILTER_CHANGED_EVENT',
  INVALID_ACCESS_TOKEN: 'INVALID_NETX_WEALTH_ACCESS_TOKEN',
  RANKING_SET_CHANGED_EVENT: 'RANKING_SET_CHANGED_EVENT',
  ACCESS_TOKEN_UPDATED: 'NETX_WEALTH_ACCESS_TOKEN_UPDATED',
  STOCK_FUNDAMENTAL_CHANGED_PERIOD: 'STOCK_FUNDAMENTAL_CHANGED_PERIOD',
  TAB_CHANGED_EVENT: 'TAB_CHANGED_EVENT',
  LAUNCH_ACCOUNT_OPENING: 'LAUNCH_ACCOUNT_OPENING',
  LAUNCH_PROPOSAL: 'LAUNCH_PROPOSAL',
  VIEW_PROFILE: 'VIEW_PROFILE',
  DOWNLOAD_PDF: 'DOWNLOAD_PDF',
  ADD_TO_COMPARE: 'ADD_TO_COMPARE',
  REMOVE_MODEL: 'REMOVE_MODEL',
  WATCHLIST_UPDATED: 'WATCHLIST_UPDATED',
  WATCHLIST_DETAILS_CHANGED_EVENT: 'WATCHLIST_DETAILS_CHANGED_EVENT',
  PAGE_NAVIGATION: 'PAGE_NAVIGATION',
  WATCHLIST_NOTE_UPDATED: 'WATCHLIST_NOTE_UPDATED',
  MOD_GOTO: 'MOD_GOTO',
  MOD_ORDER_PROSPECTUS: 'MOD_ORDER_PROSPECTUS',
  MOD_VIEW_PROSPECTUS: 'MOD_VIEW_PROSPECTUS',
  WATCHLIST_NOTE_DELETED: 'WATCHLIST_NOTE_DELETED',
  ADD_TO_WATCHLIST: 'ADD_TO_WATCHLIST',
  ORDER_ENTRY_EVENT: 'ORDER_ENTRY_EVENT',
  MY_AVAILABLE_FUNDS: 'MY_AVAILABLE_FUNDS',
  FAVORITES: 'FAVORITES',
  SELECTED_SYMBOLS_EVENT: 'SELECTED_SYMBOLS_EVENT',
  REDIRECT_CAIS_URL: 'REDIRECT_CAIS_URL',
  GOTO_FUND_PROFILE_TAB: 'GOTO_FUND_PROFILE_TAB',
  CHART_PERIOD_CHANGED_EVENT: 'CHART_PERIOD_CHANGED_EVENT'
}
