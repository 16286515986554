import React, { useState, useEffect } from 'react'
import styles from './CollapsibleCard.module.scss'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

function CollapsibleCard (props) {
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed)

  function toggleCollapsibleState (flag) {
    if (flag) {
      props.clickHandler && props.clickHandler(props.title)
    } else {
      setIsCollapsed(!isCollapsed)
    }
  }

  useEffect(() => {
    toggleCollapsibleState(false)
  }, [props.isCollapsed])

  function getIcon () {
    if (props.noIcon) {
      return null
    } else {
      let iconName = isCollapsed ? (props.customIcon && props.customIcon.close) || 'angle-right'
        : (props.customIcon && props.customIcon.open) || 'angle-down'
      return <FontAwesomeIcon icon={['fal', iconName]} className={styles.iconStyle} style={props.styleIcon} />
    }
  }

  function renderChildren () {
    return (<div className={`${styles.contentContainer} ${isCollapsed ? styles.hideChild : ''}`}>
      {props.children}
    </div>)
  }
  return (<div className={`${styles.collapsibleCardContainer}`} style={props.customStyleContainer}>
    <button className={styles.headerContainer} style={props.customStyleHeader} data-testid={'collapsibleButton'} onClick={() => { toggleCollapsibleState(true) }}>
      <span className={styles.iconContainer}>{getIcon()}</span>
      <div className={styles.title} style={props.customStyleTitle}>
        <div>{props.title}</div>
        {props.rightSideTitle &&
          <div>{props.rightSideTitle}</div>
        }
      </div>
    </button>
    {renderChildren()}
  </div>)
}

CollapsibleCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  rightSideTitle: PropTypes.string,
  noIcon: PropTypes.bool,
  styleIcon: PropTypes.object,
  customIcon: PropTypes.object,
  customStyleContainer: PropTypes.object,
  customStyleHeader: PropTypes.object,
  customStyleTitle: PropTypes.object,
  isCollapsed: PropTypes.bool,
  clickHandler: PropTypes.func
}
export default CollapsibleCard
