import './Icons.scss'

import { library } from '@fortawesome/fontawesome-svg-core'

// pro-solid-svg-icons
import { faCheckSquare } from '../assets/@fortawesome/pro-solid-svg-icons/faCheckSquare'
import { faCaretDown } from '../assets/@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretUp } from '../assets/@fortawesome/pro-solid-svg-icons/faCaretUp'
import { faCog } from '../assets/@fortawesome/pro-solid-svg-icons/faCog'
import { faCaretRight } from '../assets/@fortawesome/pro-solid-svg-icons/faCaretRight'
import { faFilter as solidFilter } from '../assets/@fortawesome/pro-solid-svg-icons/faFilter'
import { faCircle } from '../assets/@fortawesome/pro-solid-svg-icons/faCircle'
import { faChartCandlestick } from '../assets/@fortawesome/pro-solid-svg-icons/faChartCandlestick'
import { faChartArea } from '../assets/@fortawesome/pro-solid-svg-icons/faChartArea'
import { faChartLine } from '../assets/@fortawesome/pro-solid-svg-icons/faChartLine'
import { faChartScatter } from '../assets/@fortawesome/pro-solid-svg-icons/faChartScatter'
import { faStar as faStarSolid } from '../assets/@fortawesome/pro-solid-svg-icons/faStar'
import { faLock as faLockSolid } from '../assets/@fortawesome/pro-solid-svg-icons/faLock'
import { faDownload as faDownloadSolid } from '../assets/@fortawesome/pro-solid-svg-icons/faDownload'
import { faLongArrowUp as faLongArrowUpSolid } from '../assets/@fortawesome/pro-solid-svg-icons/faLongArrowUp'
import { faLongArrowDown as faLongArrowDownSolid } from '../assets/@fortawesome/pro-solid-svg-icons/faLongArrowDown'
import { faExclamationCircle, faExclamationCircle as faExclamationCircleSolid } from '../assets/@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle as faExclamationTriangleSolid } from '../assets/@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faThLarge } from '../assets/@fortawesome/pro-solid-svg-icons/faThLarge'
import { faAsterisk } from '../assets/@fortawesome/pro-solid-svg-icons/faAsterisk'
import { faGlobe } from '../assets/@fortawesome/pro-solid-svg-icons/faGlobe'
import { faCommentAlt } from '../assets/@fortawesome/pro-solid-svg-icons/faCommentAlt'
import { faRectangleWide } from '../assets/@fortawesome/pro-solid-svg-icons/faRectangleWide'
import { faHeart as faHeartSolid } from '../assets/@fortawesome/pro-solid-svg-icons/faHeart'

// pro-light-svg-icons
import { faCalendarAlt } from '../assets/@fortawesome/pro-light-svg-icons/faCalendarAlt'
import { faFileAlt } from '../assets/@fortawesome/pro-light-svg-icons/faFileAlt'
import { faTimes } from '../assets/@fortawesome/pro-light-svg-icons/faTimes'
import { faFilter } from '../assets/@fortawesome/pro-light-svg-icons/faFilter'
import { faSearch } from '../assets/@fortawesome/pro-light-svg-icons/faSearch'
import { faCog as faCogLight } from '../assets/@fortawesome/pro-light-svg-icons/faCog'
import { faCaretUp as faUpCaretLight } from '../assets/@fortawesome/pro-light-svg-icons/faCaretUp'
import { faCaretDown as faDownCaretLight } from '../assets/@fortawesome/pro-light-svg-icons/faCaretDown'
import { faBell } from '../assets/@fortawesome/pro-light-svg-icons/faBell'
import { faExclamationTriangle } from '../assets/@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faPencil } from '../assets/@fortawesome/pro-light-svg-icons/faPencil'
import { faPlus } from '../assets/@fortawesome/pro-light-svg-icons/faPlus'
import { faAngleLeft } from '../assets/@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faAngleRight } from '../assets/@fortawesome/pro-light-svg-icons/faAngleRight'
import { faSpinner } from '../assets/@fortawesome/pro-light-svg-icons/faSpinner'
import { faInfoCircle } from '../assets/@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faDownload } from '../assets/@fortawesome/pro-light-svg-icons/faDownload'
import { faStar as faStarLight } from '../assets/@fortawesome/pro-light-svg-icons/faStar'
import { faCheck } from '../assets/@fortawesome/pro-light-svg-icons/faCheck'
import { faEllipsisV } from '../assets/@fortawesome/pro-light-svg-icons/faEllipsisV'
import { faFilePdf } from '../assets/@fortawesome/pro-light-svg-icons/faFilePdf'
import { faMinusSquare } from '../assets/@fortawesome/pro-light-svg-icons/faMinusSquare'
import { faLongArrowDown } from './@fortawesome/pro-light-svg-icons/faLongArrowDown'
import { faLongArrowUp } from './@fortawesome/pro-light-svg-icons/faLongArrowUp'
import { faLock as faLockLight } from './@fortawesome/pro-light-svg-icons/faLock'
import { faHourglassHalf } from './@fortawesome/pro-light-svg-icons/faHourglassHalf'
import { faPlusCircle } from '../assets/@fortawesome/pro-light-svg-icons/faPlusCircle'
import { faPlusSquare } from '../assets/@fortawesome/pro-light-svg-icons/faPlusSquare'
import { faChevronRight as falChevronRight } from '../assets/@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronLeft as falChevronLeft } from '../assets/@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faHeart } from '../assets/@fortawesome/pro-light-svg-icons/faHeart'

// pro-duotone-svg-icons
import { faSpinnerThird } from '../assets/@fortawesome/pro-duotone-svg-icons/faSpinnerThird'
import { faAngleDown } from '../assets/@fortawesome/pro-light-svg-icons/faAngleDown'

// pro-regular-svg-icons
import { faChevronRight } from '../assets/@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronLeft } from '../assets/@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronDown } from '../assets/@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faLock } from '../assets/@fortawesome/pro-regular-svg-icons/faLock'
import { faSyncAlt } from '../assets/@fortawesome/pro-regular-svg-icons/faSyncAlt'
import { faEye } from '../assets/@fortawesome/pro-regular-svg-icons/faEye'
import { faEyeSlash } from '../assets/@fortawesome/pro-regular-svg-icons/faEyeSlash'
import { faHistory } from '../assets/@fortawesome/pro-regular-svg-icons/faHistory'
import { faCaretCircleDown } from '../assets/@fortawesome/pro-regular-svg-icons/faCaretCircleDown'
import { faExclamationTriangle as faExclamationTriangleReg } from '../assets/@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faSearch as faSearchRegular } from '../assets/@fortawesome/pro-regular-svg-icons/faSearch'
import { faPlus as faPlusRegular } from '../assets/@fortawesome/pro-regular-svg-icons/faPlus'
import { faPlusCircle as faPlusCircleRegular } from '../assets/@fortawesome/pro-regular-svg-icons/faPlusCircle'
import { faEllipsisV as faEllipsisVRegular } from '../assets/@fortawesome/pro-regular-svg-icons/faEllipsisV'
import { faChartLine as faChartLineRegular } from '../assets/@fortawesome/pro-regular-svg-icons/faChartLine'
import { faPencil as faPencilRegular } from '../assets/@fortawesome/pro-regular-svg-icons/faPencil'
import { faAsterisk as faAsteriskRegular } from '../assets/@fortawesome/pro-regular-svg-icons/faAsterisk'

library.add(
  faCheckSquare, faCalendarAlt, faFileAlt, faChevronRight,
  faChevronLeft, faChevronDown, faCaretDown, faCaretUp, faTimes, faFilter,
  solidFilter, faLock, faEye, faEyeSlash, faSyncAlt, faSearch, faSearchRegular, faEllipsisV, faChartLine,
  faChartArea, faChartScatter, faCog, faAsterisk, faAsteriskRegular, faCogLight, faUpCaretLight,
  faDownCaretLight, faSpinnerThird, faBell, faCircle, faChartCandlestick,
  faExclamationTriangle, faPencil, faCaretRight, faPlus, faSpinner, faAngleLeft, faExclamationTriangleReg,
  faAngleRight, faInfoCircle, faStarSolid, faHistory, faStarLight, faExclamationTriangleSolid, faExclamationCircle,
  faCheck, faDownload, faLockSolid, faDownloadSolid, faCaretCircleDown, faFilePdf, faPlusSquare, faMinusSquare,
  faLongArrowUp, faLongArrowDown, faPlusCircle, faLockLight, faHourglassHalf, faLongArrowUpSolid, faLongArrowDownSolid,
  faExclamationCircleSolid, faPlusRegular, faPlusCircleRegular, faEllipsisVRegular, faPencilRegular, faThLarge, faChartLineRegular,
  falChevronLeft, falChevronRight, faAngleDown, faGlobe, faCommentAlt, faRectangleWide, faHeart, faHeartSolid
)
