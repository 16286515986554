/* eslint-disable */
import {
  ENVIRONMENTS_DOMAINS,
  TIER_TYPES
} from './appConstants'

/**
 * Method used to get a query string parameter value
 * @param {URL parameter key} key
 * @return String
 */
export const getQueryStringParameterValue = (key, ignorCase = false) => {
  if (!window.location.search) return undefined;

  const params = (
      /^[?#]/.test(window.location.search) ?
      window.location.search.slice(1) :
      window.location.search
    )
    .split("&")
    .reduce((params, param) => {
      const [key, value] = param.split("=");
      const tempKey = ignorCase ? key.toLowerCase() : key
      params[tempKey] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
      return params;
    }, {});

  if (!params || Object.keys(params).length < 1) return undefined;

  return params[key];
};

export const isDebugInfoOn = () => {
  let showdebuginfo = getQueryStringParameterValue('..showdebuginfo..', true) || ''
  return showdebuginfo.toLowerCase() === 'on'
}

/**
 * Method used to get a object with query string as property 
 * @return String
 */
export const getAllQueryStringParameterValue = () => {
  if (window.location.href.split('?').length > 1) {
    let allQueryStringList = window.location.href.split('?')[1].split('#')[0].split('&')
    let queryStringObject = {}
    let elem = []
    for (let i = allQueryStringList.length - 1; i >= 0; i--) {
      elem = allQueryStringList[i].split('=')
      queryStringObject[elem[0]] = elem[1]
    }
    return queryStringObject
  } else {
    return undefined
  }
}

export const deviceType = {
  Desktop: "DESKTOP",
  Ipad: "IPAD",
  Mobile: "MOBILE",
};

export const hideScroll = (isScroll) => {
  return isScroll ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
}

export const getDeviceType = () => {
  const screenWidth = document.documentElement.clientWidth;
  let device = deviceType.Desktop;
  if (screenWidth >= 1024) {
    device = deviceType.Desktop;
  } else if (screenWidth >= 596 && screenWidth <= 1024) {
    device = deviceType.Ipad;
  } else if (screenWidth < 596 && screenWidth > 0) {
    device = deviceType.Mobile;
  }
  return device;
};

export function remvoveHTMLTags(fullText = "", charLimit) {
  fullText = fullText.replace("...", "");
  return {
    __html: charLimit ? truncateText(fullText, charLimit) : fullText
  };
}

export function truncateText(text, charLimit, endingText = "...") {
  return text.length > charLimit ? (text.substring(0, charLimit - endingText.length) + endingText) : text;
}

/**
 * Method used to get the current environment based on window location host
 */
export const getEnvironment = () => {
  const host = window.location.hostname
  let environment = ''

  if (ENVIRONMENTS_DOMAINS.LOCALHOST.includes(host)) {
    environment = 'localhost'
  } else if (ENVIRONMENTS_DOMAINS.DEVELOPMENT.includes(host)) {
    environment = 'development'
  } else if (ENVIRONMENTS_DOMAINS.ACCEPTANCE.includes(host)) {
    environment = 'acceptance'
  } else if (ENVIRONMENTS_DOMAINS.ACCEPTANCE_2.includes(host)) {
    environment = 'acceptance-2'
  } else if (ENVIRONMENTS_DOMAINS.PRODUCTION.includes(host)) {
    environment = 'production'
  } else if (ENVIRONMENTS_DOMAINS.ACCEPTANCE_3.includes(host)) {
    environment = 'acceptance-3'
  } else if (ENVIRONMENTS_DOMAINS.ACCEPTANCE_4.includes(host)) {
    environment = 'acceptance-4'
  }

  return environment
}

export const handleWindowResize = (callBackHandler) => {
  window.addEventListener('resize', () => {
    callBackHandler(getDeviceType())
  })
}

export const handleScrollChange = (callBackHandler) => {
  window.addEventListener('scroll', () => {
    callBackHandler(getDeviceType())
  })
}

export const getRangeStep = (min, max) => {
  let diff = max - min
    if (diff < 1) {
      return '0.001'
    } else
    if (diff < 10) {
      return '0.01'
    } else { return 1 }
}

export const getCompleteHeightPage = () => {
  return Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight
  ) // document height with scrolls included 
}

/* Get Scroll position from the top even if there are mutliple scrolls above */
export const getScrollPositionsFromTop = (child) => {
  let parent = child.parentNode
  let xyPosition = 0
  while (parent && !isNaN(parent.scrollTop)) {
    xyPosition += parent.scrollTop
    parent = parent.parentNode
  }
  return xyPosition
}

export const addArgument = (field, values, operator) => {
  return {
    field: field,
    conditions: [{
      operator: (operator || 'EqualTo'),
      values: values
    }]
  };
}

export const closeModalPopup = (e) => {
  e.preventDefault()
  if ((e.target.getAttribute('id') === 'closeButton' || e.target.tagName === 'path' || e.target.tagName === 'svg') || (((e.key === 'Escape' && e.keyCode === 27) || e.type === 'click') && e.target.getAttribute('id') === 'outerModalPopUp')) {
    return true
}
  return false
}
export function isNull (val) {
  return typeof val === 'undefined' || val === null || val === '-32768' || val === -32768
}

export function splitNewsContentByDash (content) {
  const contentArray = content && content.split('--')
  if (contentArray) {
    return contentArray.length > 1 ? contentArray[1] : contentArray[0]
  }
  return ''
}

export function enterKeyEvent (e, handler) {
  if (e.key === 'Enter' && e.keyCode === 13) {
    e.preventDefault();
    handler(e)
  }
}

export function openPDFInNewTab (response) {
  if (!response.isError && response.data) {
    response.data instanceof Blob && window.open(window.URL.createObjectURL(response.data))
  } else {
    response.isError && logDebugInfo('Some Error Occured! Unable to open the file')
  }
}

export function sortByKey(arrayObject, key, isAsc, isPercentage, nullKey = '--', ignoreCase = false) {
  arrayObject && arrayObject.sort(function (a, b) {
    let floatValues = {}
    if (isPercentage) {
      floatValues = getSafeValues(parseFloat(a[key]), parseFloat(b[key]), nullKey, true)
    } else {
      floatValues = getSafeValues(a[key], b[key], nullKey)
    }
    
    const first = ignoreCase && typeof floatValues.a === 'string' ? floatValues.a?.toLowerCase() : floatValues.a
    const second= ignoreCase && typeof floatValues.b === 'string'? floatValues.b?.toLowerCase() : floatValues.b
    if (isAsc) {
      return first < second ? 1 : -1
    }
    return first > second ? 1 : -1
  })
  return arrayObject
}

function getSafeValues(a, b, nullKey, isPercentage) {
  let aValue = ''
  let bValue = ''
  let maxString = 'zzzzzzzzzzzzzzzzzzzzz'
  if (isPercentage) {
    aValue = isNaN(a) ? Number.MAX_SAFE_INTEGER : a
    bValue = isNaN(b) ? Number.MAX_SAFE_INTEGER : b
  }
  else {
    // Check if the Value is equal to '--'
    aValue = a && a === nullKey
    bValue = b && b === nullKey
    if (aValue === true) { // if aValue === '--'
      bValue = b
      aValue = fetchMaxValue(aValue, bValue, maxString) // fetch max value for aValue
    }
    if (bValue === true) { // if bValue === '--'
      aValue = a
      bValue = fetchMaxValue(bValue, aValue, maxString) // fetch max value for bValue
    }
    if (!aValue && !bValue) { // if both values are normal & correct
      aValue = a
      bValue = b
    }
  }
  return {
    a: aValue,
    b: bValue
  }
}
export function logDebugInfo(message) {
  console.log(message);
}

export function generateUniqueID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0
    let v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

function fetchMaxValue(a, b, maxString) {
  let max = Number.MAX_SAFE_INTEGER
  if (isNaN(parseFloat(b))) {
    max = maxString
  }
  return max
}

export const createArrayGroups = (arr, numGroups) => {
  const perGroup = Math.ceil(arr.length / numGroups)
  return new Array(numGroups)
    .fill('')
    .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
}

export const getNoOfMonthsBetweenTwoDates = (d1, d2) => {
  if (d1 && d2) {
    let months
    months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth()
    months += d2.getMonth()
    return months
  }
}

export const checkIfUserIsRealTime  = (userTier) => {
  return userTier === TIER_TYPES.REALTIME
}

export const getRealtimeHeader = (isRealTime, userData) => {
  if (isRealTime) {
    let siteId = userData && userData.siteId && userData.siteId.trim()
    if (siteId && siteId.length > 7) {
      siteId = siteId && siteId.substring(0, 7)
    }
    if (siteId && userData && userData.userId && userData.ibd) {
      return {
        realtime: isRealTime,
        reportIdentifier: JSON.stringify({UserId: userData.userId, CorrespondentNum:  userData.ibd, NetworkId: 'Y', ChargeCustomer: 'Y', SiteId: siteId})
      }
    } else {
      return {
        realtime: isRealTime
      }
    }
  } else {
    return {
      realtime: false
    }
  }
}

export function setSelectedSecurity(selectedSecurity = {}) {
  if (selectedSecurity?.eqSymbol) {
    selectedSecurity['eqSymbol'] = decodeURIComponent(selectedSecurity.eqSymbol);
  }
  if (selectedSecurity?.nonEqSymbol) {
    selectedSecurity['nonEqSymbol'] = decodeURIComponent(selectedSecurity.nonEqSymbol);
  }
  setSessionStorage('selectedSecurity', JSON.stringify(selectedSecurity))
}
export function getSelectedSecurity(){
  let item = getSessionStorageItem('selectedSecurity', {})
  if (item?.eqSymbol) {
    item['eqSymbol'] = decodeURIComponent(item.eqSymbol);
  }
  if (item?.nonEqSymbol) {
    item['nonEqSymbol'] = decodeURIComponent(item.nonEqSymbol);
  }
  return {...item};
}

export function getSelectedEquities(){
  return getSessionStorageItem('equityCompareData', [])
}

export function setSelectedEquities(symbols = []){
  setSessionStorage('equityCompareData', JSON.stringify(symbols))
}

export function setSessionStorage(key, value){
  window.sessionStorage.setItem(key, value)
}

export function getSessionStorageItem(key, defaultValue){
let item = ''; 

  try {
    item = JSON.parse(window.sessionStorage.getItem(key)) || defaultValue;
  } catch (error) {
    item = defaultValue;
  }
  return item
}

export const getDisabledModules = () => {
  const disabledModulesList = ['education'] // TO-DO - We can remove any module code if we want to unhide it 
  return disabledModulesList
}

export const getSymbolToDisplay = (symbol='') => {
  return (symbol + ' ') || '';
}