export const PREMIUM_PRICE_MOVEMENT = {
  CHART: {
    DAYS: 14,
    DATAINTERVAL: 1,
    DATAPERIOD: 'Day',
    STYLE: {
      LABELFONTSIZE: 12,
      LABELFONTCOLOR: '#000000',
      LABELFONTFAMILY: 'Roboto-Regular',
      BORDERBOTTOMCOLOR: 'rgb(0, 0, 0, .3)',
      GRIDHORIZONTALCOLOR: 'rgb(0, 0, 0, .15)',
      DARKCOLOR: 'rgba(41,61,192,1)',
      FILLLIGHTCOLOR: 'rgba(41,61,192,0.25)'
    }
  }
}
