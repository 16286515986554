import React from 'react'
import styles from './PremiumPriceMovement.module.scss'
import PremiumPriceMovementChart from './PremiumPriceMovementChart/PremiumPriceMovementChart'
import Loader from '../../../Lib/common/Loader'
import Nodata from '../../../Lib/NoData/NoData'
import PropTypes from 'prop-types'
import PremiumPriceMovementTable from './PremiumPriceMovementTable/PremiumPriceMovementTable'

export default function PremiumPriceMovement ({xref, symbol, greeksData}) {
  const renderPriceMovementChart = () => {
    if (xref.isLoading) {
      return <Loader spinnerSize={'2x'} msg={'Loading'} />
    }
    if (xref.isError) {
      return <Nodata msg={`Chart not available for ${symbol}`} />
    } else if (xref.venueXid) {
      return <PremiumPriceMovementChart xid={xref.venueXid} />
    }
  }

  return (<div className={styles.premiumPriceMovementContainer}>
    <div className={styles.premiumPriceHeader}>Premium Price Movement</div>
    <div className={styles.premiumPriceContent}>
      <div className={styles.premiumPriceChart}>
        {renderPriceMovementChart()}
      </div>
      <div className={styles.premiumPriceTable}>
        <PremiumPriceMovementTable tableData={greeksData} testId={'premium_price_movement_table'} />
      </div>
    </div>
  </div>)
}
PremiumPriceMovement.propTypes = {
  xref: PropTypes.object,
  symbol: PropTypes.string,
  greeksData: PropTypes.object
}
