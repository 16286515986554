import DataRequest from '../../../../../../services/DataRequest'
import { FUND_SCREEN_TYPE, URLS } from '../../utils/appConstants'
import {deviceType, getDeviceType} from '../../../../../../utils/utilities'

export const ScreenerOptionsConstants = {
  NewScreener: 'New Screener',
  SaveThisScreener: 'Save this Screener',
  SavedScreeners: 'Saved Screeners',
  UpdateThisScreener: 'Update this Screener',
  EsgGlossary: 'ESG Glossary'
}

export const UpdateScreenerConfirmationDialogConstants = {
  HeaderInfo: `Save Changes`,
  BodyContent: `You've made changes to the selected screener criteria. Would you like to save these changes?`,
  ConfirmButtonText: `Save`,
  CancelButtonText: `Don't Save`
}

export const makeUpdateScreenerApiCall = (screenerID, postData, callback) => {
  DataRequest.execute(
    `${URLS.PLATFORM.SAVEDSCREENS}/` + screenerID,
    {
      method: 'PUT',
      postData: postData
    },
    callback,
    null
  )
}

export const screenerDropdownOptions = (selectedScreenerId, type, isPreScreener = false) => {
  const options = []
  options.push(ScreenerOptionsConstants.NewScreener)
  if (!isPreScreener) {
    if (selectedScreenerId) {
      options.push(ScreenerOptionsConstants.UpdateThisScreener)
    } else {
      options.push(ScreenerOptionsConstants.SaveThisScreener)
    }
  }
  options.push(ScreenerOptionsConstants.SavedScreeners)
  if (getDeviceType() === deviceType.Mobile && type === FUND_SCREEN_TYPE) {
    options.push(ScreenerOptionsConstants.EsgGlossary)
  }
  return options
}

export async function getExcelSheetData (dataCount, TabKeysArray, getExportTableData, SetCsvDataForExport, threshold) {
  return new Promise((resolve) => {
  const promiseArray = []
  const batchCount = Math.ceil(dataCount / threshold)
  for (let iCount = 0; iCount < batchCount; iCount++) {
    let remainingFundCount = dataCount - iCount * threshold
    let offset = iCount === 0 ? 0 : iCount * threshold
    let csvLimit = remainingFundCount > threshold ? threshold : remainingFundCount
    promiseArray.push(getExportTableData(offset, csvLimit, 'All'))
    }
  let output = []
  Promise.all(promiseArray).then((responseArray) => {
    responseArray.forEach(response => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        output = [...output, ...responseData.items]
      }
    })
    let workSheetArray = []
    TabKeysArray.forEach((tabkey) => {
      const outputArray = [];
      const exportArray = [...SetCsvDataForExport(output, tabkey)]
      if (exportArray.length) {
          outputArray.push(Object.keys(exportArray[0]));
          exportArray?.forEach(obj => {
            const row = Object.values(obj);
            outputArray.push(row);
          });
        }
      workSheetArray.push({name: tabkey, data: outputArray})
    })
    return resolve(workSheetArray)
  })
})
}

// Download Kebab Menu Action Handler
export const actionClickHandler = (e, DownloadCsv, DownloadAll) => {
  let action = e.currentTarget.innerHTML
  if (action === 'Download Selected Tab') {
    DownloadCsv()
  } else {
    DownloadAll()
  }
}

export const UpdateScreenerConfirmationMessage = 'Screener has been updated'
export const ScreenerCreatedMessage = 'Screener created successfully'
export const ScreenerDetailsUpdatedMessage = 'Screener details has been updated'
