import DataRequest from '../services/DataRequest'
import { URLS } from '../utils/appConstants'
import {getRealtimeHeader} from '../utils/utilities'

export const makeCorporateDividendHistoryCall = (venueXid, inputParams, requestInput, callbackHandler) => {
  DataRequest.execute(
    `${URLS.PLATFORM.CORPORATEACTIONSDIVIDENDS}/` + venueXid + '/historical-events',
    {
      params: inputParams,
      requestInput: requestInput
    },
    dataCallBack,
    null
  )
  function dataCallBack (response) {
    callbackHandler(response)
  }
}

export const getPeers = (venueXid, callback) => {
  DataRequest.execute(`${URLS.PLATFORM.EQUITIES_MORNING_STAR_PEERS}/${venueXid}`,
    {
      params: {
        rankingTypeName: 'closest'
      }
    },
    callback,
    null
  )
}

export const getEntityXidFromXref = (symbol, callback) => {
  DataRequest.execute(
    `${URLS.PLATFORM.XREFGETSYMBOL}/${symbol}`,
    {
      params: {
        isCaseSensitive: false
      }
    },
    callback,
    null
  )
}

export const getQuoteData = (symbols, callback, realTime, userInfo, navNotYield, token) => {
  DataRequest.execute(
    URLS.PLATFORM.QUOTE,
    {
      params: {
        venueXids: symbols,
        marketHoursTradeType: 'normal',
        clearPremarketQuote: false,
        ...(navNotYield && { navNotYield })
      }
    },
    callback,
    token || null,
    (realTime!==null && realTime!==undefined) ? getRealtimeHeader(realTime, userInfo) : null
  )
}

export const getEstimatesDataWithPromise = (venueXid) => {
  return new Promise((resolve) => {
    let callback = (response) => {
      return resolve(response)
    }

    DataRequest.execute(
      `${URLS.PLATFORM.EQUITIES_MORNINGSTAR_ESTIMATES}/current/` + venueXid,
      {
        requestInput: {
          venueXid: venueXid
        }
      },
      callback,
      null
    )
  })
}

// Get the response from Xref and you will be able to get venueXid and entityXid from the response in the callback
export const getXrefResponse = (symbol, callback) => {
  DataRequest.execute(
    `${URLS.PLATFORM.XREFGETSYMBOL}/` + symbol,
    {
      params: {
        isCaseSensitive: false
      }
    },
    callback,
    null
  )
}

export const getFinancialStatementResponse = (entityXid, statementType, periodType, numberOfPeriods, callback) => {
  DataRequest.execute(
    `${URLS.PLATFORM.FINANCIAL_STATEMENTS}/` + entityXid,
    {
      params: {
        statementType: statementType,
        periodType: periodType,
        numberOfPeriods: numberOfPeriods
      }
    },
    callback,
    null
  )
}

export const getDistinctValuesFromScreener = (field, screenType, callback) => {
  DataRequest.execute(
    `${URLS.CUSTOM.MIN_MAX_VALUES_SCREEN + '?searchField=' + field + '&screenType=' + screenType}`,
    {
      method: 'POST',
      postData: {
        data: {
        }
      }
    },
    callback,
    null)
}

export const getDistinctValuesFromPFScreener = (field, screenId, callback) => {
  DataRequest.execute(
    `${URLS.PLATFORM.DISTINCT_VALUES_SCREEN + '/' + screenId + '/' + field + '/distinctValues'}`,
    null,
    callback,
    null)
}
