'use strict'

import React from 'react'
import PropTypes from 'prop-types'
import { formatDate } from '../../../utils/formatter'
import { splitNewsContentByDash } from '../../../utils/utilities'
import styles from './NewsHeader.module.scss'
import ShowMoreText from 'react-show-more-text'

export default function NewsHeader ({ provider, date, headline, noOfLinesHeader }) {
  const providerAndDate = `${provider} | ${formatDate(date, {UTCtoEST: true, postFix: 'ET'})}`
  return (
    <div className={styles.newsHeaderContainer}>
      <div className={styles.provider}>{providerAndDate}</div>
      <div className={styles.headline}>
        {noOfLinesHeader ? <ShowMoreText
          lines={noOfLinesHeader}
          more=''
          less=''
          expanded={false}
        >
          {splitNewsContentByDash(headline)}
        </ShowMoreText>
          : splitNewsContentByDash(headline)}
      </div>
    </div>
  )
}
NewsHeader.propTypes = {
  provider: PropTypes.string,
  date: PropTypes.string,
  headline: PropTypes.string,
  noOfLinesHeader: PropTypes.number
}
