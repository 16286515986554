import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { ModchartsInteractive, locales } from '@markit/modcharts/dist/modcharts.umd.js'
import '../../../../Lib/ModChartsCss/ModCharts.scss'
import DataRequest from '../../../../../services/DataRequest'
import { PREMIUM_PRICE_MOVEMENT } from './PremiumPriceMovementConstants'
import styles from './PremiumPriceMovementChart.module.scss'
import Loader from '../../../../Lib/common/Loader'
import DataUnavailable from '../../../../Lib/DataUnavailable/DataUnavailable'

export default function PremiumPriceMovementChart ({xid}) {
  const [premiumPriceChart, setPremiumPriceChart] = useState(null)
  const [isChartEmpty, setChartIsEmpty] = useState(false)
  const [chartLoading, setChartLoading] = useState(true)
  const locale = locales.en_US
  const propXid = `${xid}`
  locale.labelTemplates.xAxis.doubleRow.days = {default: '%b %e', first: '', firstAlt: '%b %e', intraday: '%b %e'}
  locale.labelTemplates.xAxis.doubleRow.years = {default: '%N', first: '%N'}

  useEffect(() => {
    if (!premiumPriceChart && document.getElementById('premiumPriceChartDomElement')) {
      initNewChart()
    }
  }, [])

  const handleChartLoad = (chartObject) => {
    chartObject.setZoomEnabled(false)
    setPremiumPriceChart(chartObject)
    setChartLoading(false)
  }

  const handleLoadStop = (chartObject) => {
    if (chartObject && chartObject.status === 0 && Object.keys(chartObject.data).length === 0) {
      setChartIsEmpty(true)
      setChartLoading(false)
    }
  }
  const bindChartEvents = (chartObject) => {
    chartObject.eventEmitter.on('DATA_LOAD_STOP', () => {
      handleLoadStop(chartObject)
    })
  }

  const initNewChart = () => {
    const chart = new ModchartsInteractive('#premiumPriceChartDomElement')
    const authToken = DataRequest.getAccessToken()
    chart.setAuthToken(authToken)
    const chartConfig = {
      params: {
        locale: locale,
        apiPath: window.MD.MOD_CHART_URL,
        symbol: propXid,
        days: PREMIUM_PRICE_MOVEMENT.CHART.DAYS,
        dataInterval: PREMIUM_PRICE_MOVEMENT.CHART.DATAINTERVAL,
        dataPeriod: PREMIUM_PRICE_MOVEMENT.CHART.DATAPERIOD,
        style: {
          panel: {
            border: {
              color: {
                bottom: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.BORDERBOTTOMCOLOR,
                default: 'transparent'
              }
            },
            grid: {
              color: 'transparent',
              horizontal: {
                color: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.GRIDHORIZONTALCOLOR,
                lineDash: ''
              },
              vertical: {
                alt: { color: 'transparent' }
              }
            },
            xAxis: {
              label: {
                padding: {
                  top: 12,
                  left: 0
                },
                textAlign: 'center',
                lineHeight: 12
              },
              font: {
                color: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.LABELFONTCOLOR,
                family: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.LABELFONTFAMILY,
                size: 12
              }
            },
            yAxis: {
              label: {
                padding: {
                  left: 12
                }
              },
              font: {
                color: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.LABELFONTCOLOR,
                family: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.LABELFONTFAMILY,
                size: 12
              }
            },
            margin: {
              bottom: 30
            }
          }
        }
      },
      panels: [{
        indicators: [{
          id: 'price',
          markerType: 'fill',
          style: {
            fillColor: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.FILLLIGHTCOLOR,
            fillColorStop: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.FILLLIGHTCOLOR,
            width: 1
          },
          symbol: propXid
        }, {
          id: 'price',
          markerType: 'lineWithShapes',
          style: {
            color: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.DARKCOLOR,
            shape: { type: 'circle', color: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.DARKCOLOR, fillColor: PREMIUM_PRICE_MOVEMENT.CHART.STYLE.DARKCOLOR, length: 4 },
            width: 1
          },
          symbol: propXid
        }]
      }]
    }
    chart.load(chartConfig, handleChartLoad)
    bindChartEvents(chart)
  }

  return (<div className={styles.premiumPriceMovementChartContainer}>
    <div id={'premiumPriceChartDomElement'}>
      {chartLoading && <Loader ready={!chartLoading} spinnerSize={'2x'} msg={'Loading'} />}
      {isChartEmpty && <DataUnavailable />}
    </div>
  </div>)
}
PremiumPriceMovementChart.propTypes = {
  xid: PropTypes.number
}
