import React, { useState, useEffect } from 'react'
import PerformanceChart from './PerformanceChart'
import styles from './Performance10K.module.scss'
import { GROWTH_10K_PERFORMANCE, URLS, CHART_STYLES } from '../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import { handleWindowResize, getDeviceType } from '../../../../../../utils/utilities'
import { registerAccessTokenUpdatedF2OnEvent } from '../../../../../../utils/f2Methods'
import PropTypes from 'prop-types'
import Loader from '../../../../../Lib/common/Loader'
import NoData from '../../../../../Lib/NoData/NoData'

const Performance10KFund = (props) => {
  const [device, setDeviceType] = useState(getDeviceType())
  const [intervalMapping] = useState(GROWTH_10K_PERFORMANCE.intervalMapping)
  const [periodMapping] = useState(GROWTH_10K_PERFORMANCE.periodMapping)
  const [selectedMonths, setSelectedMonths] = useState(GROWTH_10K_PERFORMANCE.dropDownDaysValuesInMonths['1 Year'])
  const [isLoading, setIsLoading] = useState(true)
  const [chartData, setChartData] = useState(null)

  useEffect(() => {
    handleWindowResize((response) => {
      setDeviceType(response)
    })
    registerAccessTokenUpdatedF2OnEvent(() => {
      setSelectedMonths(selectedMonths)
    })
  }, [])

  useEffect(() => {
    // To set latest data in chart rendering. Depending on this - chart component is rendering
    setIsLoading(true)
    getGrowthOf10KByWsodIssue()
  }, [props.wsodIssue, selectedMonths])

  const getGrowthOf10KByWsodIssue = () => {
    if (props.wsodIssue) {
      let callback = (response) => {
        if (!response.isDataRequestComplete) return
        if (!response.isError) {
          const responseData = response.data
          if (responseData && responseData.length > 0) {
            setChartData(responseData)
          }
        } else {
          // error
        }
        setIsLoading(false)
      }

      DataRequest.execute(
        `${URLS.CUSTOM.GROWTH_OF_10K_FUND}?wsodIssue=` + props.wsodIssue + '&timeFrame=' + selectedMonths,
        {
          params: {
            isCaseSensitive: false
          }
        },
        callback,
        null
      )
    }
  }
  const callChart = e => {
    if (!e) return
    e.preventDefault()
    setSelectedMonths(GROWTH_10K_PERFORMANCE.TIME_PERIOD_IN_MONTH[e.target.innerText])
  }

  const getYearButton = (numberOfMonths, yearText) => {
    return <div className={`${selectedMonths === numberOfMonths ? styles['selectedItem'] : ''} ${styles['item']}`}>
      <button className={styles.button} onClick={callChart} onKeyPress={callChart}>{yearText}</button>
    </div>
  }

  function getChartSelection () {
    let body = []
    let bodyHtml =
      <div className={styles.itemsContainer} key={device}>
        {getYearButton(12, '1Y')}
        {getYearButton(36, '3Y')}
        {getYearButton(60, '5Y')}
        {getYearButton(120, '10Y')}
      </div>

    body.push(bodyHtml)
    return body
  }
  const getChartInputs = () => {
    const dataInterval = intervalMapping[selectedMonths]
    const dataPeriod = periodMapping[selectedMonths]
    return {
      'timePeriod': selectedMonths,
      'dataInterval': dataInterval,
      'dataPeriod': dataPeriod,
      'wsodIssue': props.wsodIssue,
      'chartType': CHART_STYLES[1].value
    }
  }
  const chartInputs = getChartInputs()
  return (
    <div className={`${styles.performanceContainer} ${styles.marginSetter}`}>
      <span className={styles.TopText} >Hypothetical Growth of 10K
      </span>
      <div className={styles.chartSelections}>
        {getChartSelection()}
      </div>
      {isLoading &&
      <div className={styles.loaderContainer}>
        <Loader ready={!isLoading}
          spinnerSize={'2x'}
          msg={'Loading'}
        />
      </div>
      }
      <div className={styles.chart}>
        {!isLoading && props.wsodIssue && chartData && <PerformanceChart chartInputs={chartInputs} ChartData={chartData} symbol={props.symbol} />}
        {((!isLoading && props.wsodIssue && !chartData) || (isLoading && !props.wsodIssue && !chartData)) && <NoData />}
      </div>
      {/* To-Do - Uncomment later in Day 2 item */}
      {/* {!isLoading && <div className={styles.AdvChtLink}>Go to Performance & Risk</div>} */}
    </div>
  )
}
Performance10KFund.propTypes = {
  wsodIssue: PropTypes.number,
  symbol: PropTypes.string
}
export default Performance10KFund
