/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import BasicDropDown from '../../Shared/Dropdown/BasicDropDown'
import styles from './FundScreener.module.scss'
import SxSelect from '../../Shared/SxSelect/SxSelect'
import TableWithShowMore from '../../Shared/Table/TableWithShowMore'
import TopLevelFilterDisplayName from './FilterOptions/TopLevelFilterDisplayName.json'
import MostPopularFilterOptions from './FilterOptions/MostPopular.json'
import TechnicalIndicatorsFilterOptions from './FilterOptions/TechnicalIndicators.json'
import PerformanceFilterOptions from './FilterOptions/Performance.json'
import BasicsFilterOptions from './FilterOptions/Basics.json'
import PortfolioCharacteristicsFilterOptions from './FilterOptions/PortfolioCharacteristics.json'
// import TechnicalIndicatorFilterOptions from './FilterOptions/TechnicalIndicator.json'
import OperationalFilterOptions from './FilterOptions/Operational.json'
import AssetAllocationFilterOptions from './FilterOptions/AssetAllocation.json'
import RatingsFilterOptions from './FilterOptions/Ratings.json'
import RiskFilterOptions from './FilterOptions/Risk.json'
import IncomeFilterOptions from './FilterOptions/Income.json'
import ESGFundRatingOptions from './FilterOptions/EsgFundRatings.json'
import ESGCarbonMetricsOptions from './FilterOptions/EsgCarbonMetrics.json'
import KeyMeasuresHeader from './FundScreenerMockData/Key Measures/KeyMeasuresHeader.json'
import OperationalHeader from './FundScreenerMockData/Operational/OperationalHeader.json'
import ProfileHeader from './FundScreenerMockData/Profile/ProfileScreenerHeader.json'
import AssetAllocationHeader from './FundScreenerMockData/Asset Allocation/AssetAllocationHeader.json'
import IncomeHeader from './FundScreenerMockData/Income/IncomeHeader.json'
import TechnicalIndicatorsHeader from './FundScreenerMockData/Technical Indicators/TechnicalIndicatorsHeader.json'
import EsgRatingHeader from './FundScreenerMockData/ESG Rating/EsgRating.json'
import EsgCarbonMetricsHeader from './FundScreenerMockData/Esg Carbon Metrics/EsgCarbonMetrics.json'
import PerformanceHeader from './FundScreenerMockData/Performance/PerformanceScreenerHeader.json'
import PortfolioCharacteristicsHeader from './FundScreenerMockData/Portfolio Characteristics/PortfolioCharacteristicsHeader.json'
import RatingsHeader from './FundScreenerMockData/Ratings/RatingsScreenerHeader.json'
import RiskHeader from './FundScreenerMockData/Risk/RiskScreenerHeader.json'
// import NoResults from '../../Shared/NoResults/NoResults'
import ModalRoot from '../../Shared/Modal/components/ModalRoot'
import ModalService from '../../Shared/Modal/service/ModalService'
import SaveScreenPopup from '../../Shared/SaveScreenerModal/SaveScreenPopup'
import OpenScreenerPopup from '../../Shared/OpenScreenerModal/OpenScreenerPopup'
import DataRequest from '../../../../../../services/DataRequest'
// check
import { URLS, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS, DEFAULT_FUND_CARD_FUNDSCREENER_INPUTS, FundScreenerResultColumnArr, FundScreenerKeyMeasuresArr, FundScreenerOperationalArr, FundScreenerProfileArr, FundScreenerAssetAllocationArr, FundScreenerPerformanceArr, FundScreenerPortfolioCharacteristicsArr, FundScreenerRatingsArr, FundScreenerRiskArr, FundScreenerTechIndicatorArr, FundScreenerEsgRatingArr, FUND_SCREEN_ID, FUND_SCREEN_TYPE, DATE_FORMAT, FundScreenerIncomeArr, SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API, SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API_ALL, FundScreenerTableFieldDictionary, MOD_GOTO_PAGES, SCREENER_TYPES, FundScreenerEsgCarbonMetricsArr, SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_ALL_FUNDS } from '../../../components/utils/appConstants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { CreateUUID, pascalize } from '../../utils/utilities'
// Custom hook used for common slider code
import useSlider from '../../Shared/CustomHook/Slider'
import TabControl from '../../Shared/TabControl/TabControl'
// import filters config
import { getDistinctValuesFromScreener, getDistinctValuesFromPFScreener, getQuoteData } from '../../../../../../shared/dataMethods'
import moment from 'moment'
import { registerF2OnEvent } from '../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import ImportantDisclosure from '../../Shared/Disclosures/ImportantDisclosures/ImportantDisclosure'
import PerformanceDisclosure from '../../Shared/Disclosures/PerformanceDisclosures/PerformanceDisclosure'
import Footer from '../../Shared/Disclosures/Footer/Footer'
import {deviceType, handleWindowResize, getDeviceType, truncateText} from '../../../../../../utils/utilities'
import ScreenerHelper from '../../Helpers/ScreenerHelper'
import { textFormatter, formatNumber, numberFormatter, convertToInternationalCurrencySystem, formatDate, convertAvgMarketCapCurrencySystem, getRatingDescription, getYesNoStatus, isBankCustody } from '../../utils/formatter'
import QuoteCard from '../../Shared/QuoteCardWithStockHighlights/QuoteCardWithStockHighlights'
import {handleAsterisk, screenerBoolDictionary, screenerDivFreqDictionary, screenerIndicatorDictionary, ScreenerFundVestTag, fundVestTag, updateMarginLeftForScreenerFilters, isElementInViewport} from '../../Helpers/CommonFunctions'
import { emitF2Event } from '../../../../../../utils/f2Methods'
import { makeUpdateScreenerApiCall, ScreenerCreatedMessage, ScreenerDetailsUpdatedMessage, screenerDropdownOptions, ScreenerOptionsConstants, UpdateScreenerConfirmationDialogConstants, UpdateScreenerConfirmationMessage } from '../../Shared/Screener/Constants'
import { fireToastMessage } from '../../../../../Lib/CustomToast.js/CustomToast'
import ConfirmationPopup from '../../../../../Watchlists/View/WatchlistStocks/ConfirmationPopup/ConfirmationPopup'
import EsgGlossary from '../../Shared/ESGGlossary/EsgGlossary'
import FundEsgGlossary from '../../Shared/ESGGlossary/FundEsgGlossary.json'
import Loader from '../../../../../Lib/common/Loader'
import AddToWatchlists from '../../../../../Watchlists/AddToWatchlists/AddToWatchlists'
import * as XLSX from 'xlsx';
import { TabKeysFund, actionList } from '../Equity/Constants'
import ActionMenu from '../../../../../Lib/ActionMenu/ActionMenu'
import { actionClickHandler, getExcelSheetData } from '../Shared/Constants'

function FundScreener (props) {
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false)
  const [selectedScreenerId, setSelectedScreenerId] = useState()
  const [errorMsg, setErrorMsg] = useState('')
  const [csvData, setCsvData] = useState({data: [], fileName: 'KeyMeasures.csv'})
  const savedScreenName = useRef([])
  const [keyMeasuresHeaders, setKeyMeasuresHeaders] = useState(KeyMeasuresHeader.headers)
  const [operationalHeaders, setOperationalHeaders] = useState(OperationalHeader.headers)
  const [performanceHeaders, setPerformanceHeaders] = useState(PerformanceHeader.headers)
  const [portfolioCharacteristicsHeaders, setPortfolioCharacteristicsHeaders] = useState(PortfolioCharacteristicsHeader.headers)
  const [profileHeaders, setProfileHeaders] = useState(ProfileHeader.headers)
  const [ratingsHeaders, setRatingsHeaders] = useState(RatingsHeader.headers)
  const [assetAllocationHeaders, setAssetAllocationHeaders] = useState(AssetAllocationHeader.headers)
  const [incomeHeaders, setIncomeHeaders] = useState(IncomeHeader.headers)
  const [technicalIndicatorsHeaders, setTechnicalIndicatorsHeaders] = useState(TechnicalIndicatorsHeader.headers)
  const [esgRatingHeaders, setEsgRatingHeaders] = useState(EsgRatingHeader.headers)
  const [riskHeaders, setRiskHeaders] = useState(RiskHeader.headers)
  const [esgCarbonMetricsHeaders, setESGCarbonMetricsHeaders] = useState(EsgCarbonMetricsHeader.headers)
  const [keyHeadersChangeId, setKeyHeadersChangeId] = useState(0)
  const [opHeadersChangeId, setOpHeadersChangeId] = useState(0)
  const [performanceHeadersChangeId, setPerformanceHeadersChangeId] = useState(0)
  const [portCharHeadersChangeId, setPortCharHeadersChangeId] = useState(0)
  const [profileHeadersChangeId, setProfileHeadersChangeId] = useState(0)
  const [ratingsHeadersChangeId, setRatingsHeadersChangeId] = useState(0)
  const [assetHeadersChangeId, setAssetHeadersChangeId] = useState(0)
  const [incomeHeadersChangeId, setIncomeHeadersChangeId] = useState(0)
  const [technicalIndicatorsHeadersChangeId, setTechnicalIndicatorsHeaderChangeId] = useState(0)
  const [esgCarbonMetricsHeadersChangeId, setESGCarbonMetricsHeadersChangeId] = useState(0)
  const [esgRatingHeadersChangeId, setEsgRatingHeadersChangeId] = useState(0)
  const [riskHeadersChangeId, setRiskHeadersChangeId] = useState(0)
  const [isDownloadingStart, setIsDownloadingStart] = useState(false)
  const sortArgument = useRef([
    {
      direction: 'A',
      field: 'SPCFticker'
    }
  ])
  const getFilterOptions = (screenerOption = 'default') => {
    const args = TopLevelFilterDisplayName.selectedFilterNames.find(x => x.name === (screenerOption))
    return args
  }

  const [activeTabKey, setActiveTabKey] = useState('Key Measures')
  const [keyMsrData, setkeyMsrData] = useState({data: []})
  const [operatData, setOperatData] = useState({data: []})
  const [profileData, setProfileData] = useState({data: []})
  const [assetAllocationData, setAssetAllocationData] = useState({data: []})
  const [performanceData, setPerformanceData] = useState({data: []})
  const [portfolioCharacterisiticsData, setPortfolioCharacterisiticsData] = useState({data: []})
  const [ratingsData, setRatingsData] = useState({data: []})
  const [riskData, setRiskData] = useState({data: []})
  const [technicalIndicatorsData, setTechnicalIndicatorsData] = useState({data: []})
  const [esgRatingData, setEsgRatingData] = useState({data: []})
  const [incomeData, setIncomeData] = useState({data: []})
  const [esgCarbonMetricsData, setEsgCarbonMetricsData] = useState({data: []})
  const [showMoreClickCount, setShowMoreClickCount] = useState(0)
  const [screenerName, setScreenerName] = useState(props.screenerName || '')
  const [screenerDescription, setScreenerDescription] = useState('')
  const [delayedQuoteDate, setDelayedQuoteDate] = useState('')
  const [topScreenResults, setTopScreenResults] = useState([])
  const screenId = useRef('')
  const csvInstance = useRef()
  const mostPopularfilterOptions = MostPopularFilterOptions.mostPopularfilterOptions
  const technicalIndicatorsFilterOptions = TechnicalIndicatorsFilterOptions.technicalIndicatorsFilterOptions
  const basicsfilterOptions = BasicsFilterOptions.basicsfilterOptions
  const performanceFilterOptions = PerformanceFilterOptions.performanceFilterOptions
  const portfolioCharacteristicsFilterOptions = PortfolioCharacteristicsFilterOptions.portfolioCharacteristicsFilterOptions
  const incomeFilterOptions = IncomeFilterOptions.incomefilterOptions
  const operationalFilterOptions = OperationalFilterOptions.operationalFilterOptions
  const assetAllocationfilterOptions = AssetAllocationFilterOptions.assetAllocationfilterOptions
  const ratingsfilterOptions = RatingsFilterOptions.ratingsfilterOptions
  const riskfilterOptions = RiskFilterOptions.riskfilterOptions
  const esgFundRatingFilterOptions = ESGFundRatingOptions.esgFundRatingfilterOptions
  const esgCarbonMetricsFilterOptions = ESGCarbonMetricsOptions.esgCarbonMetricsfilterOptions
  const filterNames = JSON.parse(JSON.stringify(TopLevelFilterDisplayName.filterNames))
  const [filterDisplayName, setFilterDisplayName] = useState(filterNames)
  const [isMobile, setIsMobile] = useState(false)
  const [deviceTypeItem, setDeviceType] = useState(deviceType.Desktop)
  const [screenerFilterOptionsToBeSaved, setscreenerFilterOptionsToBeSaved] = useState(props.screenerFilterOptionsToBeSaved || [])
  const technicalIndicatorsDynamicFilterList = ['SPCFOnBalanceVolume', 'SPCFVolume', 'SPCFAvg10DayVolume', 'SPCFVolume90dAvg', 'SPCFPctChgVs52wkLo', 'SPCFPctChgVs52wkHi', 'SPCFDayDiff52wHigh', 'SPCFDayDiff52wLow', 'SPCFPremiumDiscountM1Average', 'SPCFPremiumDiscountY1Average', 'SPCFPremiumDiscountY1High', 'SPCFPremiumDiscountY1Low']

  const asssetAllocationDynamicFilterList = ['MSDAssetClass', 'MSDBondSector', 'SPCFTotalBondNet', 'SPCFBondSectorCashAllocationNet', 'SPCFStockSectorBasicMaterials', 'SPCFStockSectorCommunicationServices',
    'SPCFStockSectorCommunicationServices', 'SPCFStockSectorConsumerCyclical', 'SPCFStockSectorEnergy', 'SPCFStockSectorFinancialServices', 'SPCFStockSectorHealthcare',
    'SPCFStockSectorIndustrials', 'SPCFStockSectorRealEstate', 'SPCFStockSectorTechnology', 'SPCFStockSectorUtilities',
    'SPCFCreditQualityAAA', 'SPCFCreditQualityAA', 'SPCFCreditQualityA', 'SPCFCreditQualityBBB', 'SPCFCreditQualityBB', 'SPCFCreditQualityB', 'SPCFCreditQualityBelowB', 'SPCFCreditQualityNotRated', 'SPCFWeightingTop10Holdings', 'SPCFYearlyTurnoverRatio',
    'SPCFTotalStockNet', 'SPCFCashNet', 'SPCFConvertibleNet', 'SPCFOtherNet', 'SPCFBondSectorCorporateAllocationNet', 'SPCFBondSectorDerivativeAllocationNet', 'SPCFBondSectorGovernmentAllocationNet', 'SPCFBondSectorMunicipalAllocationNet',
    'SPCFBondSectorSecuritizedAllocationNet', 'SPCFRegionUnitedStates', 'SPCFRegionCanada', 'SPCFRegionEurope', 'SPCFRegionAsia', 'SPCFRegionOther', 'SPCFStockSectorConsumerDefensive']

  const basicsDynamicFilterList = ['SPCFGrossExpenseRatio', 'SPCFActual12B1Fee', 'SPCFInceptionDate', 'SPCFManagerTenure', 'SPCFMStarOverrideMinimumInitialInvestment', 'SPCFMStarOverrideSubsequentMinimumInvestment', 'SPCFMinInitialRetirementInvestment', 'SPCFFundAssetClassGroup', 'SPCFProspectusPrimaryBenchmarkName']
  const riskDynamicFilterList = ['SPCFSharpeRatioY3', 'SPCFSharpeRatioY5', 'SPCFStandardDeviationY3', 'SPCFStandardDeviationY5',
    'SPCFPrimaryIndexAlphaY3', 'SPCFPrimaryIndexAlphaY5', 'SPCFPrimaryIndexBetaY3', 'SPCFPrimaryIndexBetaY5', 'SPCFPrimaryIndexRSquaredY3',
    'SPCFPrimaryIndexRSquaredY5', 'SPCFCaptureRatio3YearUp', 'SPCFCaptureRatio3YearDown', 'SPCFCaptureRatio5YearUp', 'SPCFCaptureRatio5YearDown']
  const performanceDynamicFilterList = ['SPCFNAVChgPct', 'SPCFTrailingMarketReturnM1', 'SPCFTrailingMarketReturnM3', 'SPCFTrailingMarketReturnYTD', 'SPCFTrailingMarketReturnY1', 'SPCFTrailingMarketReturnY3',
    'SPCFTrailingMarketReturnY5', 'SPCFTrailingMarketReturnY10', 'SPCFTrailingReturnM1', 'SPCFTrailingReturnM3', 'SPCFTrailingReturnYTD', 'SPCFTrailingReturnY1', 'SPCFTrailingReturnY3',
    'SPCFTrailingReturnY5', 'SPCFTrailingReturnY10', 'SPCFTaxCostRatioY3', 'SPCFTaxCostRatioY5']
  const ratingsDynamicFilterList = ['PERMMorningstarOverallRating', 'PERMMorningstarY3Rating', 'PERMMorningstarY5Rating', 'PERMMorningstarY10Rating']
  const [screenerArguments, setScreenerArguments] = useState(props.screenerArguments || [])
  const screenerArgumentsRef = useRef()
  const portfolioCharacteristicsDynamicFilterList = ['SPCFNumberofHoldingsNet', 'SPCFAvgCoupon', 'SPCFYieldToMaturityLong', 'SPCFEarningsYieldLongInv', 'SPCFAverageMarketCap', 'SPCFAverageCreditQualityLongLetter']
  const mostPopularDynamicFilterList = ['SPCFMStarOverrideFundFamilyName', 'SPCFProspectusNetExpenseRatio', 'SPCFFundNetAssets', 'SPCFTrailingReturnY3', 'SPCFTaxCostRatioY3']
  const operationalDynamicFilterList = ['SPCFMStarOverrideFundShareClass']
  const incomeDynamicFilterList = ['SPCFTrailing1YrYield', 'SPCFSECYield']
  const esgFundRatingDynamicFilterList = ['SPCFPortSustRatingCorpContibPct', 'SPCFPortSustRatingSovgContribPct', 'SPCFPctAumLowControversies', 'SPCFPctAumNegligibleESGRiskScores', 'SPCFPctAumLowESGRiskScores', 'SPCFPctAumMediumESGRiskScores', 'SPCFPctAumHighESGRiskScores', 'SPCFPctAumSevereESGRiskScores', 'SPCFCorpESGNeg2Med', 'SPCFCorpESGHigh2Sev', 'SPCFPctAumNoControversies', 'SPCFPctAumLowControversies', 'SPCFPctAumModerateControversies', 'SPCFPctAumSignificantControversies', 'SPCFPctAumHighControversies', 'SPCFPctAumSevereControversies', 'SPCFPctAumMod2NoControversies', 'SPCFPctAumSev2SigControversies', 'SPCFSovgPctPortCoveredNegligibleESGRiskScore', 'SPCFSovgPctPortCoveredLowESGRiskScore', 'SPCFSovgPctPortCoveredMediumESGRiskScore', 'SPCFSovgPctPortCoveredHighESGRiskScore', 'SPCFSovgPctPortCoveredSevereESGRiskScore', 'SPCFSovESGNeg2Med', 'SPCFSovESGHigh2Sev']
  const esgCarbonMetricsDynamicFilterList = ['SPCFCarbonRiskScorePercentRankinCategory', 'SPCFAverage12MonthFossilFuelExposure', 'SPCFCarbonIntensity', 'SPCFPercentofAUMwithNegligibleCarbonRisk', 'SPCFPercentofAUMwithLowCarbonRisk', 'SPCFPercentofAUMwithMediumCarbonRisk', 'SPCFPercentofAUMwithSevereCarbonRisk', 'SPCFPercentofAUMwithHighCarbonRisk', 'SPCFPctAUMNeg2MedCarbonRisk', 'SPCFPctAUMHigh2SevCarbonRisk']
  const productTypesForMF = ['Open-End Mutual Fund', 'Money Market', 'Mutual Fund']
  const [watchlistObj, setWatchlistObj] = useState({})

  useEffect(() => {
    screenerArgumentsRef.current = screenerArguments
  }, [screenerArguments])

  // On desktop, for L0 filters left and right scroll functionality enabled
  // Hack - here, no dependency array is set, if we set blank dependency array, it stops working
  useEffect(() => {
    let startDragging
    let stopDragging
    setTimeout((startDragging, stopDragging) => {
      const slider = document.getElementById('screenerFiltersContainer')

      if (slider) {
        let mouseDown = false
        let startX, scrollLeft
        startDragging = function (e) {
          mouseDown = true
          startX = e.pageX - slider.offsetLeft
          scrollLeft = slider.scrollLeft
        }
        stopDragging = function (event) {
          mouseDown = false
        }
        slider.addEventListener('mousemove', (e) => {
          if (e.target.type === 'range') {
            return
          }
          e.preventDefault()
          if (!mouseDown) { return }
          const x = e.pageX - slider.offsetLeft
          const scroll = x - startX
          slider.scrollLeft = scrollLeft - scroll
        })

        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, false)
        slider.addEventListener('mouseup', stopDragging, false)
        slider.addEventListener('mouseleave', stopDragging, false)
      }
    }, 0)
    return () => {
      document.removeEventListener('mousedown', startDragging)
      document.removeEventListener('mouseup', stopDragging)
      document.removeEventListener('mouseleave', stopDragging)
    }
  })
  useEffect(() => {
    setIsMobile(getDeviceType() === 'MOBILE')
    setDeviceType(getDeviceType())
    handleWindowResize((deviceType) => {
      setIsMobile(deviceType === 'MOBILE')
      setDeviceType(getDeviceType())
    })
    setResetScreenerFilter(CreateUUID())
    getQuote()
    if (props.isTabChange) {
      updateTable()
    }
    setTimeout(() => mostPopularSelectRef.current && mostPopularSelectRef.current.toggleList(null, true), 0)
    registerF2OnEvent(EVENT_NAMES.MY_AVAILABLE_FUNDS, (isMyAvailableFund) => {
      let newScreenerArguments = [
        {
          field: 'SPCFFundFamilyCode',
          conditions: [
            {
              operator: 'Like',
              values: [isMyAvailableFund]
            }
          ]
        }
      ]
      setScreenerArguments(newScreenerArguments)
      getTableData(0, newScreenerArguments, sortArgument && sortArgument.current)
    })
    getScreenData()
    return () => {
      const scName = document.querySelector('#screenName') && document.querySelector('#screenName').innerHTML // Hack bcoz state variable is returning blank
      props.tabChangePersistState({
        screenerFilterOptionsToBeSaved: screenerFilterOptionsToBeSaved,
        screenerName: scName,
        screenerArguments: screenerArgumentsRef.current
      })
    }
  }, [deviceTypeItem])

  useEffect(() => {
    if (props.symbol) {
      if (!(props.navigationFromViewAllLinkFlag || props.screenerPillChange || props.isTabChange) || props.symbol === 'pageLoad') {
        getDefaultFilterOptions()
      }
    } else if (sessionStorage.getItem('fund-context') && props.isTabChange && props.screenerArguments.length === 0) {
      getDefaultFilterOptions()
    }
  }, [props.navigationFromViewAllLinkFlag, props.screenerPillChange, props.isTabChange, props.symbol])

  function getDefaultFilterOptions () {
    const obj = getFilterOptions(props.symbol === 'pageLoad' ? undefined : props.symbol)
    SetFilterOptionsFromSavedScreener(obj.defaultScreener)
  }

  useEffect(() => {
    const viewAllDefaultFilterOption = []
    if (props.navigationFromViewAllLinkFlag && (props.symbol && (props.symbol === 'AllETF' || props.symbol === 'AllMF'))) {
      viewAllDefaultFilterOption.push(getFilterOptions(props.symbol))
    }
    if (props.navigationFromViewAllLinkFlag && viewAllDefaultFilterOption.length) {
      resetFilterOptions(true)
      sessionStorage.removeItem('fund-context')
      SetFilterOptionsFromSavedScreener(viewAllDefaultFilterOption[0].defaultScreener)
    }
  }, [props.navigationFromViewAllLinkFlag])

  useEffect(() => {
    if (props.screenerPillChange) {
      resetFilterOptions(true)
      sessionStorage.removeItem('fund-context')
      const obj = getFilterOptions(props.symbol)
      SetFilterOptionsFromSavedScreener(obj.defaultScreener)
    }
  }, [props.screenerPillChange])

  const basicDropdown = useRef()
  const [modelCompare, setModelCompare] = useState([])
  const [resetScreenerFilter, setResetScreenerFilter] = useState('')
  const [modalHeader, setModalHeader] = useState('')
  const [mostPopular, setMostPopular] = useState(mostPopularfilterOptions)
  const [technicalIndicators, setTechnicalIndicators] = useState(technicalIndicatorsFilterOptions)
  const [technicalIndicatorsRaw] = useState(JSON.parse(JSON.stringify(technicalIndicatorsFilterOptions))) // Added stringify and parse to remove the object reference
  const [basics, setBasics] = useState(basicsfilterOptions)
  const [operation, setOperation] = useState(operationalFilterOptions)
  const [assetAllocation, setAssetAllocation] = useState(assetAllocationfilterOptions)
  const [ratings, setRatings] = useState(ratingsfilterOptions)
  const [risk, setRisk] = useState(riskfilterOptions)
  const [income, setIncome] = useState(incomeFilterOptions)
  const [performance, setPerformance] = useState(performanceFilterOptions)
  const [portfolioCharacteristics, setPortfolioCharacteristics] = useState(portfolioCharacteristicsFilterOptions)
  const [updatedArguments, setUpdatedArguments] = useState([])
  const [esgFundRating, setESGFundRating] = useState(esgFundRatingFilterOptions)
  const [esgCarbonMetrics, setESGCarbonMetrics] = useState(esgCarbonMetricsFilterOptions)
  const [showGlossary, setShowGlossary] = useState(false)
  const [isDownloading, setDownloading] = useState(false)
  // const [technicalIndicator, setTechnicalIndicator] = useState(technicalIndicatorFilterOptions)
  // const [createpills, setCreatepills] = useState(filterOptions)
  const basicSelectRef = useRef()
  const operatonSelectRef = useRef()
  const mostPopularSelectRef = useRef()
  const technicalIndicatorsSelectRef = useRef()
  const assetAllocationSelectRef = useRef()
  const ratingsSelectRef = useRef()
  const riskSelectRef = useRef()
  const performanceSelectRef = useRef()
  const portfolioCharacteristicsSelectRef = useRef()
  const incomeSelectRef = useRef()
  const esgFundRatingSelectRef = useRef()
  const esgCarbonMetricsSelectRef = useRef()
  // Populating custom hook
  useSlider('screenerFiltersContainer', screenerName)

  useEffect(() => {
    setTimeout(() => {
      syncActiveTabTableOnly(activeTabKey)
    }, 1000)
    // To handle null check
    const fundCardContainer = document.getElementById('fundCardContainer')
    if (fundCardContainer && fundCardContainer.style) {
      fundCardContainer.style.display = ''
      if (fundDataCount === 0) {
        fundCardContainer.style.display = 'none'
      }
    }
  })

  function SetCsvDataForExport (items, tabKey=activeTabKey) {
    let tempData = []
    switch (tabKey) {
      case 'Key Measures':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Morningstar Category Name': textFormatter(item.SPCFMorningstarCategoryName),
            'Morningstar Rating - Overall': formatNumber(item.SPCFMorningstarOverallRating, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Net Expense Ratio (%)': formatNumber(item.SPCFProspectusNetExpenseRatio, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Product Type': textFormatter(item.SPCFFundType),
            'FundVest (Focus/Institutional)': textFormatter(screenerIndicatorDictionary[item.SPCFNoTransactonFeeEligibilityIndicator]),
            'FundVest ETF': textFormatter(screenerBoolDictionary[item.SPCFIsFVETF]),
            'FundVest ETF Added Date': formatDate(item.SPCFDateAdded, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE}),
            'Net Assets': convertToInternationalCurrencySystem(item.SPCFFundNetAssets),
            'NAV Return (3Yr %)': formatNumber(item.SPCFTrailingReturnY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Tax Cost Ratio (3Yr %)': formatNumber(item.SPCFTaxCostRatioY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            '12b-1 Fund': textFormatter(screenerBoolDictionary[item.SPCFIndicator12b1]),
            'Actual 12b-1 Fee': formatNumber(item.SPCFActual12B1Fee, {precision: 2}),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex),
            'Pershing Eligible (FundCenter) Mutual Funds': getYesNoStatus(item.SPCFTradeStatus),
            'CUSIP': textFormatter(item.SPCFMStarOverrideCUSIP),
            'ISIN': textFormatter(item.SPCFISIN),
            'Fund Family': textFormatter(item.SPCFMStarOverrideFundFamilyName)
          })
        })
        break
      case 'Operational':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Share Class': textFormatter(item.SPCFMStarOverrideFundShareClass),
            'FundServ': textFormatter(screenerBoolDictionary[item.SPCFFundservIndicator]),
            'Offshore Fund': textFormatter(ScreenerHelper.updateColumnUIMapping('SPCFOffShoreIndicator', item.SPCFOffShoreIndicator)),
            'Offshore Trading Platform': textFormatter(ScreenerHelper.updateColumnUIMapping('SPCFOffShoreProcessingIndicator', item.SPCFOffShoreProcessingIndicator)),
            'Load Type': textFormatter(ScreenerHelper.updateColumnUIMapping('SPCFMStarOverrideLoadType', item.SPCFMStarOverrideLoadType)),
            'Closed To New Investors': textFormatter(screenerBoolDictionary[item.SPCFMStarOverrideClosedToNewInvestors]),
            'Closed to Sells': textFormatter(screenerBoolDictionary[item.SPCFClosedToSellsIndicator]),
            'Tender/Interval Fund': textFormatter(screenerBoolDictionary[item.SPCFTenderIndicator]),
            'Dealer Agreement Required - Load Trades': textFormatter(screenerBoolDictionary[item.SPCFDealerAgreementRequiredForLoadTrades]),
            'Dealer Agreement Required - Load Transfers': textFormatter(screenerBoolDictionary[item.SPCFDealerAgreementRequiredForLoadTransfers]),
            'Dealer Agreement Required - No-Load Trades': textFormatter(screenerBoolDictionary[item.SPCFDealerAgreementRequiredForNo_LoadTrades]),
            'Dealer Agreement Required - No-Load Transfers': textFormatter(screenerBoolDictionary[item.SPCFDealerAgreementRequiredForNo_LoadTransfers]),
            'Bank Custody': textFormatter(isBankCustody(item.SPCFBNYMellon)),
            'Surcharge': textFormatter(screenerBoolDictionary[item.SPCFSURCHARGE])
          })
        })
        break
      case 'Profile':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Broad Asset Class': textFormatter(item.SPCFFundAssetClassGroup),
            'Leveraged Fund': textFormatter(screenerBoolDictionary[item.SPCFLeveragedFundFlag]),
            'Benchmark Leverage Factor': formatNumber(item.SPCFLeveragedFundFactor),
            'Primary Underlying Benchmark': textFormatter(item.SPCFProspectusPrimaryBenchmarkName),
            'Gross Expense Ratio': formatNumber(item.SPCFGrossExpenseRatio, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Load Amount (Deferred)': formatNumber(item.SPCFSalesLoadDeferred, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Load Amount (Front-end)': formatNumber(item.SPCFSalesLoadMaxFrontLoad, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Inception Date': formatDate(item.SPCFInceptionDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            'Manager Tenure': formatNumber(item.SPCFManagerTenure, {precision: 2}),
            'Minimum Initial Investment': numberFormatter(item.SPCFMStarOverrideMinimumInitialInvestment)
          })
        })
        break
      case 'Asset Allocation':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Top 10 Weight (%)': formatNumber(item.SPCFWeightingTop10Holdings, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Annual Turnover Ratio (%)': formatNumber(item.SPCFYearlyTurnoverRatio, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Annual Turnover Ratio Date': formatDate(item.SPCFTurnoverRatioDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            'Morningstar Equity Style Box': textFormatter(item.SPCFEquityStyleBoxText),
            'Morningstar Bond Style Box': textFormatter(item.SPCFFixedIncomeStyleBoxText),
            'Total Bonds (Net %)': formatNumber(item.SPCFTotalBondNet, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Total Stock (Net %)': formatNumber(item.SPCFTotalStockNet, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Portfolio Date': formatDate(item.SPCFPortfolioDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true})
          })
        })
        break
      case 'Income':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Distribution Frequency': textFormatter(screenerDivFreqDictionary[item.SPCFMStarOverrideDividendFrequency] || item.SPCFMStarOverrideDividendFrequency),
            'Trailing 1 Year Yield': formatNumber(item.SPCFTrailing1YrYield, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'SEC Yield': formatNumber(item.SPCFSECYield, {negSign: true, posSign: true, precision: 2, parentheses: false})
          })
        })
        break
      case 'Performance':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'NAV % Change Last': formatNumber(item.SPCFNAVChgPct, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Change': formatNumber(item.SPCFNAVChgAmt, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (YTD %)': formatNumber(item.SPCFTrailingMarketReturnYTD, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (1Yr %)': formatNumber(item.SPCFTrailingMarketReturnY1, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (3Yr %)*': formatNumber(item.SPCFTrailingMarketReturnY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (5Yr %)*': formatNumber(item.SPCFTrailingMarketReturnY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (10Yr %)*': formatNumber(item.SPCFTrailingMarketReturnY10, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (YTD %)': formatNumber(item.SPCFTrailingReturnYTD, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (1Yr %)': formatNumber(item.SPCFTrailingReturnY1, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (3Yr %)*': formatNumber(item.SPCFTrailingReturnY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (5Yr %)*': formatNumber(item.SPCFTrailingReturnY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return  (10Yr %)*': formatNumber(item.SPCFTrailingReturnY10, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Tax Cost Ratio (3Yr %)': formatNumber(item.SPCFTaxCostRatioY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Tax Cost Ratio (5Yr %)': formatNumber(item.SPCFTaxCostRatioY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Performance As Of Date': formatDate(item.SPCFPerformanceAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true})
          })
        })
        break
      case 'Portfolio Characteristics':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Number of Holdings - Total': numberFormatter(item.SPCFNumberofHoldingsNet),
            'Average Market Cap': convertAvgMarketCapCurrencySystem(item.SPCFAverageMarketCap),
            'Average Price to Earnings': formatNumber(item.SPCFEarningsYieldLongInv, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Average Credit Quality': textFormatter(item.SPCFAverageCreditQualityLongLetter),
            'Average Coupon': formatNumber(item.SPCFAvgCoupon, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Average Yield To Maturity': formatNumber(item.SPCFYieldToMaturityLong, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Portfolio Date': formatDate(item.SPCFPortfolioDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true})
          })
        })
        break
      case 'Ratings':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Morningstar Medalist Rating': textFormatter(item.SPCFMedalistRating),
            'Morningstar Rating - Overall': numberFormatter(item.SPCFMorningstarOverallRating),
            'Morningstar Rating - 3 Year': numberFormatter(item.SPCFMorningstar3YrRating),
            'Morningstar Rating - 5 Year': numberFormatter(item.SPCFMorningstar5YrRating),
            'Morningstar Rating - 10 Year': numberFormatter(item.SPCFMorningstar10YrRating),
            'Morningstar Performance Score - Overall': getRatingDescription(item.SPCFReturnScore),
            'Morningstar Risk Score - Overall': getRatingDescription(item.SPCFRiskScore),
            'Rating Date': formatDate(item.SPCFMedalistRatingDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            'Morningstar Rating As of Date': formatDate(item.SPCFMStarRatingAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true})
          })
        })
        break
      case 'Risk':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Sharpe Ratio (3Yr %)': formatNumber(item.SPCFSharpeRatioY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Sharpe Ratio (5Yr %)': formatNumber(item.SPCFSharpeRatioY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Standard Deviation (3Yr %)': formatNumber(item.SPCFStandardDeviationY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Standard Deviation (5Yr %)': formatNumber(item.SPCFStandardDeviationY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Alpha (3Yr %)**': formatNumber(item.SPCFPrimaryIndexAlphaY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Alpha (5Yr %)**': formatNumber(item.SPCFPrimaryIndexAlphaY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Beta (3Yr %)**': formatNumber(item.SPCFPrimaryIndexBetaY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Beta (5Yr %)**': formatNumber(item.SPCFPrimaryIndexBetaY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Rsquared (3Yr %)**': formatNumber(item.SPCFPrimaryIndexRSquaredY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Rsquared (5Yr %)**': formatNumber(item.SPCFPrimaryIndexRSquaredY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Capture Ratio Up (3Yr)': formatNumber(item.SPCFCaptureRatio3YearUp, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Capture Ratio Down (3Yr)': formatNumber(item.SPCFCaptureRatio3YearDown, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Capture Ratio Up (5Yr)': formatNumber(item.SPCFCaptureRatio5YearUp, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Capture Ratio Down (5Yr)': formatNumber(item.SPCFCaptureRatio5YearDown, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Morningstar Primary Benchmark': textFormatter(item.SPCFMorningstarPrimaryBenchmarkName),
            'Performance As Of Date': formatDate(item.SPCFPerformanceAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true})
          })
        })
        break
      case 'Technical Indicators':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'On Balance Volume Indicator': textFormatter(item.SPCFOnBalanceVolume),
            'Volume - Last Trade Day': formatNumber(item.SPCFVolume),
            'Volume - 10 Day Average': formatNumber(item.SPCFAvg10DayVolume),
            'Volume - 90 Day Average': formatNumber(item.SPCFVolume90dAvg),
            'Percent Above 52 Week Low (%)': formatNumber(item.SPCFPctChgVs52wkLo),
            'Percent Below 52 Week High (%)': formatNumber(item.SPCFPctChgVs52wkHi),
            'Premium/Discount 1 Month Average': formatNumber(item.SPCFPremiumDiscountM1Average, {negSign: true, posSign: true}),
            'Premium/Discount 1 Year Average': formatNumber(item.SPCFPremiumDiscountY1Average, {negSign: true, posSign: true}),
            'Premium/Discount 1 Year High': formatNumber(item.SPCFPremiumDiscountY1High, {negSign: true, posSign: true}),
            'Premium/Discount 1 Year Low': formatNumber(item.SPCFPremiumDiscountY1Low, {negSign: true, posSign: true}),
            'Premium/Discount As Of Date': formatDate(item.SPCFPremiumDiscountAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true})
          })
        })
        break
      case 'ESG Rating':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Sustainable Investment': textFormatter(item.SPCFSustainableInvestment),
            'Morningstar Sustainability Rtg': textFormatter(item.SPCFSustainabilityRating),
            'Port Corp Sustainability Rtg': getRatingDescription(item.SPCFPortCorpSustRating),
            'Port Sovereign Sustainability Rtg': getRatingDescription(item.SPCFPortSovgSustRating),
            'Corp Sustainability Score (12M Avg)': formatNumber(item.SPCFFundHistAvgSustScore, {precision: 2}),
            'Sov Sustainability Score (12M Avg)': formatNumber(item.SPCFHistSovgSustScore, {precision: 2}),
            'Environmental Score': formatNumber(item.SPCFPortfolioEnvironmentalScore, {precision: 2}),
            'Governance Score': formatNumber(item.SPCFPortfolioGovernanceScore, {precision: 2}),
            'Social Score': formatNumber(item.SPCFPortfolioSocialScore, {precision: 2}),
            'Corp % Port Contrib To Sustainability Rtg': formatNumber(item.SPCFPortSustRatingCorpContibPct, {precision: 2}),
            'Sov % Port Contrib to Sustainability Rtg': formatNumber(item.SPCFPortSustRatingSovgContribPct, {precision: 2}),
            'Corp ESG Risk Score (% Corp AUM) - Neg/Med Sum': formatNumber((item.SPCFCorpESGNeg2Med), {precision: 2}),
            'Corp ESG Risk Score (% Corp AUM) - High/Sev (Sum)': formatNumber((item.SPCFCorpESGHigh2Sev), {precision: 2}),
            'Sov ESG Risk Score (% Sov AUM) - Neg/Med (Sum)': formatNumber((item.SPCFSovESGNeg2Med), {precision: 2}),
            'Sov ESG Risk Score (% Sov AUM) - High/Sev (Sum)': formatNumber((item.SPCFSovESGHigh2Sev), {precision: 2}),
            'Controversies (% AUM) - None/Mod (Sum)': formatNumber((item.SPCFPctAumMod2NoControversies), {precision: 2}),
            'Controversies (% AUM) - Sig/Sev (Sum)': formatNumber((item.SPCFPctAumSev2SigControversies), {precision: 2})
          })
        })
        break
      case 'ESG Carbon Metrics':
        items.map(item => {
          tempData.push({
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Carbon Risk Classification': textFormatter(item.SPCFCarbonRiskClassification),
            'Low Carbon Designation': pascalize(item.SPCFLowCarbonDesignation),
            'Carbon Risk Score - Current': formatNumber(item.SPCFCarbonRiskScore),
            'Carbon Risk Score (Current) - Cat Rank': textFormatter(item.SPCFCarbonRiskScorePercentRankinCategory),
            'Fossil Fuel Involvement (12M Avg, % AUM)': formatNumber(item.SPCFAverage12MonthFossilFuelExposure),
            'Carbon Risk % AUM - Neg/Med': formatNumber(item.SPCFPctAUMNeg2MedCarbonRisk),
            'Carbon Risk % AUM - High/Sev': formatNumber(item.SPCFPctAUMHigh2SevCarbonRisk),
            'Carbon Solutions Involvement (% AUM)': formatNumber(item.SPCFCarbonSolutionsInvolvement),
            'Carbon Solutions Cat Avg (% AUM)': formatNumber(item.SPCFCarbonSolutionsCategoryAverage),
            'Carbon Intensity (mtonne Co2/Mil Rev USD)': formatNumber(item.SPCFCarbonIntensity),
            'Carbon Intensity (Cat Avg)': formatNumber(item.SPCFCarbonIntensityCategoryAverage),
            'Percent AUM Covered Carbon': formatNumber(item.SPCFPercentAUMCoveredCarbon),
            'Scope 1 & 2 Emissions': formatNumber(item.SPCFScope1Emissions),
            'Scope 1, 2 & 3 Emissions': formatNumber(item.SPCFScope2Emissions)
          })
        })
        break
    }
    return tempData
  }

  function getQuote (venueXid) {
    let callback = (response) => {
      if (!response.isError) {
        let quotesData = response &&
          response.data.quotes
        if (quotesData.length) {
          const latestTimestamp = new Date(Math.max(...quotesData.map(e => new Date(e.data.lastTrade.date))))
          setDelayedQuoteDate(latestTimestamp)
        }
      }
    }
    getQuoteData([616660, 617621, 612458, 615897], callback, false)
  }

  function getTopScreenResults (screenerArguments) {
    const screenTopResultCallabck = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        if (responseData.items) {
          setTopScreenResults(responseData.items)
        }
      }
    }
    const topResultArguments = DEFAULT_FUND_CARD_FUNDSCREENER_INPUTS
    topResultArguments.arguments = screenerArguments || []

    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN + '?screenType=' + FUND_SCREEN_TYPE}`,
      {
        method: 'POST',
        postData: topResultArguments
      },
      screenTopResultCallabck
    )
  }

  function getFundType (fundType) {
    switch (fundType) {
      case 'CEF':
        return {name: 'CEF', color: '#7168D4'}
      case 'ETF':
      case 'ETN':
        return {name: 'ETF', color: '#008575'}
      default:
        return {name: 'MF', color: '#AE6506'}
    }
  }

  function getTableData (offset, screenerArguments, sortArguments, defaultSorting = false) {
    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        let filteredCount = 0
        if (responseData && responseData.pagination) {
          filteredCount = responseData.pagination.totalItems
        }
        let keyMeasuresTableData = []
        let operationalTableData = []
        let profileTableData = []
        let assetAllocationTableData = []
        let incomeTableData = []
        let performanceTableData = []
        let portfolioCharacterisiticsTableData = []
        let ratingTableData = []
        let riskTableData = []
        let technicalIndicatorsTableData = []
        let esgRatingTableData = []
        let esgCarbonMetricsTableData = []
        responseData.items && responseData.items.map(item => {
          let _type = getFundType(item.SPCFFundType).name
          keyMeasuresTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Morningstar Category Name': textFormatter(item.SPCFMorningstarCategoryName),
            'Morningstar Rating - Overall': formatNumber(item.SPCFMorningstarOverallRating, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Net Expense Ratio (%)': formatNumber(item.SPCFProspectusNetExpenseRatio, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Product Type': textFormatter(item.SPCFFundType),
            'FundVest (Focus/Institutional)': textFormatter(screenerIndicatorDictionary[item.SPCFNoTransactonFeeEligibilityIndicator]),
            'FundVest ETF': textFormatter(screenerBoolDictionary[item.SPCFIsFVETF]),
            'FundVest ETF Added Date': formatDate(item.SPCFDateAdded, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE}),
            'Net Assets': convertToInternationalCurrencySystem(item.SPCFFundNetAssets),
            'NAV Return (3Yr %)': formatNumber(item.SPCFTrailingReturnY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Tax Cost Ratio (3Yr %)': formatNumber(item.SPCFTaxCostRatioY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            '12b-1 Fund': textFormatter(screenerBoolDictionary[item.SPCFIndicator12b1]),
            'Actual 12b-1 Fee': formatNumber(item.SPCFActual12B1Fee, {precision: 2}),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex),
            'Pershing Eligible (FundCenter) Mutual Funds': getYesNoStatus(item.SPCFTradeStatus),
            'CUSIP': textFormatter(item.SPCFMStarOverrideCUSIP),
            'ISIN': textFormatter(item.SPCFISIN),
            'Fund Family': textFormatter(item.SPCFMStarOverrideFundFamilyName),
            IsFundVestETF: fundVestTag(item)
          })
          operationalTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Share Class': textFormatter(item.SPCFMStarOverrideFundShareClass),
            'FundServ': textFormatter(screenerBoolDictionary[item.SPCFFundservIndicator]),
            'Offshore Fund': textFormatter(ScreenerHelper.updateColumnUIMapping('SPCFOffShoreIndicator', item.SPCFOffShoreIndicator)),
            'Offshore Trading Platform': textFormatter(ScreenerHelper.updateColumnUIMapping('SPCFOffShoreProcessingIndicator', item.SPCFOffShoreProcessingIndicator)),
            'Load Type': textFormatter(ScreenerHelper.updateColumnUIMapping('SPCFMStarOverrideLoadType', item.SPCFMStarOverrideLoadType)),
            'Closed To New Investors': textFormatter(screenerBoolDictionary[item.SPCFMStarOverrideClosedToNewInvestors]),
            'Closed to Sells': textFormatter(screenerBoolDictionary[item.SPCFClosedToSellsIndicator]),
            'Tender/Interval Fund': textFormatter(screenerBoolDictionary[item.SPCFTenderIndicator]),
            'Dealer Agreement Required - Load Trades': textFormatter(screenerBoolDictionary[item.SPCFDealerAgreementRequiredForLoadTrades]),
            'Dealer Agreement Required - Load Transfers': textFormatter(screenerBoolDictionary[item.SPCFDealerAgreementRequiredForLoadTransfers]),
            'Dealer Agreement Required - No-Load Trades': textFormatter(screenerBoolDictionary[item.SPCFDealerAgreementRequiredForNo_LoadTrades]),
            'Dealer Agreement Required - No-Load Transfers': textFormatter(screenerBoolDictionary[item.SPCFDealerAgreementRequiredForNo_LoadTransfers]),
            'Bank Custody': textFormatter(isBankCustody(item.SPCFBNYMellon)),
            'Surcharge': textFormatter(screenerBoolDictionary[item.SPCFSURCHARGE]),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          profileTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Broad Asset Class': textFormatter(item.SPCFFundAssetClassGroup),
            'Leveraged Fund': textFormatter(screenerBoolDictionary[item.SPCFLeveragedFundFlag]),
            'Benchmark Leverage Factor': formatNumber(item.SPCFLeveragedFundFactor),
            'Primary Underlying Benchmark': textFormatter(item.SPCFProspectusPrimaryBenchmarkName),
            'Gross Expense Ratio': formatNumber(item.SPCFGrossExpenseRatio, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Load Amount (Deferred)': formatNumber(item.SPCFSalesLoadDeferred, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Load Amount (Front-end)': formatNumber(item.SPCFSalesLoadMaxFrontLoad, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Inception Date': formatDate(item.SPCFInceptionDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            'Manager Tenure': formatNumber(item.SPCFManagerTenure, {precision: 2}),
            'Minimum Initial Investment': numberFormatter(item.SPCFMStarOverrideMinimumInitialInvestment),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          assetAllocationTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Top 10 Weight (%)': formatNumber(item.SPCFWeightingTop10Holdings, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Annual Turnover Ratio (%)': formatNumber(item.SPCFYearlyTurnoverRatio, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Annual Turnover Ratio Date': formatDate(item.SPCFTurnoverRatioDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            'Morningstar Equity Style Box': textFormatter(item.SPCFEquityStyleBoxText),
            'Morningstar Bond Style Box': textFormatter(item.SPCFFixedIncomeStyleBoxText),
            'Total Bonds (Net %)': formatNumber(item.SPCFTotalBondNet, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Total Stock (Net %)': formatNumber(item.SPCFTotalStockNet, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Portfolio Date': formatDate(item.SPCFPortfolioDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          incomeTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Distribution Frequency': textFormatter(screenerDivFreqDictionary[item.SPCFMStarOverrideDividendFrequency] || item.SPCFMStarOverrideDividendFrequency),
            'Trailing 1 Year Yield': formatNumber(item.SPCFTrailing1YrYield, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'SEC Yield': formatNumber(item.SPCFSECYield, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          performanceTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'NAV % Change Last': formatNumber(item.SPCFNAVChgPct, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Change': formatNumber(item.SPCFNAVChgAmt, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (YTD %)': formatNumber(item.SPCFTrailingMarketReturnYTD, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (1Yr %)': formatNumber(item.SPCFTrailingMarketReturnY1, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (3Yr %)*': formatNumber(item.SPCFTrailingMarketReturnY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (5Yr %)*': formatNumber(item.SPCFTrailingMarketReturnY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Market Return (10Yr %)*': formatNumber(item.SPCFTrailingMarketReturnY10, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (YTD %)': formatNumber(item.SPCFTrailingReturnYTD, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (1Yr %)': formatNumber(item.SPCFTrailingReturnY1, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (3Yr %)*': formatNumber(item.SPCFTrailingReturnY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (5Yr %)*': formatNumber(item.SPCFTrailingReturnY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'NAV Return (10Yr %)*': formatNumber(item.SPCFTrailingReturnY10, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Tax Cost Ratio (3Yr %)': formatNumber(item.SPCFTaxCostRatioY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Tax Cost Ratio (5Yr %)': formatNumber(item.SPCFTaxCostRatioY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Performance As Of Date': formatDate(item.SPCFPerformanceAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          portfolioCharacterisiticsTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Number of Holdings - Total': numberFormatter(item.SPCFNumberofHoldingsNet),
            'Average Market Cap': convertAvgMarketCapCurrencySystem(item.SPCFAverageMarketCap),
            'Average Price to Earnings': formatNumber(item.SPCFEarningsYieldLongInv, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Average Credit Quality': textFormatter(item.SPCFAverageCreditQualityLongLetter),
            'Average Coupon': formatNumber(item.SPCFAvgCoupon, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Average Yield To Maturity': formatNumber(item.SPCFYieldToMaturityLong, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Portfolio Date': formatDate(item.SPCFPortfolioDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          ratingTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Morningstar Medalist Rating': textFormatter(item.SPCFMedalistRating),
            'Morningstar Rating - Overall': numberFormatter(item.SPCFMorningstarOverallRating),
            'Morningstar Rating - 3 Year': numberFormatter(item.SPCFMorningstar3YrRating),
            'Morningstar Rating - 5 Year': numberFormatter(item.SPCFMorningstar5YrRating),
            'Morningstar Rating - 10 Year': numberFormatter(item.SPCFMorningstar10YrRating),
            'Morningstar Performance Score - Overall': numberFormatter(item.SPCFReturnScore),
            'Morningstar Risk Score - Overall': numberFormatter(item.SPCFRiskScore),
            'Rating Date': formatDate(item.SPCFMedalistRatingDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            'Morningstar Rating As of Date': formatDate(item.SPCFMStarRatingAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          riskTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Sharpe Ratio (3Yr %)': formatNumber(item.SPCFSharpeRatioY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Sharpe Ratio (5Yr %)': formatNumber(item.SPCFSharpeRatioY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Standard Deviation (3Yr %)': formatNumber(item.SPCFStandardDeviationY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Standard Deviation (5Yr %)': formatNumber(item.SPCFStandardDeviationY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Alpha (3Yr %)**': formatNumber(item.SPCFPrimaryIndexAlphaY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Alpha (5Yr %)**': formatNumber(item.SPCFPrimaryIndexAlphaY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Beta (3Yr %)**': formatNumber(item.SPCFPrimaryIndexBetaY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Beta (5Yr %)**': formatNumber(item.SPCFPrimaryIndexBetaY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Rsquared (3Yr %)**': formatNumber(item.SPCFPrimaryIndexRSquaredY3, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Rsquared (5Yr %)**': formatNumber(item.SPCFPrimaryIndexRSquaredY5, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Capture Ratio Up (3Yr)': formatNumber(item.SPCFCaptureRatio3YearUp, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Capture Ratio Down (3Yr)': formatNumber(item.SPCFCaptureRatio3YearDown, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Capture Ratio Up (5Yr)': formatNumber(item.SPCFCaptureRatio5YearUp, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Capture Ratio Down (5Yr)': formatNumber(item.SPCFCaptureRatio5YearDown, {negSign: true, posSign: true, precision: 2, parentheses: false}),
            'Morningstar Primary Benchmark': textFormatter(item.SPCFMorningstarPrimaryBenchmarkName),
            'Performance As Of Date': formatDate(item.SPCFPerformanceAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          technicalIndicatorsTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'On Balance Volume Indicator': textFormatter(item.SPCFOnBalanceVolume),
            'Volume - Last Trade Day': formatNumber(item.SPCFVolume),
            'Volume - 10 Day Average': formatNumber(item.SPCFAvg10DayVolume),
            'Volume - 90 Day Average': formatNumber(item.SPCFVolume90dAvg),
            'Percent Above 52 Week Low (%)': formatNumber(item.SPCFPctChgVs52wkLo),
            'Percent Below 52 Week High (%)': formatNumber(item.SPCFPctChgVs52wkHi),
            'Premium/Discount 1 Month Average': formatNumber(item.SPCFPremiumDiscountM1Average, {negSign: true, posSign: true}),
            'Premium/Discount 1 Year Average': formatNumber(item.SPCFPremiumDiscountY1Average, {negSign: true, posSign: true}),
            'Premium/Discount 1 Year High': formatNumber(item.SPCFPremiumDiscountY1High, {negSign: true, posSign: true}),
            'Premium/Discount 1 Year Low': formatNumber(item.SPCFPremiumDiscountY1Low, {negSign: true, posSign: true}),
            'Premium/Discount As Of Date': formatDate(item.SPCFPremiumDiscountAsOfDate, {format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true}),
            IsFundVestETF: fundVestTag(item),
            'Offshore Flex': textFormatter(item.SPCFOffshoreFlex)
          })
          esgRatingTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Sustainable Investment': textFormatter(item.SPCFSustainableInvestment),
            'Morningstar Sustainability Rtg': textFormatter(item.SPCFSustainabilityRating),
            'Port Corp Sustainability Rtg': getRatingDescription(item.SPCFPortCorpSustRating),
            'Port Sovereign Sustainability Rtg': getRatingDescription(item.SPCFPortSovgSustRating),
            'Corp Sustainability Score (12M Avg)': formatNumber(item.SPCFFundHistAvgSustScore, {precision: 2}),
            'Sov Sustainability Score (12M Avg)': formatNumber(item.SPCFHistSovgSustScore, {precision: 2}),
            'Environmental Score': formatNumber(item.SPCFPortfolioEnvironmentalScore, {precision: 2}),
            'Governance Score': formatNumber(item.SPCFPortfolioGovernanceScore, {precision: 2}),
            'Social Score': formatNumber(item.SPCFPortfolioSocialScore, {precision: 2}),
            'Corp % Port Contrib To Sustainability Rtg': formatNumber(item.SPCFPortSustRatingCorpContibPct, {precision: 2}),
            'Sov % Port Contrib to Sustainability Rtg': formatNumber(item.SPCFPortSustRatingSovgContribPct, {precision: 2}),
            'Corp ESG Risk Score (% Corp AUM) - Neg/Med Sum': formatNumber((item.SPCFCorpESGNeg2Med), {precision: 2}),
            'Corp ESG Risk Score (% Corp AUM) - High/Sev (Sum)': formatNumber((item.SPCFCorpESGHigh2Sev), {precision: 2}),
            'Sov ESG Risk Score (% Sov AUM) - Neg/Med (Sum)': formatNumber((item.SPCFSovESGNeg2Med), {precision: 2}),
            'Sov ESG Risk Score (% Sov AUM) - High/Sev (Sum)': formatNumber((item.SPCFSovESGHigh2Sev), {precision: 2}),
            'Controversies (% AUM) - None/Mod (Sum)': formatNumber((item.SPCFPctAumMod2NoControversies), {precision: 2}),
            'Controversies (% AUM) - Sig/Sev (Sum)': formatNumber((item.SPCFPctAumSev2SigControversies), {precision: 2})
          })
          esgCarbonMetricsTableData.push({
            xid: item.SPCFWSODIssue,
            Ticker: item.SPCFOffshoreFlex === 'Yes' ? '--' : textFormatter(item.SPCFticker),
            type: _type,
            searchType: _type,
            CUSIP: item.SPCFMStarOverrideCUSIP,
            fundType: item.SPCFFundType,
            'Name': textFormatter(item.SPCFMStarOverrideFundName),
            'Carbon Risk Classification': textFormatter(item.SPCFCarbonRiskClassification),
            'Low Carbon Designation': pascalize(item.SPCFLowCarbonDesignation),
            'Carbon Risk Score - Current': formatNumber(item.SPCFCarbonRiskScore),
            'Carbon Risk Score (Current) - Cat Rank': textFormatter(item.SPCFCarbonRiskScorePercentRankinCategory),
            'Fossil Fuel Involvement (12M Avg, % AUM)': formatNumber(item.SPCFAverage12MonthFossilFuelExposure),
            'Carbon Risk % AUM - Neg/Med': formatNumber(item.SPCFPctAUMNeg2MedCarbonRisk),
            'Carbon Risk % AUM - High/Sev': formatNumber(item.SPCFPctAUMHigh2SevCarbonRisk),
            'Carbon Solutions Involvement (% AUM)': formatNumber(item.SPCFCarbonSolutionsInvolvement),
            'Carbon Solutions Cat Avg (% AUM)': formatNumber(item.SPCFCarbonSolutionsCategoryAverage),
            'Carbon Intensity (mtonne Co2/Mil Rev USD)': formatNumber(item.SPCFCarbonIntensity),
            'Carbon Intensity (Cat Avg)': formatNumber(item.SPCFCarbonIntensityCategoryAverage),
            'Percent AUM Covered Carbon': formatNumber(item.SPCFPercentAUMCoveredCarbon),
            'Scope 1 & 2 Emissions': formatNumber(item.SPCFScope1Emissions),
            'Scope 1, 2 & 3 Emissions': formatNumber(item.SPCFScope2Emissions)
          })
        })
        // setFundDataCount
        let keyMsrDataOld = offset > 0 ? keyMsrData.data : []
        setkeyMsrData({data: [...keyMsrDataOld, ...keyMeasuresTableData]})
        let operatDataOld = offset > 0 ? operatData.data : []
        setOperatData({data: [...operatDataOld, ...operationalTableData]})
        let profileDataOld = offset > 0 ? profileData.data : []
        setProfileData({data: [...profileDataOld, ...profileTableData]})
        let assetAllocationDataOld = offset > 0 ? assetAllocationData.data : []
        setAssetAllocationData({data: [...assetAllocationDataOld, ...assetAllocationTableData]})
        let incomeDataOld = offset > 0 ? incomeData.data : []
        setIncomeData({data: [...incomeDataOld, ...incomeTableData]})
        let performanceDataOld = offset > 0 ? performanceData.data : []
        setPerformanceData({data: [...performanceDataOld, ...performanceTableData]})
        let portfolioCharacterisiticsDataOld = offset > 0 ? portfolioCharacterisiticsData.data : []
        setPortfolioCharacterisiticsData({data: [...portfolioCharacterisiticsDataOld, ...portfolioCharacterisiticsTableData]})
        let ratingsDataOld = offset > 0 ? ratingsData.data : []
        setRatingsData({data: [...ratingsDataOld, ...ratingTableData]})
        let riskDataOld = offset > 0 ? riskData.data : []
        setRiskData({data: [...riskDataOld, ...riskTableData]})
        let technicalIndicatorsDataOld = offset > 0 ? technicalIndicatorsData.data : []
        setTechnicalIndicatorsData({data: [...technicalIndicatorsDataOld, ...technicalIndicatorsTableData]})
        let esgRatingDataOld = offset > 0 ? esgRatingData.data : []
        setEsgRatingData({data: [...esgRatingDataOld, ...esgRatingTableData]})
        let esgCarbonMetricsDataOld = offset > 0 ? esgCarbonMetricsData.data : []
        setEsgCarbonMetricsData({data: [...esgCarbonMetricsDataOld, ...esgCarbonMetricsTableData]})
        setFundDataCount(filteredCount)

        if (defaultSorting) {
          sortColumnDataHandler('Ticker', true, false, true)
        }
        if (responseData && responseData.pagination) {
          setFundDataCount(responseData.pagination.totalItems)
        } else setFundDataCount(0)

        if (responseData && responseData.items.length > 0) {
          if ((offset + 20) <= responseData.pagination.totalItems) {
            setTableResultCount(tableResultCount > 0 && offset > 0 ? tableResultCount + responseData.items.length : responseData.items.length)
          } else {
            setTableResultCount(responseData.pagination.totalItems)
          }
        } else setTableResultCount(0)
      }
    }

    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN + '?screenType=' + FUND_SCREEN_TYPE}`,
      buildScreenInputs(offset, false, 0, screenerArguments, sortArguments),
      screenDataCallback
    )
  }
  const buildScreenInputs = (_offset, isExportToCsv, csvLimit, screenerArguments, sortArguments, tabKey=activeTabKey) => {
    const preScreenInputs = DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS
    preScreenInputs.offset = _offset
    preScreenInputs.arguments = screenerArguments || []
    preScreenInputs.sortArguments = sortArguments || [
      {
        direction: 'A',
        field: 'SPCFticker'
      }
    ]
    preScreenInputs.limit = csvLimit
    if (!isExportToCsv) {
      preScreenInputs.limit = 20
      preScreenInputs.resultFields = FundScreenerResultColumnArr
    } else {
      switch (tabKey) {
        case 'Key Measures':
          preScreenInputs.resultFields = FundScreenerKeyMeasuresArr
          break
        case 'Operational':
          preScreenInputs.resultFields = FundScreenerOperationalArr
          break
        case 'Profile':
          preScreenInputs.resultFields = FundScreenerProfileArr
          break
        case 'Asset Allocation':
          preScreenInputs.resultFields = FundScreenerAssetAllocationArr
          break
        case 'Income':
          preScreenInputs.resultFields = FundScreenerIncomeArr
          break
        case 'Performance':
          preScreenInputs.resultFields = FundScreenerPerformanceArr
          break
        case 'Portfolio Characteristics':
          preScreenInputs.resultFields = FundScreenerPortfolioCharacteristicsArr
          break
        case 'Ratings':
          preScreenInputs.resultFields = FundScreenerRatingsArr
          break
        case 'Risk':
          preScreenInputs.resultFields = FundScreenerRiskArr
          break
        case 'Technical Indicators':
          preScreenInputs.resultFields = FundScreenerTechIndicatorArr
          break
        case 'ESG Rating':
          preScreenInputs.resultFields = FundScreenerEsgRatingArr
          break
        case 'ESG Carbon Metrics':
          preScreenInputs.resultFields = FundScreenerEsgCarbonMetricsArr
          break
        case 'All':
          preScreenInputs.resultFields = FundScreenerResultColumnArr
          break
      }
    }
    return {
      method: 'POST',
      postData: preScreenInputs
    }
  }

  function showAddToWatchlist (toggleWatchlist, xid, symbol) {
    setWatchlistObj({
      toggleAddtoWatchlist: toggleWatchlist,
      venueXid: xid,
      symbol: symbol
    })
  }

  function settingShowGlossary (ele) {
    setShowGlossary(ele)
  }

  function changeHandlerDropDown (e) {
    if (e.innerText === ScreenerOptionsConstants.SaveThisScreener) {
      ModalService.open(SaveScreenPopup)
      setModalHeader('Save Screener')
      setErrorMsg('')
      setShowGlossary(false)
    } else if (e.innerText === ScreenerOptionsConstants.NewScreener) {
      setResetScreenerFilter(CreateUUID())
      screenId.current = ''
      setScreenerName('')
      setMostPopular(mostPopularfilterOptions)
      resetFilterOptions()
      setShowGlossary(false)
    } else if (e.innerText === ScreenerOptionsConstants.SavedScreeners) {
      ModalService.open(OpenScreenerPopup)
      setModalHeader('Open Fund Screener')
      setErrorMsg('')
      setShowGlossary(false)
    } else if (e.innerText === ScreenerOptionsConstants.UpdateThisScreener) {
      setShowUpdateConfirmation(true)
      setShowGlossary(false)
    } else if (e.innerText === ScreenerOptionsConstants.EsgGlossary) {
      settingShowGlossary(true)
    }
  }

  function updateScreenerConfirmationHandler () {
    setShowUpdateConfirmation(false)
    basicDropdown.current.resetDropdown()
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        setUpdatedArguments([])
        fireToastMessage({
          message: UpdateScreenerConfirmationMessage
        })
      }
    }
    const postData = {
      id: screenId.current,
      arguments: [{screenArguments: screenerArguments,
        savedFilters: screenerFilterOptionsToBeSaved}],
      name: screenerName,
      description: screenerDescription
    }
    makeUpdateScreenerApiCall(FUND_SCREEN_ID, postData, callback)
  }

  function getScreenData () {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        savedScreenName.current = []
        response.data.forEach(element => {
          savedScreenName.current[element.id] = element.name
          if (screenId.current === element.id) {
            screenId.current = element.id
            setScreenerName(element.name)
          }
        })
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.SAVEDSCREENS}/` + FUND_SCREEN_ID,
      {},
      callback,
      null
    )
  }

  function updateScreenData (name, description) {
    basicDropdown.current.resetDropdown()
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        setUpdatedArguments([])
        fireToastMessage({
          message: ScreenerDetailsUpdatedMessage
        })
        if (screenId.current === selectedScreenerId) {
          setScreenerName(name)
        }
      }
    }
    const postData = {
      id: screenId.current,
      arguments: updatedArguments || [{screenArguments: screenerArguments,
        savedFilters: screenerFilterOptionsToBeSaved}],
      name: name,
      description: description
    }
    makeUpdateScreenerApiCall(FUND_SCREEN_ID, postData, callback)
  }

  function handleOnCheckboxChange (fundSelection) {
    setModelCompare(fundSelection)
  }

  function saveBtnHandler (isSave, name, description, isEditMode) {
    if (name.trim() === '') {
      setErrorMsg('Name cannot be empty.')
    } else if (!isSave) {
      setErrorMsg('There is already a screener with this name.')
    } else {
      ModalService.open() // Closes the opened modal
      if (isEditMode) {
        updateScreenData(name, description)
      } else {
        let savedId = CreateUUID()
        screenId.current = savedId
        basicDropdown.current.resetDropdown()
        let callback = (response) => {
          if (!response.isDataRequestComplete) return
          if (!response.isError) {
            setScreenerName(name)
            setScreenerDescription(description)
            setSelectedScreenerId(savedId)
            props.updateScreenerName(name)
            getScreenData()
            fireToastMessage({
              message: ScreenerCreatedMessage
            })
          }
        }
        DataRequest.execute(
          `${URLS.PLATFORM.SAVEDSCREENS}/` + FUND_SCREEN_ID,
          {
            method: 'POST',
            postData: {
              id: savedId,
              arguments: [{screenArguments: screenerArguments,
                savedFilters: screenerFilterOptionsToBeSaved}],
              name: name,
              description: description
            }
          },
          callback,
          null
        )
      }
    }
  }

  function editScreenData (item) {
    ModalService.open(SaveScreenPopup, {screenData: {isEditMode: true, defaultName: item.name, defaultDescription: item.description}})
    setModalHeader('Edit Screener Details')
    setUpdatedArguments(item.arguments)
    screenId.current = item.id
    setErrorMsg('')
  }

  function deleteScreenerHandler (savedScreenerId) {
    delete savedScreenName.current[savedScreenerId]
    if (savedScreenerId === selectedScreenerId) {
      resetFilterOptions()
    }
  }

  function closeHandler () {
    basicDropdown.current.resetDropdown()
  }

  function textChange () {
    setErrorMsg('')
  }

  function resetFilterOptions (disableDefault = false) {
    for (let i = 0; i < document.querySelectorAll('*[id^="pill"]').length; i++) {
      document.querySelectorAll('*[id^="pill"]')[i].childNodes[1].click()
    }
    setScreenerName('')
    setScreenerDescription('')
    setSelectedScreenerId()
    props.updateScreenerName('')
    const defaultChkBoxFVETF = document.querySelector('#chk-SPCFFVProductType-FVETF')
    const defaultChkBoxFVMF = document.querySelector('#chk-SPCFFVProductType-FundVestMutualFunds')
    const defaultChkBoxFundCenter = document.querySelector('#chk-SPCFFVProductType-SPCFTradeStatus')
    if (!disableDefault) {
      const obj = getFilterOptions()
      SetFilterOptionsFromSavedScreener(obj.defaultScreener)
      setTimeout(() => mostPopularSelectRef.current && mostPopularSelectRef.current.toggleList(null, true), 0)
      if (defaultChkBoxFVETF && !defaultChkBoxFVETF.checked) defaultChkBoxFVETF.checked = true
      if (defaultChkBoxFVMF && !defaultChkBoxFVMF.checked) defaultChkBoxFVMF.checked = true
    } else {
      if (defaultChkBoxFVETF && props.symbol === 'FundVest ETF') defaultChkBoxFVETF.checked = true
      if (defaultChkBoxFVMF && props.symbol === 'FundVest') defaultChkBoxFVMF.checked = true
      if (defaultChkBoxFundCenter && props.symbol === 'FundCenter') defaultChkBoxFundCenter.checked = true
    }
  }

  function openBtnHandler (results) {
    resetFilterOptions(true)
    let matchingId = Object.keys(savedScreenName.current).find(key => savedScreenName.current[key] === results.name)
    screenId.current = matchingId
    setScreenerName(results.name)
    setSelectedScreenerId(results.id)
    setScreenerDescription(results.description)
    props.updateScreenerName(results.name)
    setResetScreenerFilter(CreateUUID())
    let savedFilters = results.arguments[0].savedFilters
    let promiseArray = []
    // const distinctFilters = []
    // const map = new Map()
    // for (const item of savedFilters) {
    //   if (!map.has(item.rootFilterId)) {
    //     map.set(item.rootFilterId, true)
    //     distinctFilters.push(item)
    //   }
    // }
    let keysToCallAtUpper = ['SPCFFundAssetClassGroup', 'SPCFMStarOverrideFundManagerName', 'SPCFRiskProfile', 'SPCFMStarOverrideFundShareClass', 'SPCFMStarOverrideFundFamilyName']
    savedFilters.map(obj => {
      if (keysToCallAtUpper.indexOf(obj.ParentField) > -1) {
        let isDynamicDataFromPF = obj.control === 'checkbox' || false
        let field = obj.ParentField
        promiseArray.push(getDynamicValues(field, obj.rootFilterId, isDynamicDataFromPF, savedFilters))
      }
    })
    setscreenerFilterOptionsToBeSaved(savedFilters)
    SetFilterOptionsFromSavedScreener(savedFilters)
  }

  const getDynamicValues = (field, rootFilterId, isDynamicDataFromPF, savedFilters) => {
    let tempField = field
    let tempRootFilterId = rootFilterId
    return new Promise((resolve) => {
      let screenerCallback = (response) => {
        if (response.data) {
          const responseData = response.data && response.data.items
          if (responseData) {
            let minData = responseData[0].min
            let maxData = responseData[0].max
            if (tempField === 'SPCFInceptionDate') {
              let minDataTemp = minData
              let maxDataTemp = maxData
              maxData = moment().diff(minDataTemp, 'years')
              minData = moment().diff(maxDataTemp, 'years')
            }
            let updatedFilterState = [...(getStateById(tempRootFilterId))]
            updatedFilterState.forEach(parent => {
              updateFilterDynamicData(parent, updatedFilterState, minData, maxData, responseData, savedFilters)
            })
          }
          resolve(responseData)
        }
      }
      if (isDynamicDataFromPF) {
        getDistinctValuesFromPFScreener(field, FUND_SCREEN_ID, screenerCallback)
      } else {
        getDistinctValuesFromScreener(field, FUND_SCREEN_TYPE, screenerCallback)
      }
    })
  }

  const updatePills = (updatedFilters, itemlist) => {
    updatedFilters.map(item => {
      item.items.map(x => {
        if (x.val === itemlist.val && x.parentVal === itemlist.parentVal) {
          x.isSelected = itemlist.isSelected
          setPillDisplayLabel(x, itemlist)
        } else if (x.control === 'submenu') {
          updateSubMenuItems(x, itemlist)
          UpdateItemsCount(x)
        }
      })
      UpdateItemsCount(item)
    })
  }

  const updateSubMenuItems = (controls, itemlist) => {
    controls.items.map(control => {
      if (control.val === itemlist.val && control.parentVal === itemlist.parentVal) {
        control.isSelected = itemlist.isSelected
        setPillDisplayLabel(control, itemlist)
      }
    })
  }
  const setPillDisplayLabel = (ele, item) => {
    const controls = ['slider', 'rangeSelector', 'rangeSelectorCheckbox', 'radio', 'technicalIndicator']
    if (item.pillLabel) {
      if (controls.find(f => f === item.control)) {
        switch (item.control) {
          case 'technicalIndicator':
            const buttonBands = item.buttonValues && item.buttonValues.map(x => x.screenerName)
            if (buttonBands && buttonBands.length > 0) {
              const selectedButtonBand = item.buttonValues.filter(x => x.screenerName === buttonBands[0])[0]
              if (item.selectedValue[buttonBands[0]]) {
                const buttonVal = selectedButtonBand.options.filter(x => x.name === item.selectedValue[buttonBands[0]])[0].val
                ele.pillDisplayLabel = `${item.pillLabel}: ${buttonVal}`
              }
            } else {
              const sButtonBands = item.selects.filter(x => x.screenerName === 'argumentMultiplier')
              if (sButtonBands && sButtonBands.length > 0) {
                const buttonVal = sButtonBands[0].options.filter(x => x.name === item.selectedValue['argumentMultiplier'])[0].val
                ele.pillDisplayLabel = `${item.pillLabel}: ${buttonVal}`
              }
            }
            break
          case 'rangeSelectorCheckbox':
            if (Array.isArray(item.selectedValue)) {
              ele.pillDisplayLabel = item.selectedValue.map(x => `${item.pillLabel}: ${x}`)
            } else {
              ele.pillDisplayLabel = `${item.pillLabel}: ${item.val}`
            }
            break
          default:
            ele.pillDisplayLabel = `${item.pillLabel}: ${item.val}`
        }
      } else {
        ele.pillDisplayLabel = `${item.pillLabel}: ${item.label}`
      }
    } else {
      ele.pillDisplayLabel = `${item.label}`
    }
  }

  // const getPillDisplayLabel = (item) => {
  //   let controls = ['slider', 'rangeSelector']
  //   if (item.pillLabel) {
  //     if (controls.find(f => f === item.control)) {
  //       return item.val
  //     } else {
  //       return item.label
  //     }
  //   } else {
  //     return item.label
  //   }
  // }

  function getStateById (elementId) {
    switch (elementId) {
      case 'popular-select':
        return mostPopular
      case 'operational-select':
        return operation
      case 'ratings-select':
        return ratings
      case 'risk-select':
        return risk
      case 'assetAllocation-select':
        return assetAllocation
      case 'performance-select':
        return performance
      case 'basics-select':
        return basics
      case 'portfolio-characteristics-select':
        return portfolioCharacteristics
      case 'technicalIndicators-select':
        return technicalIndicators
      case 'income-select':
        return income
      case 'esgFundRating-select':
        return esgFundRating
      case 'esgCarbonMetrics-select':
        return esgCarbonMetrics
      default:
        return []
    }
  }
  function setStateById (elementId, updatedFilters) {
    switch (elementId) {
      case 'popular-select':
        setMostPopular(updatedFilters)
        break
      case 'operational-select':
        setOperation(updatedFilters)
        break
      case 'ratings-select':
        setRatings(updatedFilters)
        break
      case 'performance-select':
        setPerformance(updatedFilters)
        break
      case 'risk-select':
        setRisk(updatedFilters)
        break
      case 'assetAllocation-select':
        setAssetAllocation(updatedFilters)
        break
      case 'basics-select':
        setBasics(updatedFilters)
        break
      case 'portfolio-characteristics-select':
        setPortfolioCharacteristics(updatedFilters)
        break
      case 'technicalIndicators-select':
        setTechnicalIndicators(updatedFilters)
        break
      case 'income-select':
        setIncome(updatedFilters)
        break
      case 'esgFundRating-select':
        setESGFundRating(updatedFilters)
        break
      case 'esgCarbonMetrics-select':
        setESGCarbonMetrics(updatedFilters)
        break
      default:
        setMostPopular(updatedFilters)
        break
    }
  }

  function addPill (item, parent, parentNode, rootFilterId) {
    if (parent) {
      setTableResultCount(0)
      let updatedFilterState = [...(getStateById(rootFilterId))]
      updatePills(updatedFilterState, item)
      if (parentNode) updateTopFilterCount(parentNode, rootFilterId)
      setStateById(rootFilterId, updatedFilterState)
      updateScreenerArgument(item, parent)
      // let screenerArguments = updateScreenerArgument(item, parent)
      // getTopScreenResults(screenerArguments)
      // getTableData(0, screenerArguments)
      debounce(updateTable, 1000)
      fillSaveScreenerFilterObject(updatedFilterState, item)
    }
  }

  function updateTable () {
    getTopScreenResults(screenerArguments)
    getTableData(0, screenerArguments, [
      {
        direction: 'A',
        field: 'SPCFticker'
      }
    ], true)
    setModelCompare([])
    setShowMoreClickCount(0)
  }

  const stringFormat = (templateString, args) => {
    let s = templateString
    for (const [key, value] of Object.entries(args)) {
      s = s.replace(new RegExp('\\{' + key + '\\}', 'gm'), value)
    }
    return s
  }

  /**
 * Updating Screener Arguments
 */
  function updateScreenerArgument (itemlist, selectedParent) {
    let controls = ['slider', 'rangeSelector', 'rangeSelectorCheckbox']
    let screenerColumnName = getScreenerCoumnName(itemlist, selectedParent)
    if (controls.find(f => f === itemlist.control)) {
      if (itemlist.control === 'rangeSelectorCheckbox') {
        // Clear existing item from Screener argument for rangeSelectorCheckbox
        for (let index = 0; index < screenerArguments.length; index++) {
          let screenArgument = screenerArguments[index]
          if (screenArgument.arguments && screenArgument.arguments.length > 0) {
            screenArgument.arguments = screenArgument.arguments.filter(x => !(x.field === screenerColumnName || x.field === itemlist.rangeSelectorVal))
            if (screenArgument.arguments.length === 0) {
              screenerArguments.splice(index, 1)
            }
          }
        }
        if (itemlist.selectedValue) {
          itemlist.selectedValue.map(x => {
            let value = {selectedVal: x}
            let { calcMin, calcMax } = ScreenerHelper.getMinMaxValues(value)
            ScreenerHelper.buildControlArgument(screenerColumnName, calcMin, calcMax, itemlist.isSelected, screenerArguments, itemlist)
          })
        } else {
          let value = {selectedVal: itemlist.val}
          let { calcMin, calcMax } = ScreenerHelper.getMinMaxValues(value)
          ScreenerHelper.buildControlArgument(screenerColumnName, calcMin, calcMax, itemlist.isSelected, screenerArguments, itemlist)
        }
      } else if (itemlist.selectedValue === '' || itemlist.control === 'slider') {
        ScreenerHelper.buildControlArgument(screenerColumnName, itemlist.min, itemlist.max, itemlist.isSelected, screenerArguments, itemlist)
      } else {
        let value = {selectedVal: itemlist.selectedValue}
        let { calcMin, calcMax } = ScreenerHelper.getMinMaxValues(value)
        ScreenerHelper.buildControlArgument(screenerColumnName, calcMin, calcMax, itemlist.isSelected, screenerArguments, itemlist)
      }
    } else {
      if (selectedParent.control === 'submenu') {
        ScreenerHelper.buildScreenArguments(screenerColumnName, itemlist.val, itemlist, screenerArguments)
      } else if (itemlist.control === 'technicalIndicator') {
        screenerColumnName = stringFormat(screenerColumnName, itemlist.selectedValue)
        ScreenerHelper.buildScreenArguments(screenerColumnName, itemlist.val, itemlist, screenerArguments)
      } else {
        ScreenerHelper.buildScreenArguments(screenerColumnName, itemlist.val, itemlist, screenerArguments)
      }
    }
    setScreenerArguments(screenerArguments)
    return screenerArguments
  }
  /**
 * Get Element by it's ID
 */
  function getElementById (item, elRemove) {
    switch (item.control) {
      case 'rangeSelectorCheckbox':
        return `chk-${item.parentVal}-${elRemove}`
      case 'checkbox' :
        return `chk-${item.parentVal}-${item.val}`
      case 'rangeSelector' :
        return `rdo-${item.parentVal}-${item.val}`
    }
  }
  /**
 *Removing pill
 */
  function hide (res, item, parent, parentNode) {
    const elRemove = res.indexOf('pill-rs') > -1 && res.split(':')[1].trim()
    const htmlElementId = getElementById(item, elRemove)
    const rootFilterId = item.rootFilterId ? item.rootFilterId : parent && parent.rootFilterId
    if (document.getElementById(htmlElementId) && (item.control === 'checkbox')) { // Remove the if block and update state of currently displayed controls
      document.getElementById(htmlElementId).checked = false
      item.isSelected = false
      let element = document.getElementById(htmlElementId)
      let selectedRef = selectSubItem(parent)
      selectedRef.selectSubItem(element, item, parent, 'yes')
      UpdateItemsCount(parent)
      updateTopFilterCount(parentNode, parent.rootFilterId)
    } else if (item.control === 'rangeSelectorCheckbox' && Array.isArray(item.selectedValue)) {
      let element = document.getElementById(htmlElementId)
      if (element) {
        document.getElementById(htmlElementId).checked = false
        let selectedRef = selectSubItem(parent)
        selectedRef.selectSubItem(element, item, parent, 'yes')
      }
      item.val = elRemove
      item.selectedValue = item.selectedValue.filter(x => x !== elRemove)
      item.isSelected = item.selectedValue.length > 0
      updateRootFilter(rootFilterId, item, parentNode, parent)
      UpdateItemsCount(parent)
      updateTopFilterCount(parentNode, parent.rootFilterId)
    } else {
      item.isSelected = false
      // L2/L3 count depending on the levels of filters
      UpdateItemsCount(parent)

      if (item.control === 'slider' || item.control === 'rangeSelector' || item.control === 'rangeSelectorCheckbox' || item.control === 'radio') {
        item.selectedValue = ''
        updateRootFilter(rootFilterId, item, parentNode, parent)
      } else if (item.control === 'technicalIndicator') {
        updateFilterInState(item, parent, parentNode, technicalIndicatorsRaw, technicalIndicators, rootFilterId)
      } else { addPill(item, parent, parentNode, rootFilterId) }
    }
  }

  function updateRootFilter (rootFilterId, item, parentNode, parent) {
    switch (rootFilterId) {
      case 'risk-select':
        updateFilterInState(item, parent, parentNode, riskfilterOptions, risk, rootFilterId)
        break
      case 'basics-select':
        updateFilterInState(item, parent, parentNode, basicsfilterOptions, basics, rootFilterId)
        break
      case 'technicalIndicators-select':
        updateFilterInState(item, parent, parentNode, technicalIndicatorsFilterOptions, technicalIndicators, rootFilterId)
        break
      case 'performance-select':
        updateFilterInState(item, parent, parentNode, performanceFilterOptions, performance, rootFilterId)
        break
      case 'portfolio-characteristics-select':
        updateFilterInState(item, parent, parentNode, portfolioCharacteristicsFilterOptions, portfolioCharacteristics, rootFilterId)
        break
      case 'popular-select':
        updateFilterInState(item, parent, parentNode, mostPopularfilterOptions, mostPopular, rootFilterId)
        break
      case 'operational-select':
        updateFilterInState(item, parent, parentNode, operationalFilterOptions, operation, rootFilterId)
        break
      case 'assetAllocation-select':
        updateFilterInState(item, parent, parentNode, assetAllocationfilterOptions, assetAllocation, rootFilterId)
        break
      case 'income-select':
        updateFilterInState(item, parent, parentNode, incomeFilterOptions, income, rootFilterId)
        break
      case 'esgFundRating-select':
        updateFilterInState(item, parent, parentNode, esgFundRatingFilterOptions, esgFundRating, rootFilterId)
        break
      case 'esgCarbonMetrics-select':
        updateFilterInState(item, parent, parentNode, esgCarbonMetricsFilterOptions, esgCarbonMetrics, rootFilterId)
        break
    }
  }

  function updateFilterInState (item, parent, parentNode, filterOptions, stateObject, rootFilterId) {
    let fromFilter, toFilter
    if (parent.control === 'submenu') {
      fromFilter = filterOptions.filter(x => x.val === parent.parentVal)[0].items
      toFilter = stateObject.filter(x => x.val === parent.parentVal)[0]
      fromFilter = fromFilter.find(x => x.val === parent.val).items
      toFilter = toFilter.items.find(x => x.val === parent.val)
      toFilter.items = JSON.parse(JSON.stringify(fromFilter))
      if (item.control === 'rangeSelectorCheckbox') {
        updateRangeSelectorCheckbox(toFilter, item)
      }
    } else if (item.control === 'technicalIndicator') {
      fromFilter = technicalIndicatorsRaw.filter(x => x.label === parent.label)[0].items
      toFilter = stateObject.filter(x => x.label === parent.label)[0]
      fromFilter = fromFilter.find(x => x.parentVal === parent.val).selectedValue
      toFilter = toFilter.items.find(x => x.parentVal === parent.val)
      toFilter.selectedValue = JSON.parse(JSON.stringify(fromFilter)) // Added stringify and parse to remove the object reference
    } else {
      fromFilter = filterOptions.filter(x => x.val === parent.val)[0].items
      toFilter = stateObject.filter(x => x.val === parent.val)[0]
      toFilter.items = JSON.parse(JSON.stringify(fromFilter))
      if (item.control === 'rangeSelectorCheckbox') {
        updateRangeSelectorCheckbox(toFilter, item)
      }
    }
    addPill(item, parent, parentNode, rootFilterId)
  }

  // Added hack to change state when reset button is clicked
  function updateRangeSelectorCheckbox (filter, item) {
    filter.items[0].selectedValue = item.selectedValue
    filter.items[0].isSelected = item.isSelected
  }

  function selectSubItem (parent) {
    switch (parent.rootFilterId) {
      case 'operational-select':
        return operatonSelectRef.current
      case 'popular-select':
        return mostPopularSelectRef.current
      case 'technicalIndicators-select':
        return technicalIndicatorsSelectRef.current
      case 'assetAllocation-select':
        return assetAllocationSelectRef.current
      case 'performance-select':
        return performanceSelectRef.current
      case 'ratings-select':
        return ratingsSelectRef.current
      case 'risk-select':
        return riskSelectRef.current
      case 'basics-select':
        return basicSelectRef.current
      case 'portfolio-characteristics-select':
        return portfolioCharacteristicsSelectRef.current
      case 'income-select':
        return incomeSelectRef.current
      case 'esgFundRating-select':
        return esgFundRatingSelectRef.current
      case 'esgCarbonMetrics-select':
        return esgCarbonMetricsSelectRef.current

      default:
        return basicSelectRef.current
    }
  }

  function UpdateItemsCount (parent) {
    let categoryCount = (parent.items[0].control === 'rangeSelectorCheckbox' && Array.isArray(parent.items[0].selectedValue)) ? parent.items[0].selectedValue.length : parent.items.filter(x => x.isSelected).length
    updateElementFilterCount(parent, categoryCount)
    parent.selectedItemCount = categoryCount

    let l3FiltersSelected = 0
    let isSubMenu = false
    // eslint-disable-next-line no-unused-vars
    for (let [index2, subMenu] of parent.items.filter(x => x.control === 'submenu').entries()) {
      let categoryCount = (subMenu.items[0].control === 'rangeSelectorCheckbox' && Array.isArray(subMenu.items[0].selectedValue)) ? subMenu.items[0].selectedValue.length : subMenu.items.filter(x => x.isSelected).length // subMenu.items.filter(x => x.isSelected).length
      subMenu.selectedItemCount = categoryCount
      l3FiltersSelected = l3FiltersSelected + categoryCount
      updateElementFilterCount(subMenu, categoryCount)
      isSubMenu = true
    }

    if (isSubMenu) {
      updateElementFilterCount(parent, l3FiltersSelected)
      parent.selectedItemCount = l3FiltersSelected
    }
  }

  function updateElementFilterCount (parent, selectedItemCount) {
    if (parent.pillLabel) {
      parent.pillDisplayLabel = `${parent.pillLabel}`
    } else {
      parent.pillDisplayLabel = `${parent.label}`
    }
    if (document.getElementById(`li-${parent.parentVal}-${parent.val}`) && deviceTypeItem === deviceType.Desktop) {
      document.getElementById(`li-${parent.parentVal}-${parent.val}`).childNodes[0].innerHTML = handleAsterisk(parent.pillDisplayLabel) // PRW-1024 & PRW-974
      if (selectedItemCount > 0) {
        document.getElementById(`li-${parent.parentVal}-${parent.val}`).childNodes[0].innerHTML = `${handleAsterisk(parent.pillDisplayLabel)} (${selectedItemCount})` // PRW-1024 & PRW-974
      }
    }
  }

  function updateTopFilterCount (parentNode, elementId) {
    let updateParentNode = updateParentNodeForTopFilterCount(parentNode, elementId)
    let newFilterNames = [...filterDisplayName]
    let selectedItemCount = updateParentNode.map(item => item.selectedItemCount).reduce((prev, next) => prev + next)
    let element = newFilterNames.find(f => f.val === elementId)

    if (element && parent) {
      if (!element.val) element.val = parent.parentVal
      if (document.getElementById(element.val)) {
        document.getElementById(element.val).innerHTML = element.displayLabel
        if (selectedItemCount > 0) {
          newFilterNames.displayLabel = `${element.label} (${selectedItemCount})`
          setFilterDisplayName(newFilterNames)
          document.getElementById(element.val).innerHTML = `${element.displayLabel} (${selectedItemCount})`
        }
      }
    }
  }

  function updateParentNodeForTopFilterCount (parentNode, rootFilterId) {
    if (parentNode.filter(x => x.level === 'L1').length <= 1) {
      return [...(getStateById(rootFilterId))]
    } else {
      return parentNode
    }
  }
  const [fundDataCount, setFundDataCount] = useState(0)
  const [tableResultCount, setTableResultCount] = useState(0)
  const syncFixedColumnsKeyMeasures = React.useRef(null)
  const syncFixedColumnsOperational = React.useRef(null)
  const syncFixedColumnsProfile = React.useRef(null)
  const syncFixedColumnsAssetAllocation = React.useRef(null)
  const syncFixedColumnsIncome = React.useRef(null)
  const syncFixedColumnsPerformance = React.useRef(null)
  const syncFixedColumnsPortfolioCharacterisitics = React.useRef(null)
  const syncFixedColumnsRatings = React.useRef(null)
  const syncFixedColumnsRisk = React.useRef(null)
  const syncFixedColumnsTechnicalIndicators = React.useRef(null)
  const syncFixedColumnsEsgRating = React.useRef(null)
  const syncFixedColumnsEsgCarbonMetrics = React.useRef(null)
  function syncActiveTabTableOnly (e) {
    switch (e) {
      case 'Key Measures':
        syncActiveTab(syncFixedColumnsKeyMeasures)
        break
      case 'Operational':
        syncActiveTab(syncFixedColumnsOperational)
        break
      case 'Profile':
        syncActiveTab(syncFixedColumnsProfile)
        break
      case 'Asset Allocation':
        syncActiveTab(syncFixedColumnsAssetAllocation)
        break
      case 'Income':
        syncActiveTab(syncFixedColumnsIncome)
        break
      case 'Performance':
        syncActiveTab(syncFixedColumnsPerformance)
        break
      case 'Portfolio Characteristics':
        syncActiveTab(syncFixedColumnsPortfolioCharacterisitics)
        break
      case 'Ratings':
        syncActiveTab(syncFixedColumnsRatings)
        break
      case 'Risk':
        syncActiveTab(syncFixedColumnsRisk)
        break
      case 'Technical Indicators':
        syncActiveTab(syncFixedColumnsTechnicalIndicators)
        break
      case 'ESG Rating':
        syncActiveTab(syncFixedColumnsEsgRating)
        break
      case 'ESG Carbon Metrics':
        syncActiveTab(syncFixedColumnsEsgCarbonMetrics)
        break
    }
  }

  function syncActiveTab (synctab) {
    synctab.current && typeof synctab.current() === 'function' && synctab.current()
  }

  function changeHandlerTab (e) {
    setActiveTabKey(e)
  }

  function showMoreClickHandler () {
    setShowMoreClickCount(showMoreClickCount + 1)
    const _offset = (20 * (showMoreClickCount + 1))
    getTableData(_offset, screenerArguments, sortArgument && sortArgument.current)
  }

  // defaultSorting = true if getTableData will be called from changing filters
  function sortColumnDataHandler (_columnName, _sortDirection, comingFromChild, defaultSorting = false) {
    if (comingFromChild) {
      _sortDirection = _sortDirection === undefined ? false : _sortDirection
      let sortObj = {
        direction: _sortDirection ? 'D' : 'A',
        field: FundScreenerTableFieldDictionary[_columnName]
      }
      getTableData(0, screenerArguments, [sortObj])
      setModelCompare([])
      setShowMoreClickCount(0)
      sortArgument.current = [sortObj]
    }

    let allTabHeaders = [{tab: 'Key Measures', key: keyMeasuresHeaders},
      {tab: 'Operational', key: operationalHeaders},
      {tab: 'Profile', key: profileHeaders},
      {tab: 'Asset Allocation', key: assetAllocationHeaders},
      {tab: 'Income', key: incomeHeaders},
      {tab: 'Performance', key: performanceHeaders},
      {tab: 'Portfolio Characteristics', key: portfolioCharacteristicsHeaders},
      {tab: 'Ratings', key: ratingsHeaders},
      {tab: 'Risk', key: riskHeaders},
      {tab: 'Technical Indicators', key: technicalIndicatorsHeaders},
      {tab: 'ESG Rating', key: esgRatingHeaders},
      {tab: 'ESG Carbon Metrics', key: esgCarbonMetricsHeaders}
    ]

    if (comingFromChild || defaultSorting) {
      allTabHeaders.forEach(obj => {
        obj.key.map(function (item) {
          delete item.SortDirection
          return item
        })
        obj.key.forEach(item => {
          if (item.name === _columnName) {
            item.SortDirection = (activeTabKey === obj.tab) ? _sortDirection : !_sortDirection
          }
        })
      })
    }

    setKeyMeasuresHeaders(allTabHeaders[0].key)
    setKeyHeadersChangeId(Math.random())

    setOperationalHeaders(allTabHeaders[1].key)
    setOpHeadersChangeId(Math.random())

    setProfileHeaders(allTabHeaders[2].key)
    setProfileHeadersChangeId(Math.random())

    setAssetAllocationHeaders(allTabHeaders[3].key)
    setAssetHeadersChangeId(Math.random())

    setIncomeHeaders(allTabHeaders[4].key)
    setIncomeHeadersChangeId(Math.random())

    setPerformanceHeaders(allTabHeaders[5].key)
    setPerformanceHeadersChangeId(Math.random())

    setPortfolioCharacteristicsHeaders(allTabHeaders[6].key)
    setPortCharHeadersChangeId(Math.random())

    setRatingsHeaders(allTabHeaders[7].key)
    setRatingsHeadersChangeId(Math.random())

    setRiskHeaders(allTabHeaders[8].key)
    setRiskHeadersChangeId(Math.random())

    setTechnicalIndicatorsHeaders(allTabHeaders[9].key)
    setTechnicalIndicatorsHeaderChangeId(Math.random())

    setEsgRatingHeaders(allTabHeaders[10].key)
    setEsgRatingHeadersChangeId(Math.random())

    setESGCarbonMetricsHeaders(allTabHeaders[11].key)
    setESGCarbonMetricsHeadersChangeId(Math.random())
  }
  useEffect(() => {
    // For handling shadow on L0 filters and when L0 filters are scrolled
    handleScrollingfilters()

    // L1/L2/L3 filters should be left aligned, means not changed their position
    if (document.querySelector('#screenerFiltersContainer')) {
      if (document.querySelector('#screenerFiltersContainer').scrollWidth > document.querySelector('#screenerFiltersContainer').clientWidth) {
        document.getElementById('screenerFiltersContainer').addEventListener('scroll', handleScrollingfilters)
        return () => document.querySelector('#screenerFiltersContainer') && document.getElementById('screenerFiltersContainer').removeEventListener('scroll', handleScrollingfilters)
      }
    }
  })
  function handleScrollingfilters (e) {
    var filterstargetElement = document.querySelector('#screenerFiltersContainer')
    var filtersLeftShadow = document.getElementById('filtersLeftShadow')
    var filtersRightShadow = document.getElementById('filtersRightShadow')

    if (filterstargetElement) {
      var filtersscrollLeft = filterstargetElement.scrollLeft
      // for left side box-shadow
      if (filtersscrollLeft !== 0) {
        filtersLeftShadow.style.background = 'linear-gradient(90deg, #949494 -541.2%, rgba(25, 25, 25, 0.148231) -37.87%, rgba(0, 0, 0, 0.0001) 28.43%)'
        // filtersLeftShadow.style.borderLeft = '1px solid #B2B2B2' //commented for PRW-958 and PRW-959
        filtersLeftShadow.style.marginLeft = filtersscrollLeft + 'px'
        filtersLeftShadow.style.width = '40px'
      } else {
        filtersLeftShadow.style.boxShadow = 'none'
        filtersLeftShadow.style.borderLeft = '0px'
        filtersLeftShadow.style.width = '0px'
      }
      // for right side box-shadow
      if (!isElementInViewport(document.getElementsByName('esgCarbonMetrics-select')[0])) {
        // Right shadow was not coming properly in Ipad view
        const screenWidth = document.documentElement.clientWidth
        if ((screenWidth >= 596) && (screenWidth <= 1022)) {
          filtersscrollLeft -= 5
        }
        filtersRightShadow.style.background = 'linear-gradient(270deg, #949494 -541.2%, rgba(25, 25, 25, 0.35) -37.87%, rgba(0, 0, 0, 0.0001) 28.43%)'
        filtersRightShadow.style.width = '40px'
        filtersRightShadow.style.right = (filtersscrollLeft * -1) + 'px'
      } else {
        filtersRightShadow.style.boxShadow = 'none'
        filtersRightShadow.style.borderRight = '0px'
        filtersRightShadow.style.width = '0px'
      }

      updateMarginLeftForScreenerFilters()
    }
  }

  const updateFilterDynamicData = (item, selectedItemParents, min, max, responseData, savedFilters) => {
    if (!item.isDynamicData) return
    if (savedFilters) {
      const isExists = savedFilters.some(x => (x.ParentField === item.parentVal) || x.ParentField === item.val)
      if (!isExists) return
    }
    let updatedFilters = getfilterState(item.val, item.rootFilterId)
    let filteredItemList = updatedFilters.find(f => f.parentVal === item.val || f.val === item.val)
    let itemToBeUpdated = null
    if (item.level === 'L2') {
      let tempFilteredItem = updatedFilters.find(f => f.items.find(itm => itm.val === item.val || itm.parentVal === item.val))
      let filteredItem = tempFilteredItem.items.find(itm => itm.val === item.val || itm.parentVal === item.val)
      itemToBeUpdated = filteredItem && filteredItem.items.find(f => true)
    } else {
      if (filteredItemList && filteredItemList.items && filteredItemList.items.length > 0) {
        itemToBeUpdated = filteredItemList.items.find(itm => itm.val === item.val || itm.parentVal === item.val)
      }
    }
    if (filteredItemList && filteredItemList.control === 'multi-select') {
      let filteredItem = filteredItemList
      if (filteredItem) {
        filteredItem.items = []
        responseData.map(obj => {
          let isSelected = false
          let pillDisplayLabel = ''
          let savedItemstoMatch = screenerFilterOptionsToBeSaved
          if (savedFilters && (screenerFilterOptionsToBeSaved.length === 0 || (screenerFilterOptionsToBeSaved.length !== savedFilters.length))) {
            savedItemstoMatch = savedFilters
          }
          savedItemstoMatch.forEach(element => {
            if (element.selectedLabel === obj) {
              isSelected = true
              element['selectedItemCount'] = 1
              pillDisplayLabel = element.selectedLabel
            }
          })
          let itemToPush = {
            'val': obj,
            'label': obj,
            'pillLabel': filteredItem.pillLabel,
            'parentVal': filteredItem.parentVal,
            'rootFilterId': filteredItem.rootFilterId,
            'control': 'checkbox',
            'level': 'L2',
            'isSelected': isSelected,
            'pillDisplayLabel': filteredItem.pillLabel + ': ' + pillDisplayLabel,
            'items': [
            ]
          }
          filteredItem.items.push(itemToPush)
          if (isSelected) {
            let parentNode = updatedFilters.filter(x => x.val === item.val)
            addPill(itemToPush, filteredItem, parentNode, item.rootFilterId)
          }
        })
      }
    } else if (itemToBeUpdated) {
      if (!(itemToBeUpdated.min || itemToBeUpdated.max)) {
        itemToBeUpdated.min = min
        itemToBeUpdated.max = max
      }
      itemToBeUpdated.highest = max
      itemToBeUpdated.lowest = min
    }
    updatefilterState(updatedFilters, item.val, item.rootFilterId)
  }

  const getfilterState = (filter, rootFilterId) => {
    switch (rootFilterId) {
      case 'popular-select':
        if (mostPopularDynamicFilterList.find(f => f === filter)) return [...mostPopular]
        break
      case 'basics-select':
        if (basicsDynamicFilterList.find(f => f === filter)) return [...basics]
        break
      case 'performance-select':
        if (performanceDynamicFilterList.find(f => f === filter)) return [...performance]
        break
      case 'portfolio-characteristics-select':
        if (portfolioCharacteristicsDynamicFilterList.find(f => f === filter)) return [...portfolioCharacteristics]
        break
      case 'operational-select':
        if (operationalDynamicFilterList.find(f => f === filter)) return [...operation]
        break
      case 'assetAllocation-select':
        if (asssetAllocationDynamicFilterList.find(f => f === filter)) return [...assetAllocation]
        break
      case 'ratings-select':
        if (ratingsDynamicFilterList.find(f => f === filter)) return [...ratings]
        break
      case 'risk-select':
        if (riskDynamicFilterList.find(f => f === filter)) return [...risk]
        break
      case 'technicalIndicators-select':
        if (technicalIndicatorsDynamicFilterList.find(f => f === filter)) return [...technicalIndicators]
        break
      case 'income-select':
        if (incomeDynamicFilterList.find(f => f === filter)) return [...income]
        break
      case 'esgFundRating-select':
        if (esgFundRatingDynamicFilterList.find(f => f === filter)) return [...esgFundRating]
        break
      case 'esgCarbonMetrics-select':
        if (esgCarbonMetricsDynamicFilterList.find(f => f === filter)) return [...esgCarbonMetrics]
        break
    }
  }

  const updatefilterState = (updatedFilter, filter, rootFilterId) => {
    switch (rootFilterId) {
      case 'popular-select':
        if (mostPopularDynamicFilterList.find(f => f === filter)) setMostPopular(updatedFilter)
        break
      case 'basics-select':
        if (basicsDynamicFilterList.find(f => f === filter)) return [...basics]
        break
      case 'performance-select':
        if (performanceDynamicFilterList.find(f => f === filter)) setPerformance(updatedFilter)
        break
      case 'portfolio-characteristics-select':
        if (portfolioCharacteristicsDynamicFilterList.find(f => f === filter)) setPortfolioCharacteristics(updatedFilter)
        break
      case 'operational-select':
        if (operationalDynamicFilterList.find(f => f === filter)) setOperation(updatedFilter)
        break
      case 'assetAllocation-select':
        if (asssetAllocationDynamicFilterList.find(f => f === filter)) setAssetAllocation(updatedFilter)
        break
      case 'ratings-select':
        if (ratingsDynamicFilterList.find(f => f === filter)) setRatings(updatedFilter)
        break
      case 'risk-select':
        if (riskDynamicFilterList.find(f => f === filter)) setRisk(updatedFilter)
        break
      case 'technicalIndicators-select':
        if (technicalIndicatorsDynamicFilterList.find(f => f === filter)) setTechnicalIndicators(updatedFilter)
        break
      case 'income-select':
        if (incomeDynamicFilterList.find(f => f === filter)) setIncome(updatedFilter)
        break
      case 'esgFundRating-select':
        if (esgFundRatingDynamicFilterList.find(f => f === filter)) setESGFundRating(updatedFilter)
        break
      case 'esgCarbonMetrics-select':
        if (esgCarbonMetricsDynamicFilterList.find(f => f === filter)) setESGCarbonMetrics(updatedFilter)
        break
    }
  }
  const createPill = (fundScreenerfilters, filterCriteria) => (fundScreenerfilters.map((item, index) => createPillElement(item, fundScreenerfilters, fundScreenerfilters.title)))

  const createPillElement = (item, parentNode, title) => {
    return (
      item.items.filter(itm => itm.isSelected === true).map((subItem, index) => {
        if (subItem.control === 'rangeSelectorCheckbox' && Array.isArray(subItem.pillDisplayLabel)) {
          return (
            subItem.pillDisplayLabel.map(x =>
              <span name='AllModels' key={`pill-${x}`} id={`pill-rs-${x}`} className={styles.buttonfilter} aria-label={x}>
                {x}
                <button tabIndex='0' aria-label='Remove' className={`${styles.close}`} onClick={(e) => hide(`pill-rs-${x}`, subItem, item, parentNode, title)} type='button'>
                  <span aria-hidden='true'>×</span></button>
              </span>
            )
          )
        } else {
          return (
            <span name='AllModels' key={`pill-${subItem.val}`} id={`pill-${subItem.val}`} className={styles.buttonfilter} aria-label={subItem.pillDisplayLabel ? subItem.pillDisplayLabel : subItem.val}>
              {subItem.pillDisplayLabel ? `${subItem.pillDisplayLabel}` : `${subItem.val}`}
              <button tabIndex='0' aria-label='Remove' className={`${styles.close}`} onClick={(e) => hide(`pill-${subItem.val}`, subItem, item, parentNode, title)} type='button'>
                <span aria-hidden='true'>×</span></button>
            </span>
          )
        }
      }))
  }
  let debounce = (method, delay) => {
    clearTimeout(method._tId)
    method._tId = setTimeout(function () {
      method()
    }, delay)
  }
  function getPill (pillState, level) {
    if (level === 'L2') {
      return pillState && createPill(pillState)
    } else {
      return pillState && pillState.map((item, index) => (createPill(item.items.filter(itm => itm.control === 'submenu'))))
    }
  }

  function setFilterSaveObject (child, parent) {
    let tmpObj = {
      rootFilterId: child.rootFilterId ? child.rootFilterId : parent.rootFilterId,
      ParentField: getScreenerCoumnName(child, parent), // Getting column name
      Level: child.level,
      selectedLabel: child.label,
      selectedVal: child.control === 'technicalIndicator' ? child.selectedValue : child.val,
      control: child.control,
      isDynamicData: parent.isDynamicData,
      selectedValue: child.selectedValue
    }
    if (child.control === 'rangeSelector') {
      tmpObj['selectorType'] = (child.selectedValue.length > 0) ? 'Manual' : 'Range'
    }
    return tmpObj
  }

  function getScreenerCoumnName (child, parent) {
    if ((child.control === 'slider' || child.control === 'rangeSelector' || child.control === 'rangeSelectorCheckbox' || child.control === 'technicalIndicator') && child.parentVal.indexOf('SPCF') > -1) {
      return child.parentVal
    } else if (parent.val.indexOf('SPCF') === -1) {
      return parent.parentVal
    }
    return parent.val
  }

  function fillSaveScreenerFilterObject (filterObj, itemList) {
    filterObj.map(parent => {
      parent.items.map(child => {
        if (child.control === 'submenu') {
          child.items.map(subChildItem => {
            if (subChildItem.val === itemList.val && subChildItem.parentVal === itemList.parentVal) {
              saveScreenerFilterItem(child, subChildItem)
            }
          })
} else if (child.control === "rangeSelectorCheckbox") {
          if (child.arrayList.includes(itemList.val) && child.parentVal === itemList.parentVal) {
            saveScreenerFilterItem(parent, child)
          }
        } else if (child.val === itemList.val && child.parentVal === itemList.parentVal) {
          saveScreenerFilterItem(parent, child)
        }
      })
    })
    setscreenerFilterOptionsToBeSaved(screenerFilterOptionsToBeSaved)
  }

  function saveScreenerFilterItem (parent, child) {
    const screenerColumnName = getScreenerCoumnName(child, parent)
    let index = screenerFilterOptionsToBeSaved.findIndex(e1 => e1['ParentField'] === screenerColumnName)
    const keyExistsMoreThanOnce = screenerFilterOptionsToBeSaved.filter(e1 => e1['ParentField'] === screenerColumnName)?.length > 1
    // If item is deselected, remove it from screenerFilterOptionsToBeSaved, else process
    if (!child.isSelected) {
      if (keyExistsMoreThanOnce) {
        // Eg: for checkbox controlfields, we had multiple items in screenerFilterOptionsToBeSaved[] with same ParentField, so filter one step further with selectedVal
        index = screenerFilterOptionsToBeSaved.findIndex(e1 => e1['ParentField'] === screenerColumnName && e1.selectedVal === child.val)
      }
      index !== -1 && screenerFilterOptionsToBeSaved.splice(index, 1)
    } else {
      if (index === -1) {
        // push
        screenerFilterOptionsToBeSaved.push(setFilterSaveObject(child, parent))
      } else {
        // update
        if (child.control === 'rangeSelector' ||
          child.control === 'slider' ||
          child.control === 'rangeSelectorCheckbox' ||
          child.control === 'radio') {
          screenerFilterOptionsToBeSaved[index]['selectedVal'] = child.val
          screenerFilterOptionsToBeSaved[index]['selectedValue'] = child.selectedValue
        } else {
          if (child.control === 'checkbox') {
            if (!screenerFilterOptionsToBeSaved.some(x => x.ParentField === screenerColumnName && x.selectedVal === child.val)) {
              screenerFilterOptionsToBeSaved.push(setFilterSaveObject(child, parent))
            }
          } else {
            screenerFilterOptionsToBeSaved.push(setFilterSaveObject(child, parent))
          }
        }
      }
        }
  }

  function setSingleFilterOptionFromSavedScreener (level, filterObj, savedObj) {
    let parentNode = filterObj
    if (level === 'L2') {
      filterObj.map(parent => {
        parent.items.map(item => {
          loadingSavedLevelFilters(item, parent, savedObj, parentNode, item.parentVal)
        })
      })
    } else if (level === 'L3') {
      filterObj.map(parent => {
        if (parent.items) {
          parent.items.map(item => {
            if (item.items) {
              item.items.map(subChild => {
                loadingSavedLevelFilters(subChild, item, savedObj, parentNode, item.parentVal)
              })
            }
          })
        }
      })
    }
  }

  function loadingSavedLevelFilters (child, parent, savedObj, parentNode, parentField) {
    if (child.control === 'rangeSelector' || child.control === 'slider' || child.control === 'rangeSelectorCheckbox') {
      if ((child.parentVal === savedObj.ParentField || parentField === savedObj.ParentField) && child.label === savedObj.selectedLabel) {
        child.val = savedObj.selectedVal
        child.selectedValue = savedObj.selectorType === 'Manual' ? savedObj.selectedVal : ''
        if (child.control === 'rangeSelectorCheckbox') {
          child.selectedValue = savedObj.selectedValue
        }
        child.isSelected = true
        if (child.control === 'slider') {
          let { calcMin, calcMax } = ScreenerHelper.getMinMaxValues(savedObj)
          child.min = parseInt(calcMin)
          child.max = parseInt(calcMax)
        }
        addPill(child, parent, parentNode, child.rootFilterId)
      }
    } else if (child.control === 'checkbox' || child.control === 'radio' || child.control === 'technicalIndicator') {
      if ((child.parentVal === savedObj.ParentField || parentField === savedObj.ParentField) && child.label === savedObj.selectedLabel) {
        if (child.label === savedObj.selectedLabel) {
          child.isSelected = true
          // item.pillDisplayLabel = item.pillLabel + ': ' + savedObj.selectedVal
          if (child.control === 'radio') {
            child.selectedValue = savedObj.selectedVal
            child.val = savedObj.selectedVal
          } else if (child.control === 'technicalIndicator') {
            child.selectedValue = savedObj.selectedVal
          }
          addPill(child, parent, parentNode, child.rootFilterId || parent.rootFilterId)
        }
      }
    }
  }

  function SetFilterOptionsFromSavedScreener (savedFilters) {
    let tmp = JSON.parse(JSON.stringify(savedFilters)) // TODO this would come from saved screener and will be used to set filter options

    // Initially take all filters options with initial values
    let tmpMostPopularfilterOptions = mostPopular
    let tmpTechnicalIndicatorsfilterOptions = technicalIndicators
    let tmpBasicsfilterOptions = basics
    let tmpPerformanceFilterOptions = performance
    let tmpPortfolioCharacteristicsFilterOptions = portfolioCharacteristics
    let tmpOperationalFilterOptions = operation
    let tmpAssetAllocationfilterOptions = assetAllocation
    let tmpRatingsfilterOptions = ratings
    let tmpIncomeFilterOptions = income
    let tmpRiskfilterOptions = risk
    let tmpESGFundRatingFilterOptions = esgFundRating
    let tmpESGCarbonMetricsFilterOptions = esgCarbonMetrics
    // let tmptechnicalIndicator = technicalIndicators

    tmp.map(obj => {
      switch (obj.rootFilterId) {
        case 'popular-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpMostPopularfilterOptions, obj)
          break
        case 'technicalIndicators-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpTechnicalIndicatorsfilterOptions, obj)
          break
        case 'basics-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpBasicsfilterOptions, obj)
          break
        case 'performance-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpPerformanceFilterOptions, obj)
          break
        case 'portfolio-characteristics-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpPortfolioCharacteristicsFilterOptions, obj)
          break
        case 'operational-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpOperationalFilterOptions, obj)
          break
        case 'assetAllocation-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpAssetAllocationfilterOptions, obj)
          break
        case 'ratings-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpRatingsfilterOptions, obj)
          break
        case 'risk-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpRiskfilterOptions, obj)
          break
        case 'income-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpIncomeFilterOptions, obj)
          break
        case 'esgFundRating-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpESGFundRatingFilterOptions, obj)
          break
        case 'esgCarbonMetrics-select':
          setSingleFilterOptionFromSavedScreener(obj.Level, tmpESGCarbonMetricsFilterOptions, obj)
          break
      }
    })
  }
  const getExportTableData = (offset, csvLimit, tabKey) => {
    return new Promise((resolve) => {
      DataRequest.execute(
        `${URLS.CUSTOM.SCREEN + '?screenType=MF_ETF'}`,
        buildScreenInputs(offset, true, csvLimit, screenerArguments, sortArgument.current, tabKey),
        setExportToCsvData,
        null
      )
      function setExportToCsvData (response) {
        return resolve(response)
      }
    })
  }

  function DownloadCsv (e) {
    setDownloading(true)
    const promiseArray = []
    const batchCount = Math.ceil(fundDataCount / SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API)
    for (let iCount = 0; iCount < batchCount; iCount++) {
      let remainingFundCount = fundDataCount - iCount * SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API
      let offset = iCount === 0 ? 0 : iCount * SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API
      let csvLimit = remainingFundCount > SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API ? SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API : remainingFundCount
      promiseArray.push(getExportTableData(offset, csvLimit))
    }
    let output = []
    Promise.all(promiseArray).then((responseArray) => {
      responseArray.forEach(response => {
        if (!response.isDataRequestComplete) return
        if (!response.isError) {
          const responseData = response.data || {}
          output = [...output, ...SetCsvDataForExport(responseData.items)]
        }
      })
      setDownloading(false)
      const outputArray = [];
      if (output.length) {
        outputArray.push(Object.keys(output[0]));
        output?.forEach(obj => {
          const row = Object.values(obj);
          outputArray.push(row);
        });
      }
      const sheet = XLSX.utils.aoa_to_sheet(outputArray);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, sheet, activeTabKey);
      const fileName= activeTabKey.replace(' ', '')+ ' ' + formatDate(Date.now(), {format: 'MM/DD/YYYY'}).replace(/\//g, '.') + '.xlsx'
      XLSX.writeFile(workbook, fileName);
    })
  }

  const DownloadAll = async () => {
    setDownloading(true);
    const promise = [];
    let workbook = XLSX.utils.book_new();
    promise.push(getExcelSheetData(fundDataCount, TabKeysFund, getExportTableData, SetCsvDataForExport, SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_ALL_FUNDS));
    try {
      const excelData = await Promise.all(promise);
      excelData[0].forEach((sheet) => {
        const workSheet = XLSX.utils.aoa_to_sheet(sheet.data);
        XLSX.utils.book_append_sheet(workbook, workSheet, sheet.name);
      });
      const fileName = "Consolidated Screener".replace(" ", "") + " " + formatDate(Date.now(), {format: 'MM/DD/YYYY'}).replace(/\//g, '.') + ".xlsx";
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
    }
  }

  function downloadCsvHandler () {
    !isDownloadingStart && debounce(DownloadCsv, 1000)
  }
  function handleDownloadCsv (e) {
    if (e.key === 'Enter') {
      downloadCsvHandler()
    }
  }
  // Redirecting to compare screen from screenr
  function addToCompare (items) {
    let updatedItems = keyMsrData && keyMsrData.data.filter(x => items.find(f => f === x.xid))
    let updatedCompareCardData = []
    updatedItems.map(item => {
      updatedCompareCardData.push({
        'type': (productTypesForMF.includes(item['Product Type'])) || (item.type === 'MF') ? 'FSCMF' : 'FSCETF',
        'venueXid': item.xid,
        'symbol': item['Offshore Flex'] === 'Yes' ? '' : item.Ticker,
        'name': item.Name,
        'CUSIP': item.CUSIP,
        'IsFVETF': ScreenerFundVestTag(item),
        'OffshoreFlex': item['Offshore Flex'],
        'fundType': item.fundType
      })
    })
    let dataEmit = {}
    if (updatedItems) {
      dataEmit = {
        gotoPage: MOD_GOTO_PAGES.fund, // Option can be funds, model-marketplace, alternative-investments  ---  3rd Level Navigation tabs own by Pershing
        activeTabKey: 'compare',
        compareData: updatedCompareCardData
      }
    }
    emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, dataEmit)
  }

  function enableResetButton () {
    return !(document.querySelectorAll('*[id^="pill"]').length === 2 && document.querySelector('#pill-FVETF') && document.querySelector('#pill-FundVestMutualFunds'))
  }

  return (
    <div key={resetScreenerFilter} className={styles.ScreenerContainer} id='main'>
      <div className={styles.screenerTitle}>
        <div className={styles.discoverModels} aria-label='Discover Funds'>Discover Funds</div>
        <div role='button' tabIndex='0' id='screenName' aria-label={screenerName}>{deviceTypeItem === deviceType.Desktop ? screenerName : truncateText(screenerName, 25)} </div>
      </div>
      <div className={styles.openSaveOverlay} role={'navigation'} >
        <div className={styles.glossary}>
          <EsgGlossary glossary={FundEsgGlossary.glossary} showGlossary={showGlossary} settingShowGlossary={settingShowGlossary} module='Screener' />
        </div>
        <div className={styles.dropdown}>
          <BasicDropDown data={screenerDropdownOptions(selectedScreenerId, FUND_SCREEN_TYPE)} selectedValue={'Open & Save'} isMobileView={isMobile} ref={basicDropdown} changeHandler={(e) => changeHandlerDropDown(e)} caretColor={'white'} isBlueBackground={!isMobile} />
        </div>
      </div>
      <div className={styles.screenerFiltersContainer} id='screenerFiltersContainer' role={'navigation'} name={'screenerFiltersContainer'} aria-label={SCREENER_TYPES.FUND_SCREENER.ARIA_LABEL}>
        <div className={styles.filtersLeftShadow} id={'filtersLeftShadow'} aria-hidden='true' />
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='popular-select' key='popular-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={mostPopularSelectRef} addPill={addPill} updateFilterDynamicData={updateFilterDynamicData} label='popular-select' list={mostPopular} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'popular-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='operational-select' key='operational-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={operatonSelectRef} addPill={addPill} updateFilterDynamicData={updateFilterDynamicData} label='operational-select' list={operation} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'operational-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='basics-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={basicSelectRef} addPill={addPill} updateFilterDynamicData={updateFilterDynamicData} label='basics-select' list={basics} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'basics-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='esgFundRating-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} updateFilterDynamicData={updateFilterDynamicData} ref={esgFundRatingSelectRef} addPill={addPill} label='esgFundRating-select' list={esgFundRating} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'esgFundRating-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='esgCarbonMetrics-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} updateFilterDynamicData={updateFilterDynamicData} ref={esgCarbonMetricsSelectRef} addPill={addPill} label='esgCarbonMetrics-select' list={esgCarbonMetrics} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'esgCarbonMetrics-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='assetAllocation-select' key='assetAllocation-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={assetAllocationSelectRef} addPill={addPill} updateFilterDynamicData={updateFilterDynamicData} label='assetAllocation-select' list={assetAllocation} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'assetAllocation-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='income-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={incomeSelectRef} addPill={addPill} updateFilterDynamicData={updateFilterDynamicData} label='income-select' list={income} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'income-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='performance-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={performanceSelectRef} addPill={addPill} updateFilterDynamicData={updateFilterDynamicData} label='performance-select' list={performance} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'performance-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='portfolio-characteristics-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={portfolioCharacteristicsSelectRef} addPill={addPill} updateFilterDynamicData={updateFilterDynamicData} label='portfolio-characteristics-select' list={portfolioCharacteristics} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'portfolio-characteristics-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='ratings-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={ratingsSelectRef} addPill={addPill} label='ratings-select' list={ratings} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'ratings-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='risk-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} ref={riskSelectRef} updateFilterDynamicData={updateFilterDynamicData} addPill={addPill} label='risk-select' list={risk} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'risk-select').displayLabel} />
        </div>
        <div className={styles.selectScreenerFilter} >
          <SxSelect deviceType={deviceTypeItem} id='technicalIndicators-select' screenId={FUND_SCREEN_ID} screenType={FUND_SCREEN_TYPE} updateFilterDynamicData={updateFilterDynamicData} ref={technicalIndicatorsSelectRef} addPill={addPill} label='technicalIndicators-select' list={technicalIndicators} className={styles.selectScreenerFilter} title={filterDisplayName && filterDisplayName.find(f => f.val === 'technicalIndicators-select').displayLabel} />
        </div>
        <div className={styles.filtersRightShadow} id={'filtersRightShadow'} aria-hidden='true' />

      </div>
      <div className={styles.selectedFilterPills}>
        <div id='button-filter' className={styles.pillsContainer}>
          {getPill(mostPopular, 'L2')}
          {getPill(mostPopular, 'L3')}
          {getPill(operation, 'L2')}
          {getPill(operation, 'L3')}
          {getPill(basics, 'L2')}
          {getPill(basics, 'L3')}
          {getPill(esgFundRating, 'L2')}
          {getPill(esgFundRating, 'L3')}
          {getPill(esgCarbonMetrics, 'L2')}
          {getPill(esgCarbonMetrics, 'L3')}
          {getPill(assetAllocation, 'L2')}
          {getPill(assetAllocation, 'L3')}
          {getPill(income, 'L2')}
          {getPill(performance, 'L2')}
          {getPill(portfolioCharacteristics, 'L2')}
          {getPill(ratings, 'L2')}
          {getPill(ratings, 'L3')}
          {getPill(risk, 'L3')}
          {getPill(technicalIndicators, 'L2')}
          {getPill(technicalIndicators, 'L3')}
          <div className={styles.resetFundScreenFilters}>
            <span aria-label={`${fundDataCount} Results`} className={styles.screenerResultCount}>{fundDataCount} Results </span>
            <span role='button' id='resetFilters' tabIndex='0' onKeyDown={(e) => resetFilterOptions()} onClick={() => resetFilterOptions()} className={`${styles.resetFilterLnk} ${enableResetButton() ? styles['enbResetFilter'] : styles['disbResetFilter']}`}> Reset Filters </span>
          </div>
        </div>
      </div>
      <div id='fundCardContainer'>
        <div aria-label='Top Results' className={styles.topResultsSection}>
          <span className={styles.topResults}>Top Results</span>
          <span className={styles.topResultsCriteria}>Based on selected criteria and highest month-end 5 year annualized return</span>
        </div>
        <div className={styles.scrollContainer}>
          {
            topScreenResults.map(item => {
              return (<div className={styles.quoteCard} key={'quoteCard-xid-' + item.SPCFWSODIssue}>
                <QuoteCard type={getFundType(item.SPCFFundType).name} fundType={item.SPCFFundType} moduleType={'FundScreener'} style={{borderTop: `4px solid ${getFundType(item.SPCFFundType).color}`}} id={'quoteCardNews_' + item.SPCFWSODIssue} key={'quoteCardNews_' + item.SPCFWSODIssue} venueXid={item.SPCFWSODIssue} symbol={item.SPCFticker} name={item.SPCFFundName} CUSIP={item.SPCFMStarOverrideCUSIP} showAddToWatchlist={showAddToWatchlist} />
              </div>)
            })
          }
        </div>
        <PerformanceDisclosure />
      </div>
      <div className={`${styles.screenerTitle} ${styles.allModels}`}>
        <div aria-label='All Funds'>All Funds ({fundDataCount})</div>
      </div>
      <div className={styles.downloadCompareButtonWrapperDiv}>
        <div className={styles.downloadCompareButtonDiv}>
          {isDownloading ? <span className={styles.loader}>
            <Loader spinnerSize={'2x'} msg={'Loading'} />
          </span>
            : <span aria-label='Download CSV' data-testid='downloadCSV' id="downloadCSV" className={`${styles.displayHidden} ${styles.headerActionMenu}`} onKeyDown={(e) => handleDownloadCsv(e)} role='button' tabIndex='0'>
              <ActionMenu actionsList={actionList} actionHandler={(e) => actionClickHandler(e, DownloadCsv, DownloadAll)} screener tabIndex='0' />
            </span>
          }
          <div id='divcompareBtn' className={`${styles.buttons} ${modelCompare.length < 1 ? styles['disabled'] : ''}`}>
            <a role='button' tabIndex='0' data-testid='divcompareBtn' id='compareBtn' onKeyDown={() => addToCompare(modelCompare)} onClick={() => addToCompare(modelCompare)}>Compare Funds</a>
          </div>
        </div>
      </div>
      <div className={styles.tabControl}>
        <TabControl id={'MainPage_tab'} className={styles.fundScreenTabs} ariaLabel={'Discover funds tabs'} activeTab={activeTabKey} changeHandler={(e) => changeHandlerTab(e)}>
          <div key={'Key Measures'} label='Key Measures'>
            {activeTabKey === 'Key Measures' && keyMsrData && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={keyMsrData} headersKeyChanged={keyHeadersChangeId} headers={keyMeasuresHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'KeyMeasures'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsKeyMeasures} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Operational' label='Operational'>
            { activeTabKey === 'Operational' && operatData && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={operatData} headersOpChanged={opHeadersChangeId} headers={operationalHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'Operational'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsOperational} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Profile' label='Profile'>
            { activeTabKey === 'Profile' && profileData && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={profileData} headers={profileHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'Profile'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsProfile} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={profileHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='ESG Rating' label='ESG Rating'>
            { activeTabKey === 'ESG Rating' && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={esgRatingData} headers={esgRatingHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'ESGRating'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsEsgRating} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={esgRatingHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='ESG Carbon Metrics' label='ESG Carbon Metrics'>
            { activeTabKey === 'ESG Carbon Metrics' && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={esgCarbonMetricsData} headers={esgCarbonMetricsHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'ESGCarbonMetrics'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsEsgCarbonMetrics} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={esgCarbonMetricsHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Asset Allocation' label='Asset Allocation'>
            { activeTabKey === 'Asset Allocation' && assetAllocationData && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={assetAllocationData} headers={assetAllocationHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'AssetAllocation'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsAssetAllocation} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={assetHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Income' label='Income'>
            { activeTabKey === 'Income' && incomeData && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={incomeData} headers={incomeHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'Income'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsIncome} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={incomeHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Performance' label='Performance'>
            { activeTabKey === 'Performance' && performanceData && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={performanceData} headers={performanceHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'Performance'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsPerformance} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={performanceHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Portfolio Characteristics' label='Portfolio Characteristics'>
            { activeTabKey === 'Portfolio Characteristics' && portfolioCharacterisiticsData && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={portfolioCharacterisiticsData} headers={portfolioCharacteristicsHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'PortfolioCharacterisitics'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsPortfolioCharacterisitics} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={portCharHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Ratings' label='Ratings'>
            { activeTabKey === 'Ratings' && ratingsData && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={ratingsData} headers={ratingsHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'Ratings'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsRatings} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={ratingsHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Risk' label='Risk'>
            { activeTabKey === 'Risk' && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={riskData} headers={riskHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'Risk'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsRisk} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={riskHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
          <div key='Technical Indicators' label='Technical Indicators'>
            { activeTabKey === 'Technical Indicators' && <TableWithShowMore deviceType={deviceTypeItem} key='FundScreener' chkboxSelectedIds={modelCompare} ModelScreenerData={technicalIndicatorsData} headers={technicalIndicatorsHeaders} defaultSortColumn={'SortingTicker'} handleOnCheckboxChange={handleOnCheckboxChange} maxSelectionLength={10} moduleType={'FundScreener'} fixedColumnCount={deviceTypeItem === deviceType.Desktop ? 5 : 4} moduleName={'TechnicalIndicators'} errorMsg={{msg: 'Maximum of 10 Models for Comparison', width: '234px'}} startIndex={0} syncFixedColumns={syncFixedColumnsTechnicalIndicators} helperMesssage={['Annualized', 'Morningstar Primary Benchmark']} headersKeyChanged={technicalIndicatorsHeadersChangeId} showMoreClick={() => showMoreClickHandler()} showMoreClickRows={(fundDataCount - tableResultCount) > 0 ? (fundDataCount - tableResultCount) : 0} sortColumnData={sortColumnDataHandler} /> }
          </div>
        </TabControl>
      </div>
      <div className={styles.dataQuoteContainer}>
        <div className={`${styles.datequoteModelScreener} ${styles.dataQuoteAdjust}`} aria-label='Delayed Quote as of 12:04PM EST'>
          Delayed Quote as of {formatDate(delayedQuoteDate, {UTCtoEST: true, postFix: 'ET', format: DATE_FORMAT.MONTH_DAY_YEAR_TIMEINSEC})}
        </div>
      </div>
      <div>{activeTabKey === 'Performance' && <PerformanceDisclosure />}</div>
      <ModalRoot
        header={modalHeader}
        bodyLabel='Screener Name'
        bodyDescription='Description'
        errorMsg={errorMsg}
        saveBtnHandler={(isSave, name, description, isEditMode) => saveBtnHandler(isSave, name, description, isEditMode)}
        deleteBtnHandler={(savedScreenerId) => deleteScreenerHandler(savedScreenerId)}
        openBtnHandler={(name) => openBtnHandler(name)}
        closeHandler={() => closeHandler()}
        savedScreenName={savedScreenName.current}
        screenId={FUND_SCREEN_ID}
        textChange={() => textChange()}
        showActionKebabMenu
        editScreenData={editScreenData}
      />
      <footer>
        <ImportantDisclosure />
        <Footer />
      </footer>
      {showUpdateConfirmation &&
        <div id='UpdateScreenerConfirmation'>
          <ConfirmationPopup
            headerInfo={UpdateScreenerConfirmationDialogConstants.HeaderInfo}
            confirmationHandler={updateScreenerConfirmationHandler}
            closeHandler={() => {
              setShowUpdateConfirmation(!showUpdateConfirmation)
              closeHandler()
            }}
            bodyContent={UpdateScreenerConfirmationDialogConstants.BodyContent}
            confirmButtonText={UpdateScreenerConfirmationDialogConstants.ConfirmButtonText}
            cancelButtonText={UpdateScreenerConfirmationDialogConstants.CancelButtonText}
          />
        </div>}
      {watchlistObj.toggleAddtoWatchlist &&
        <AddToWatchlists symbol={watchlistObj.symbol} xid={watchlistObj.venueXid} onClose={() => { showAddToWatchlist(false, watchlistObj.venueXid, watchlistObj.symbol) }} />
      }
    </div>
  )
}
FundScreener.propTypes = {
  symbol: PropTypes.string,
  screenerPillChange: PropTypes.number,
  navigationFromViewAllLinkFlag: PropTypes.number,
  isTabChange: PropTypes.bool,
  screenerFilterOptionsToBeSaved: PropTypes.array,
  tabChangePersistState: PropTypes.func,
  screenerName: PropTypes.string,
  screenerArguments: PropTypes.array,
  updateScreenerName: PropTypes.func
}
export default FundScreener
