/* eslint-disable no-irregular-whitespace */
export const URLS = {
  PLATFORM: {
    QUOTE: 'quote/v1/details',
    SHARECLASS: 'managedfunds-morningstar-shareclass/v1/share-class',
    MARKET_RANKINGS: 'market-rankings/v1/detail',
    XREF: 'xref/v1/venue-xids',
    XREFGETSYMBOL: 'xref/v1/symbols',
    XREFPREDICTIVESEARCH: 'xref/v1/venues',
    EXCHANGEINFORMATION: '/markets-exchangeinformation/v1/details',
    MARKETRANKINGS: '/market-rankings/v1/detail',
    SPARKCHART: '/charts-spark/v1/spark',
    QUOTE_HISTORICAL: 'quote-historical/v1/details',
    QUOTE_HISTORICAL_DURATION: 'quote-historical/v1/duration-details',
    CORPORATEACTIONSDIVIDENDS: 'corporateactions-dividends/v1',
    PRICINGCURRENTENDDAYSTATS: '/pricing-current-endofdaystats/v1/details',
    COMPANYGENERALINFORMATION: '/equities-morningstar-generalinformation/v1/details',
    SPLITS_CALENDAR: '/corporateactions-splits-calendar/v1/splitEventsByDate',
    CALENDAREVENTBYDATE: '/calendar/v1/eventsByDate',
    DIVIDENDEVENTSBYDATE: '/corporateactions-dividends-calendar/v1/dividendEventsByDate',
    DIVIDENDEVENTSCOUNTBYDATE: '/corporateactions-dividends-calendar/v1/counts',
    EARNINGEVENTSCOUNTBYDATE: '/calendar/v1/counts',
    ECONOMICEVENTSCOUNTBYDATE: '/calendar/v1/counts',
    SPLITEVENTSCOUNTBYDATE: '/corporateactions-splits-calendar/v1/counts',
    SPLITSENTSBYDATE: '/corporateactions-splits-calendar/v1/splitEventsByDate',
    REVENUE_NETINCOME: '/equities-morningstar-financialstatements/v1/key-data',
    HISTORICALREVENUE: '/equities-morningstar-financialratios/v1/history',
    CURRENCYCONVERSION: '/currency-conversion/v1/rate',
    FINANCIALRATIOS: '/equities-morningstar-financialratios/v1/details',
    CORPORATEACTIONSSPLITS: '/corporateactions-splits/v1',
    MORNINGSTARESTIMATES: '/equities-morningstar-estimates/v1/trends',
    EARNINGSANDESTIMATES: '/equities-morningstar-estimates/v1/trends',
    SAVEDSCREENS: '/saved-screens/v1',
    USER_MGMT_PREFERENCES: 'user-management/v1/preferences',
    EQUITIES_MORNINGSTAR_ESTIMATES: '/equities-morningstar-estimates/v1',
    EQUITIESMORNINGSTAREARNINGS: '/equities-morningstar-earnings/v1/details',
    EQUITIES_MORNING_STAR_PEERS: '/equities-morningstar-peers/v1/details',
    EQUITY_CLASSIFICATION: '/equities-morningstar-gecs/v1/equityClassification',
    RATIOS_AGGREGATE: '/equities-morningstar-gecs/v1/ratiosAggregate',
    EQUITIESMORNINGSTARRATIOSPERCENTILE: '/equities-morningstar-gecs/v1/ratiosPercentiles',
    FINANCIAL_STATEMENTS: '/equities-morningstar-financialstatements/v1/financial-statements',
    SEC_FILINGS: '/sec-filings/v1/documentList',
    SEC_FILINGS_DOCUMENT: 'sec-filings/v1/document',
    MORNINGSTAR_EQUITIES_CLASSFICATION: '/equities-morningstar-assetclassification/v1/details',
    MORNINGSTAR_OWNERSHIP_DETAILS: '/ownership-morningstar/v1/fundDetails',
    MORNINGSTAR_OWNERSHIP_INSTITUTION_DETAILS: '/ownership-morningstar/v1/institutionDetails',
    WATCHLIST: '/watchlist/v1/watchlist',
    DISTINCT_VALUES_SCREEN: 'screener/v1/screens',
    CALENDAR_EVENTS_COUNT: '/calendar/v1/counts',
    OPTION_CHAINS_EXPIRATION_DATES: 'optionchainsgreeks/v1/options/expirationdates',
    OPTION_CHAINS_WITH_GREEKS: 'optionchainsgreeks/v1/options/chainwithgreeks',
    MORNINGSTAR_MANAGEDFUNDS_BENCHMARK_INFORMATION: 'managedfunds-morningstar-benchmarkinformation/v1/categories/',
    MORNINGSTAR_MANAGEDFUNDS_PEERS: 'managedfunds-morningstar-peers/v1/details',
    ASSET_ALLOCATION: 'managedfunds-morningstar-assetallocation/v1/asset-allocation',
    MIND_EVENTS: 'mind/v1/interesting-events',
    MANAGEDFUNDS_MORNINGSTAR_RATING_RISK: 'managedfunds-morningstar-ratingsandrisk/v1/ratings-and-risk',
    MANAGEDFUNDS_MORNINGSTAR_YIELD: 'managedfunds-morningstar-yield/v1',
    MANAGED_FUND_MORNINGSTAR_RISK_MEASURE: 'managedfunds-morningstar-riskmeasures/v1/risk-measures',
    MANAGED_FUND_MORNINGSTAR_PERFORMANCE: 'managedfunds-morningstar-performance/v1/trailing-month-end',
    MANAGED_FUND_MORNINGSTAR_PORTFOLIOSTATS: 'managedfunds-morningstar-portfoliostats/v1/details',
    EQUITIES_MORNINGSTAR_ESGLEVELTWORISKRATING: 'equities-morningstar-esgleveltworiskrating/v1/ESGLevelTwoRiskRating',
    EQUITIES_MORNINGSTAR_ESGLEVELTWO_CARBON_RISK_RATING: 'equities-morningstar-esgleveltwocarbonriskrating/v1/ESGLevelTwoCarbonRiskRating',
    MANAGED_FUND_MORNINGSTAR_BEST_AVAILABLE: 'managedfunds-morningstar-fundxidtobestavailablevenuexid/v1/bestAvailableVenueXid',
    OWNERSHIP_MORNINGSTAR_FUND_DETAILS: 'ownership-morningstar/v1/fundDetails',
    MORNINGSTAR_INSIDEROWNERSHIP: 'equities-morningstar-insiderownership/v1'
  },
  CUSTOM: {
    NEWS: 'pershing-netxwealth-api/v1/news',
    RESEARCH: 'pershing-netxwealth-api/v1/research',
    CONSENSUS_RATING: 'pershing-netxwealth-api/v1/consensusRating',
    NEWSARTICLE: 'pershing-netxwealth-api/v1/news/article',
    RESEARCH_DOCUMENT_PDF: 'pershing-netxwealth-api/v1/getDocument',
    RESEARCH_PDF: 'pershing-netxwealth-api/v1/researchReport',
    RATINGS: 'pershing-netxwealth-api/v1/ratings',
    SEARCH: 'pershing-netxwealth-api/v1/search',
    PRODUCTACCESS: 'pershing-netxwealth-api/v1/productAccess',
    GET_RECENT_SEARCH: 'pershing-netxwealth-api/v1/getRecentSearch',
    SET_RECENT_SEARCH: 'pershing-netxwealth-api/v1/setRecentSearch',
    GET_DATAPOINTS_AI: 'pershing-netxwealth-api/v1/getQIDdataPointsForAI',
    PRESCREEN: 'pershing-netxwealth-api/v1/preScreen',
    SCREEN: 'pershing-netxwealth-api/v1/screen',
    MIN_MAX_VALUES_SCREEN: 'pershing-netxwealth-api/v1/minMaxScreener',
    OBJECT_STORE_GET: 'pershing-netxwealth-api/v1/getString',
    OBJECT_STORE_SAVE: 'pershing-netxwealth-api/v1/saveString',
    GET_SECTOR_DETAILS: 'pershing-netxwealth-api/v1/getSectorDetails',
    OBJECT_STORE_REMOVE: 'pershing-netxwealth-api/v1/removeString',
    SET_VIEWED_NEWS_CATEGORY: 'pershing-netxwealth-api/v1/setViewedNewsCategory',
    GET_VIEWED_NEWS_CATEGORY: 'pershing-netxwealth-api/v1/getViewedNewsCategory',
    GET_MARKET_EVENTS_DATA: 'pershing-netxwealth-api/v1/getMarketEventsData'
  }
}
export const TIER_TYPES = {
  REALTIME: 5894,
  METERED: 5895,
  DELAYED: 5896
}

export const ENT_LIST = {
  REALTIME_1: 121,
  REALTIME_2: 195,
  REALTIME_3: 510
}

export const ISSUE_TYPES = {
  Etf: 'ETF',
  MutualFund: 'MUTUALFUND',
  Indices: 'INDICES',
  Stock: 'STOCK',
  Option: 'Option',
  EQ: 'EQ'
}

export const ISSUE_TYPES_MAP = {
  OP: 'Option',
  ADR: 'STOCK',
  ALN: 'STOCK',
  CL: 'STOCK',
  CLRT: 'STOCK',
  CP: 'STOCK',
  CPRT: 'STOCK',
  CS: 'STOCK',
  CSRT: 'STOCK',
  DR: 'STOCK',
  DRRT: 'STOCK',
  EQ: 'STOCK',
  ETDS: 'STOCK',
  IDR: 'STOCK',
  PR: 'STOCK',
  PRRT: 'STOCK',
  PS: 'STOCK',
  RT: 'STOCK',
  WD: 'STOCK',
  WI: 'STOCK',
  CSD: 'STOCK',
  ETF: 'ETF',
  LETF: 'ETF',
  ETN: 'ETF',
  LETN: 'ETF',
  MF: 'MUTUALFUND',
  OF: 'MUTUALFUND',
  IN: 'INDICES'
}

export const EventType = {
  Economics: 'Economics',
  Dividends: 'Dividends',
  Splits: 'Splits',
  Earnings: 'Earnings'
}

export const CalendarEventType = {
  Economics: 'Economic Events',
  Dividends: 'Dividend Events',
  Splits: 'Split Events',
  Earnings: 'Earnings Events'
}

export const ChartTimeFrames = {
  OneDay: 1,
  Week: 7,
  Month: 30,
  Year: 365
}

export const CURRENCY_CONVERSION_CONSTANTS = {
  DISCLAIMER: 'Delayed currency rate.'
}

export const MostActiveData = {
  DaysCount: 3,
  RankingType: 'MostActive',
  DefaultStockExchange: 'NYSE Stocks'
}

export const rankingType = [
  { key: 'PctChgGainers', value: 'Top Gainers (%)' },
  { key: 'NetGainers', value: 'Top Gainers ($)' },
  { key: 'PctChgLosers', value: 'Top Losers (%)' },
  { key: 'NetLosers', value: 'Top Losers ($)' },
  { key: '52WkHigh', value: '52 Week High' },
  { key: '52WkLow', value: '52 Week Low' },
  { key: 'BreakoutHigh', value: 'Breakout High' },
  { key: 'BreakoutLow', value: 'Breakout Low' },
  { key: 'GapHigh', value: 'Gap High' },
  { key: 'GapLow', value: 'Gap Low' }
]
export const rankingSet = [
  { key: 'S&P 500 Index', value: 'S&P 500' },
  { key: 'DJIA Index', value: 'DJIA' },
  { key: 'Russell 2000', value: 'Russell 2000' },
  { key: 'NYSE Stocks', value: 'NYSE' },
  { key: 'NASDAQ Stocks', value: 'NASDAQ' },
  { key: 'NYSE American Stocks', value: 'AMEX' }
]

export const KEY_ACCESS_TOKEN = 'modAccessToken'

export const Exchange = {
  NYQ: 'NYQ'
}

export const ENVIRONMENTS_DOMAINS = {
  LOCALHOST: [
    'localhost'
  ],
  DEVELOPMENT: [
    'pershing-netxwealth.dev.services.mdgapp.net'
  ],
  ACCEPTANCE: [
    'pershing-netxwealth.markitqa.com',
    'pershing-netxwealth.qa.services.mdgapp.net'
  ],
  ACCEPTANCE_2: [
    'pershing-netxwealth-a2.markitqa.com',
    'pershing-netxwealth-a2.qa.services.mdgapp.net'
  ],
  ACCEPTANCE_3: [
    'pershing-netxwealth-a3.markitqa.com',
    'pershing-netxwealth-a3.qa.services.mdgapp.net'
  ],
  ACCEPTANCE_4: [
    'pershing-netxwealth-a4.markitqa.com',
    'pershing-netxwealth-a4.qa.services.mdgapp.net'
  ],
  PRODUCTION: [
    'pershing-netxwealth.wallst.com',
    'pershing-netxwealth.ptc.services.mdgapp.net',
    'pershing-netxwealth.ctc.services.mdgapp.net'
  ]
}
export const PROVIDER_TYPE = {
  REUTERS: 'Reuters',
  TODAY_NEWS: 'Todays News'
}

export const DEFAULT_NEWS_INPUTS = {
  argsOperator: 'AND',
  arguments: [],
  sortArguments: [
    {
      direction: 'desc',
      field: 'DocumentDate'
    }
  ],
  limit: 20,
  offset: 0
}

export const DEFAULT_PRE_SCREEN_INPUTS = {
  argsOperator: 'AND',
  arguments: [],
  offset: 0,
  limit: 20,
  sortArguments: [
    {
      direction: 'A',
      field: 'SPCFWSODIssue'
    }
  ],
  'noCache': false
}


// Fund Screener
export const DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS = {
  argsOperator: 'AND',
  arguments: [],
  offset: 0,
  limit: 20,
  sortArguments: [
    {
      direction: 'A',
      field: 'SPCFticker'
    }
  ],
  resultFields: []
}
export const FundScreenerKeyMeasuresArr = [
  'SPCFticker',
  'SPCFMorningstarCategoryName',
  'SPCFMorningstarOverallRating',
  'SPCFProspectusNetExpenseRatio',
  'SPCFFundType',
  'SPCFNoTransactonFeeEligibilityIndicator',
  'SPCFIsFVETF',
  'SPCFDateAdded',
  'SPCFFundvest200Eligible',
  'SPCFFundNetAssets',
  'SPCFTrailingReturnY3',
  'SPCFTaxCostRatioY3',
  'SPCFIndicator12b1',
  'SPCFNoTransactonFeeEligibilityIndicator',
  'SPCFMStarOverrideCUSIP',
  'SPCFMStarOverrideFundFamilyName'
]
export const FundScreenerOperationalArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundShareClass',
  'SPCFFundservIndicator',
  'SPCFOffShoreIndicator',
  'SPCFOffShoreProcessingIndicator',
  'SPCFMStarOverrideLoadType',
  'SPCFMStarOverrideClosedToNewInvestors',
  'SPCFClosedToSellsIndicator',
  'SPCFDealerAgreementRequiredForLoadTrades',
  'SPCFDealerAgreementRequiredForLoadTransfers',
  'SPCFDealerAgreementRequiredForNo_LoadTrades',
  'SPCFDealerAgreementRequiredForNo_LoadTransfers',
  'SPCFBNYMellon',
  'SPCFSURCHARGE'
]
export const FundScreenerProfileArr = [
  'SPCFticker',
  'SPCFFundAssetClassGroup',
  'SPCFLeveragedFundFlag',
  'SPCFLeveragedFundFactor',
  'SPCFProspectusPrimaryBenchmarkName',
  'SPCFGrossExpenseRatio',
  'SPCFSalesLoadDeferred',
  'SPCFSalesLoadMaxFrontLoad',
  'SPCFInceptionDate',
  'SPCFManagerTenure',
  'SPCFMStarOverrideMinimumInitialInvestment'
]
export const FundScreenerAssetAllocationArr = [
  'SPCFticker',
  'SPCFWeightingTop10Holdings',
  'SPCFYearlyTurnoverRatio',
  'SPCFTurnoverRatioDate',
  'SPCFEquityStyleBoxText',
  'SPCFFixedIncomeStyleBoxText',
  'SPCFTotalBondNet',
  'SPCFTotalStockNet',
  'SPCFPortfolioDate'
]
export const FundScreenerIncomeArr = [
  'SPCFticker',
  'SPCFMStarOverrideDividendFrequency',
  'SPCFTrailing1YrYield',
  'SPCFSECYield'
]
export const FundScreenerPerformanceArr = [
  'SPCFticker',
  'SPCFNAVChgPct',
  'SPCFNAVChgAmt',
  'SPCFTrailingMarketReturnYTD',
  'SPCFTrailingMarketReturnY1',
  'SPCFTrailingMarketReturnY3',
  'SPCFTrailingMarketReturnY5',
  'SPCFTrailingMarketReturnY10',
  'SPCFTrailingReturnYTD',
  'SPCFTrailingReturnY1',
  'SPCFTrailingReturnY3',
  'SPCFTrailingReturnY5',
  'SPCFTrailingReturnY10',
  'SPCFTaxCostRatioY3',
  'SPCFTaxCostRatioY5',
  'SPCFPerformanceAsOfDate'
]
export const FundScreenerPortfolioCharacteristicsArr = [
  'SPCFticker',
  'SPCFNumberofHoldingsNet',
  'SPCFAverageMarketCap',
  'SPCFEarningsYieldLongInv',
  'SPCFAverageCreditQualityLong',
  'SPCFAvgCoupon',
  'SPCFYieldToMaturityLong',
  'SPCFPortfolioDate'
]
export const FundScreenerRatingsArr = [
  'SPCFticker',
  'SPCFMedalistRating',
  'SPCFMorningstarOverallRating',
  'SPCFMorningstar3YrRating',
  'SPCFMorningstar5YrRating',
  'SPCFMorningstar10YrRating',
  'SPCFReturnScore',
  'SPCFRiskScore',
  'SPCFMedalistRatingDate',
  'SPCFMStarRatingAsOfDate'
]
export const FundScreenerRiskArr = [
  'SPCFticker',
  'SPCFSharpeRatioY3',
  'SPCFSharpeRatioY5',
  'SPCFStandardDeviationY3',
  'SPCFStandardDeviationY5',
  'SPCFPrimaryIndexAlphaY3',
  'SPCFPrimaryIndexAlphaY5',
  'SPCFPrimaryIndexBetaY3',
  'SPCFPrimaryIndexBetaY5',
  'SPCFPrimaryIndexRSquaredY3',
  'SPCFPrimaryIndexRSquaredY5',
  'SPCFCaptureRatio3YearUp',
  'SPCFCaptureRatio3YearDown',
  'SPCFCaptureRatio5YearUp',
  'SPCFCaptureRatio5YearDown',
  'SPCFMorningstarPrimaryBenchmarkName',
  'SPCFPerformanceAsOfDate'
]
export const FundScreenerTechIndicatorArr = [
  'SPCFticker',
  'SPCFOnBalanceVolume',
  'SPCFVolume',
  'SPCFAvg10DayVolume',
  'SPCFVolume90dAvg',
  'SPCFPctChgVs52wkLo',
  'SPCFPctChgVs52wkHi',
  'SPCFPremiumDiscountM1Average',
  'SPCFPremiumDiscountY1Average',
  'SPCFPremiumDiscountY1High',
  'SPCFPremiumDiscountY1Low',
  'SPCFPremiumDiscountAsOfDate'
]
export const FundScreenerResultColumnArr = [
  'SPCFfundname',
  'SPCFticker',
  'SPCFMorningstarCategoryName',
  'SPCFMorningstarOverallRating',
  'SPCFProspectusNetExpenseRatio',
  'SPCFFundType',
  'SPCFNoTransactonFeeEligibilityIndicator',
  'SPCFIsFVETF',
  'SPCFDateAdded',
  'SPCFFundvest200Eligible',
  'SPCFFundNetAssets',
  'SPCFTrailingReturnY3',
  'SPCFTaxCostRatioY3',
  'SPCFIndicator12b1',
  'SPCFMStarOverrideCUSIP',
  'SPCFMStarOverrideFundFamilyName',
  'SPCFMStarOverrideFundShareClass',
  'SPCFFundservIndicator',
  'SPCFOffShoreIndicator',
  'SPCFOffShoreProcessingIndicator',
  'SPCFMStarOverrideLoadType',
  'SPCFMStarOverrideClosedToNewInvestors',
  'SPCFClosedToSellsIndicator',
  'SPCFDealerAgreementRequiredForLoadTrades',
  'SPCFDealerAgreementRequiredForLoadTransfers',
  'SPCFDealerAgreementRequiredForNo_LoadTrades',
  'SPCFDealerAgreementRequiredForNo_LoadTransfers',
  'SPCFBNYMellon',
  'SPCFSURCHARGE',
  'SPCFFundAssetClassGroup',
  'SPCFLeveragedFundFlag',
  'SPCFLeveragedFundFactor',
  'SPCFProspectusPrimaryBenchmarkName',
  'SPCFGrossExpenseRatio',
  'SPCFSalesLoadDeferred',
  'SPCFSalesLoadMaxFrontLoad',
  'SPCFInceptionDate',
  'SPCFManagerTenure',
  'SPCFMStarOverrideMinimumInitialInvestment',
  'SPCFWeightingTop10Holdings',
  'SPCFYearlyTurnoverRatio',
  'SPCFTurnoverRatioDate',
  'SPCFEquityStyleBoxText',
  'SPCFFixedIncomeStyleBoxText',
  'SPCFTotalBondNet',
  'SPCFTotalStockNet',
  'SPCFPortfolioDate',
  'SPCFMStarOverrideDividendFrequency',
  'SPCFTrailing1YrYield',
  'SPCFSECYield',
  'SPCFNAVChgPct',
  'SPCFNAVChgAmt',
  'SPCFTrailingMarketReturnYTD',
  'SPCFTrailingMarketReturnY1',
  'SPCFTrailingMarketReturnY3',
  'SPCFTrailingMarketReturnY5',
  'SPCFTrailingMarketReturnY10',
  'SPCFTrailingReturnYTD',
  'SPCFTrailingReturnY1',
  'SPCFTrailingReturnY5',
  'SPCFTrailingReturnY10',
  'SPCFTaxCostRatioY5',
  'SPCFPerformanceAsOfDate',
  'SPCFNumberofHoldingsNet',
  'SPCFAverageMarketCap',
  'SPCFEarningsYieldLongInv',
  'SPCFAverageCreditQualityLong',
  'SPCFAvgCoupon',
  'SPCFYieldToMaturityLong',
  'SPCFMedalistRating',
  'SPCFMorningstar3YrRating',
  'SPCFMorningstar5YrRating',
  'SPCFMorningstar10YrRating',
  'SPCFReturnScore',
  'SPCFRiskScore',
  'SPCFMedalistRatingDate',
  'SPCFMStarRatingAsOfDate',
  'SPCFSharpeRatioY3',
  'SPCFSharpeRatioY5',
  'SPCFStandardDeviationY3',
  'SPCFStandardDeviationY5',
  'SPCFPrimaryIndexAlphaY3',
  'SPCFPrimaryIndexAlphaY5',
  'SPCFPrimaryIndexBetaY3',
  'SPCFPrimaryIndexBetaY5',
  'SPCFPrimaryIndexRSquaredY3',
  'SPCFPrimaryIndexRSquaredY5',
  'SPCFCaptureRatio3YearUp',
  'SPCFCaptureRatio3YearDown',
  'SPCFCaptureRatio5YearUp',
  'SPCFCaptureRatio5YearDown',
  'SPCFMorningstarPrimaryBenchmarkName',
  'SPCFOnBalanceVolume',
  'SPCFVolume',
  'SPCFAvg10DayVolume',
  'SPCFVolume90dAvg',
  'SPCFPctChgVs52wkLo',
  'SPCFPctChgVs52wkHi',
  'SPCFPremiumDiscountM1Average',
  'SPCFPremiumDiscountY1Average',
  'SPCFPremiumDiscountY1High',
  'SPCFPremiumDiscountY1Low',
  'SPCFPremiumDiscountAsOfDate',
  'SPCFBollingerPeriod20StdDev2Squeeze'
]

export const DEFAULT_FUND_CARD_FUNDSCREENER_INPUTS = {
  argsOperator: 'AND',
  arguments: [],
  offset: 0,
  limit: 4,
  sortArguments: [
    {
      direction: 'D',
      field: 'SPCFTrailingReturnY5'
    }
  ],
  resultFields: FundScreenerResultColumnArr
}

export const CRYPTOCURRENCY_PERFORMANCE = {
  CRYPTO_INDEXES_NAME: [
    'ETH_USD',
    'BTC_USD',
    'LTC_USD',
    'BCH_USD'
  ],
  CRYPTO_INDEXES: {
    'ETH_USD': {
      'name': 'Ethereum',
      'symbol': 'ETH-USD',
      'wsodIssue': 107144239
    },
    'ETH_EUR': {
      'name': 'Ethereum',
      'symbol': 'ETH-EUR',
      'wsodIssue': 459456265
    },
    'BTC_USD': {
      'name': 'Bitcoin',
      'symbol': 'BTC-USD',
      'wsodIssue': 112038635
    },
    'BTC_EUR': {
      'name': 'Bitcoin',
      'symbol': 'BTC-EUR',
      'wsodIssue': 459436555
    },
    'LTC_USD': {
      'name': 'Litecoin',
      'symbol': 'LTC-USD',
      'wsodIssue': 112102730
    },
    'LTC_EUR': {
      'name': 'Litecoin',
      'symbol': 'LTC-EUR',
      'wsodIssue': 459408249
    },
    'BCH_USD': {
      'name': 'Bitcoin Cash',
      'symbol': 'BCH-USD',
      'wsodIssue': 112418210
    },
    'BCH_EUR': {
      'name': 'Bitcoin Cash',
      'symbol': 'BCH-EUR',
      'wsodIssue': 484621572
    }
  }
}

export const COLOR_DEFAULT_SET = [
  {key: 'blue', value: '#004480', rgbaVal: 'rgba(0, 68, 128, 0.25)'},
  {key: 'lightblue', value: '#0095FF', rgbaVal: 'rgba(0, 149, 255, 0.25)'},
  {key: 'orange', value: '#D97E07', rgbaVal: 'rgba(217, 126, 7, 0.25)'},
  {key: 'persianGreen', value: '#00B3A1', rgbaVal: 'rgba(0, 179, 161, 0.25)'},
  {key: 'magenta', value: '#AA01AA', rgbaVal: 'rgba(170, 1, 170, 0.25)'},
  {key: 'ogre', value: '#FF573F', rgbaVal: 'rgba(255, 87, 63, 0.25)'},
  {key: 'purple', value: '#9085FF', rgbaVal: 'rgba(144, 133, 255, 0.25)'},
  {key: 'red', value: '#BF360C', rgbaVal: 'rgba(191, 54, 12, 0.25)'},
  {key: 'green1', value: '#00AC00', rgbaVal: 'rgba(0, 172, 0, 0.25)'},
  {key: 'black', value: '#093055', rgbaVal: 'rgba(9, 48, 85, 0.25)'}
]
export const NXI_COLOR_DEFAULT_SET = [
  {key: 'blue', value: '#004480', rgbaVal: 'rgba(0, 68, 128, 0.25)'},
  {key: 'grey', value: '#58595A', rgbaVal: 'rgba(88,89,90,1)'},
  {key: 'lightBlue', value: '#287CB9', rgbaVal: 'rgba(40, 124, 185, 1)'},
  {key: 'lightGrey', value: '#BDBDC1', rgbaVal: 'rgba(189, 189, 193, 1)'},
  {key: 'magenta', value: '#AA01AA', rgbaVal: 'rgba(170, 1, 170, 0.25)'},
  {key: 'ogre', value: '#FF573F', rgbaVal: 'rgba(255, 87, 63, 0.25)'},
  {key: 'purple', value: '#9085FF', rgbaVal: 'rgba(144, 133, 255, 0.25)'},
  {key: 'red', value: '#BF360C', rgbaVal: 'rgba(191, 54, 12, 0.25)'},
  {key: 'green1', value: '#00AC00', rgbaVal: 'rgba(0, 172, 0, 0.25)'},
  {key: 'black', value: '#093055', rgbaVal: 'rgba(9, 48, 85, 0.25)'}
]

export const ADVANCE_COLOR_SET = [
  {key: 'downriver', value: '#093055', rgbaVal: 'rgba(9, 48, 85, 1)'},
  {key: 'cerulean', value: '#00A0D4', rgbaVal: 'rgba(0, 160, 212, 1)'},
  {key: 'camelot', value: '#7D324C', rgbaVal: 'rgba(125, 50, 76, 1)'},
  {key: 'christi', value: '#5FA113', rgbaVal: 'rgba(95, 161, 19, 1)'},
  {key: 'seance', value: '#751D84', rgbaVal: 'rgba(117, 29, 132, 1)'},
  {key: 'pirate Gold', value: '#C98506', rgbaVal: 'rgba(201, 133, 6, 1)'},
  {key: 'teal', value: '#008778', rgbaVal: 'rgba(0, 135, 120, 1)'},
  {key: 'trinidad', value: '#E65100', rgbaVal: 'rgba(230, 81, 0, 1)'},
  {key: 'medium Purple', value: '#7472E0', rgbaVal: 'rgba(116, 114, 224, 1)'},
  {key: 'congress Blue', value: '#003E83', rgbaVal: 'rgba(0, 62, 131, 1)'},
  {key: 'robinBlue', value: '#00BCD4', rgbaVal: 'rgba(0, 188, 212, 1)'}
]

export const MARKETS_PERFORMANCE = {
  INDEXESNAME: {
    SAndP500: 'SAndP500',
    DJIA: 'DJIA',
    NASDAQ: 'NASDAQ',
    Russell2000: 'Russell2000',
    NYSE: 'NYSE',
    AMEX: 'AMEX'
  },
  INDEXES: {
    'NYSE': {
      'wsodIssue': 566677,
      'advancingIssue': 10025197,
      'decliningIssue': 10025198,
      'UnchangedIssue': 10544868,
      'advancingVolumeIssue': 10400806,
      'decliningVolumeIssue': 10544867,
      'unchangedVolumeIssue': 10544869,
      'symbol': 'NYSE',
      'darkColor': COLOR_DEFAULT_SET[3].value,
      'lightColor': COLOR_DEFAULT_SET[3].rgbaVal,
      'borderColor': COLOR_DEFAULT_SET[3].value
    },
    'NASDAQ': {
      'wsodIssue': 579435,
      'advancingIssue': 10025200,
      'decliningIssue': 10025201,
      'UnchangedIssue': 10546650,
      'advancingVolumeIssue': 10546648,
      'decliningVolumeIssue': 10546649,
      'unchangedVolumeIssue': 10546651,
      'symbol': 'NASDAQ',
      'darkColor': COLOR_DEFAULT_SET[4].value,
      'lightColor': COLOR_DEFAULT_SET[4].rgbaVal,
      'borderColor': COLOR_DEFAULT_SET[4].value
    },
    'AMEX': {
      'wsodIssue': 583358,
      'advancingIssue': 7780680,
      'decliningIssue': 7780688,
      'UnchangedIssue': 10545163,
      'advancingVolumeIssue': 10545161,
      'decliningVolumeIssue': 10545162,
      'unchangedVolumeIssue': 10545164,
      'symbol': 'AMEX',
      'darkColor': COLOR_DEFAULT_SET[5].value,
      'lightColor': COLOR_DEFAULT_SET[5].rgbaVal,
      'borderColor': COLOR_DEFAULT_SET[5].value
    },
    'SAndP500': {
      'wsodIssue': 593933,
      'symbol': 'S&P 500',
      'darkColor': COLOR_DEFAULT_SET[0].value,
      'borderColor': COLOR_DEFAULT_SET[0].value,
      'lightColor': COLOR_DEFAULT_SET[0].rgbaVal
    },
    'DJIA': {
      'wsodIssue': 599362,
      'symbol': 'DJIA',
      'darkColor': COLOR_DEFAULT_SET[1].value,
      'borderColor': COLOR_DEFAULT_SET[1].value,
      'lightColor': COLOR_DEFAULT_SET[1].rgbaVal
    },
    'Russell2000': {
      'wsodIssue': 579920,
      'symbol': 'Russell 2000',
      'darkColor': COLOR_DEFAULT_SET[2].value,
      'borderColor': COLOR_DEFAULT_SET[2].value,
      'lightColor': COLOR_DEFAULT_SET[2].rgbaVal
    }
  },
  'dropDownValues': {
    PERFORMANCE: 'Performance',
    ADVANCERSDECLINERS: 'Advancers/Decliners'
  },
  PERFORMANCECHART: {
    DAYS: 1,
    DATAINTERVAL: 30,
    STYLE: {
      LABELFONTSIZE: 12,
      LABELFONTCOLOR: '#000000',
      LABELFONTFAMILY: 'Roboto-Regular',
      BORDERBOTTOMCOLOR: '#D9D9D9',
      GRIDHORIZONTALCOLOR: '#D9D9D9'
    },
    MARKERTYPE: 'lineWithShapes'
  },
  PERFORMANCE_INDEXES_TILES_ORDER: {
    SAndP500: 'SAndP500',
    DJIA: 'DJIA',
    Russell2000: 'Russell2000',
    NYSE: 'NYSE',
    NASDAQ: 'NASDAQ',
    AMEX: 'AMEX'
  },
  'intervalMapping': {
    1: '1',
    5: '1',
    30: '1',
    90: '1',
    365: '1',
    1825: '1',
    3650: '1',
    10000: '1'
  },
  'periodMapping': {
    1: 'Hour',
    5: 'Hour',
    30: 'Week',
    90: 'Week',
    365: 'Month',
    1825: 'Month',
    3650: 'Month',
    10000: 'Month'
  }
}
export const CHART_EVENTS = [{'id': 0, 'text': 'Dividends', 'checked': false, 'value': 'Dividends'}, {'id': 1, 'text': 'Splits', 'checked': false, 'value': 'Splits'}, {'id': 2, 'text': 'Earnings', 'checked': false, 'value': 'CustomEarnings'}]
export const CHART_STYLES = [
  { Text: 'Line Chart', value: 'line', Icon: 'chart-line', checked: false, Onlyvisual: true },
  { Text: 'Mountain Chart', value: 'fill', Icon: 'chart-area', checked: false, Onlyvisual: true },
  { Text: 'Candlestick ', value: 'candlestick', Icon: 'chart-candlestick', checked: false, Onlyvisual: true }
]
export const CHART_TIME_FRAMES = {
  1: '1 Day',
  5: '5 Days',
  30: '1 Month',
  90: '3 Months',
  365: '1 Year',
  1825: '5 Years',
  3650: '10 Years'
}

export const STOCKS_PERFORMANCE = {
  INDEXES: {
    'darkColor': '#004480',
    'lightColor': 'rgb(191, 208, 223, 0.4)', // 'rgba(41,61,192,0.25)',
    'borderColor': '#2938C8'
  },
  'chartTypes': {
    LINECHART: 'chart-line',
    CANDLESTICKCHART: 'chart-candlestick',
    Fill: 'chart-area'
  },
  'dropDownDaysValues': {
    '1 Day': 1,
    '5 Days': 5,
    '1 Month': 30,
    '3 Months': 90,
    '1 Year': 365,
    '5 Years': 1825,
    '10 Years': 3650,
    'Max': 10000
  },
  'intervalMapping': {
    1: '5',
    5: '1',
    30: '1',
    90: '1',
    365: '1',
    1825: '1',
    3650: '1',
    10000: '1'
  },
  'periodMapping': {
    1: 'Minute',
    5: 'Hour',
    30: 'Week',
    90: 'Week',
    365: 'Month',
    1825: 'Month',
    3650: 'Month',
    10000: 'Month'
  },
  PERFORMANCECHART: {
    DATAINTERVAL: 30,
    STYLE: {
      LABELFONTSIZE: 12,
      LABELFONTCOLOR: '#000000',
      LABELFONTFAMILY: 'Roboto-Regular',
      BORDERBOTTOMCOLOR: 'rgb(0, 0, 0, .3)',
      GRIDHORIZONTALCOLOR: 'rgb(0, 0, 0, .15)'
    },
    MARKERTYPE: 'lineWithShapes'
  },
  TIME_PERIOD: {
    '1D': 1,
    '5D': 5,
    '1M': 30,
    '3M': 90,
    '1Y': 365,
    '5Y': 1825,
    '10Y': 3650,
    'Max': 10000
  }
}
export const DATE_FORMAT = {
  YEAR: 'yyyy',
  YEAR_MONTH_DAY: 'yyyy-MM-DD',
  MONTH_Day_YEAR: 'MM/DD/YY', // (December 18, 2010 -> 12/18/10)
  MONTH_DAY_YEAR_DATE: 'MM/DD/yyyy',
  MONTH_DAY_YEAR: 'MMM DD, yyyy',
  MONTH_DAY_YEAR_TIME: 'MMM DD, YYYY hh:mm A',
  MONTH_DAY_YEAR_TIMEINSEC: 'MMM DD, YYYY hh:mm:ss A',
  MONTH_DAY_YEAR_LOCALE: 'LL',
  MONTH_YEAR: 'MMM YY' // (April 7, 2023 -> Apr 23)
}
export const TREASURY_BILLS = {
  header: {
    period: '3M Treasury Bill',
    wsodIssue: 9696613
  },
  body: [
    {
      period: '6M Treasury Bill',
      wsodIssue: 9696612
    },
    {
      period: '2Y Treasury Bill',
      wsodIssue: 11523678
    },
    {
      period: '5Y Treasury Bill',
      wsodIssue: 11523679
    },
    {
      period: '10Y Treasury Bill',
      wsodIssue: 11523680
    },
    {
      period: '30Y Treasury Bill',
      wsodIssue: 11523681
    }]
}

export const CURRENCY = {
  header: {
    period: 'EUR/USD',
    wsodIssue: 617254
  },
  body: [
    {
      period: 'USD/CAD',
      wsodIssue: 615897
    },
    {
      period: 'USD/JPY',
      wsodIssue: 616660
    },
    {
      period: 'GBP/USD',
      wsodIssue: 618341
    },
    {
      period: 'EUR/GBP',
      wsodIssue: 612458
    },
    {
      period: 'EUR/JPY',
      wsodIssue: 617621
    }]
}

export const MARKET_EVENTS_CALENDER = {
  CALENDAR_TYPE: {
    MONTHLY: 'Monthly',
    WEEKLY: 'Weekly'
  },
  DAYS: {
    'Monday': 'M',
    'Tuesday': 'T',
    'Wednesday': 'W',
    'Thursday': 'TH',
    'Friday': 'F'
  },
  DAY_STYLES: {
    DISABLED: 'disabled',
    SELECTED: 'selected',
    TODAY: 'today'
  },
  DATE_FORMAT: 'YYYY-MM-DD',
  EVENTS_TYPE: {
    'Dividend Events': {
      'apiUrl': URLS.PLATFORM.DIVIDENDEVENTSCOUNTBYDATE
    },
    'Earnings Events': {
      'apiUrl': URLS.PLATFORM.EARNINGEVENTSCOUNTBYDATE,
      'additionalParameters': {
        'eventName': 'Earnings Events'
      }
    },
    'Economic Events': {
      'apiUrl': URLS.PLATFORM.ECONOMICEVENTSCOUNTBYDATE,
      'additionalParameters': {
        'eventName': 'Economic Events'
      }
    },
    'Split Events': {
      'apiUrl': URLS.PLATFORM.SPLITEVENTSCOUNTBYDATE,
      'additionalParameters': {
        'includeSplits': true
      }
    }
  }
}

export const NXI_COLOR_CHARTIST_SET = {
  DARK_BLUE: '#004480',
  GREY: '#58595A',
  LIGHT_BLUE: '#287CB9',
  LIGHT_GREY: '#BDBDC1',
  MAGENTA: '#AA01AA'
}
export const COLOR_CHARTIST_SET = {
  DARK_BLUE: '#004480',
  LIGHT_BLUE: '#0095FF',
  ORANGE: '#D97E07',
  GREEN: '#00B3A1',
  PURPLE: '#AA01AA'
}

export const COLOR_TABLE_CELL = {
  GREEN: '#008338',
  RED: '#E40707'
}

export const PRICECOLOR = {
  RED: '#ED0808',
  GREEN: '#008338'
}

export const MARKET_EVENTS_CURRENCY_CONVERSION = {
  VALID_CURRENCY: [
    'EUR',
    'USD',
    'CAD',
    'JPY',
    'GBP'
  ],
  PRECISION: 2
}
export const TYPE_OF_VALUE = {
  DECIMAL: 'decimal',
  INTEGER: 'integer',
  PERCENTAGE: 'percentage',
  DATE: 'Date',
  NUMBER: 'Number'
}
export const STOCK_VALUATION_AVG = {
  DATAMAP: [
    {'key': 'Price / Earnings',
      'value': 'data && data.valuation && data.valuation.peRatio.ttm'
    }, {
      'key': 'Price / Book',
      'value': 'data && data.valuation && data.valuation.pbRatio.current'
    },
    {
      'key': 'Price / Sales',
      'value': 'data && data.valuation && data.valuation.psRatio.ttm'
    },
    {
      'key': 'Return on Equity',
      'value': 'data && data.operations.find(e => e.period == "1Y").roe * 100',
      'type': TYPE_OF_VALUE.PERCENTAGE,
      'column': 1
    },
    {
      'key': 'Long Term Debt to Capital',
      'value': 'data && data.operations.find(e => e.period == "3M").leverage.longTermDebtTotalCapitalRatio * 100',
      'type': TYPE_OF_VALUE.PERCENTAGE,
      'column': 1
    }
  ],
  PRECISION: 2,
  HEADER: 'Valuations',
  FOOTER: 'Go to Valuations'
}
export const STOCK_VALUATION = {
  DATAMAP: [
    {'key': 'Price / Earnings (TTM)',
      'value': 'data && data.valuation && data.valuation.peRatio && data.valuation.peRatio.ttm'
    }, {
      'key': 'Price / Book (MRQ)',
      'value': 'data && data.valuation && data.valuation.pbRatio && data.valuation.pbRatio.current'
    },
    {
      'key': 'Price / Sales (TTM)',
      'value': 'data && data.valuation && data.valuation.psRatio && data.valuation.psRatio.ttm'
    }, {
      'key': 'Return on Equity (MRFY)',
      'value': 'data && data.operations && data.operations.find(e => e.period == "1Y").roe * 100',
      'type': TYPE_OF_VALUE.PERCENTAGE,
      'column': 1
    },
    {
      'key': 'Long Term Debt to Capital (MRQ)',
      'value': 'data && data.operations && data.operations.find(e => e.period == "3M").leverage.longTermDebtTotalCapitalRatio * 100',
      'type': TYPE_OF_VALUE.PERCENTAGE,
      'column': 1
    }
  ],
  PRECISION: 2,
  HEADER: 'Valuations',
  FOOTER: 'Go to Valuations'
}
export const CONFIRMATION_MESSAGES = {
  Yes: 'Yes',
  No: 'No',
  Cancel: 'Cancel'
}

export const ModuleName = {
  ETF_RESEARCH_SNAPSHOT: 'ETF_RESEARCH_SNAPSHOT',
  ETF_RESEARCH_SNAPSHOT_ASSET_ALLOCATION: 'ETF_RESEARCH_SNAPSHOT_ASSET_ALLOCATION'
}
export const ERROR_MESSAGE = 'No results for selected criteria'

export const DEFAULT_SCREEN_ID = '290'
export const FUND_SCREEN_ID = '450'
export const MODEL_SCREEN_TYPE = 'MODEL'
export const FUND_SCREEN_TYPE = 'FUND'
export const AI_SCREEN_TYPE = 'AI'
export const DEFAULT_VALUE = '--'

export const OBJECT_STORE_CATEGORY = {
  WATCHLIST_HOLDINGS_NOTES: 'WATCHLIST_HOLDINGS_NOTES',
  RECENT_SEARCH: 'WATCHLIST_HOLDINGS_NOTES'
}

export const AI_SCREEN_ID = '448'
export const SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API = 10000

export const OPTIONS_CHAIN = {
  CALLS_AND_PUTS: [
    'Calls & Puts',
    'Calls Only',
    'Puts Only'
  ],
  STRIKES: [
    '5 Strikes',
    '10 Strikes',
    '15 Strikes',
    'All'
  ],
  STANDARD: [
    'All',
    'Standard',
    'Adjusted'
  ]
}
export const STATUS_CODE = {
  NOT_FOUND: 404
}

export const DIVIDEND_NOT_AVAILABLE_MSG = 'Dividend History information is presently unavailable for this company. This could indicate that the company has never provided a dividend or that a dividend is pending.'
export const PAGE_NAMES = {
  CHARTING: 'charting',
  EARNINGS_ESTIMATES: 'earnings-estimates',
  FUNDAMENTALS: 'fundamentals',
  VALUATIONS: 'valuations',
  NEWS: 'news'
}

export const TAB_NAMES = {
  PORTFOLIO: 'ESG Metrics',
  SNAPSHOT: 'Snapshot'
}
export const TIME_ZONE = {
  AMERICA_NEWYORK: 'America/New_York'
}

export const TEXT_NULL_VALUE = '--'

export const MOD_GOTO_PAGES = {
  VIEW_ALL_NEWS: 'news-center-mod',
  VIEW_ALL_EVENTS: 'market-events-mod'
}

export const EVENT_TYPE_LABEL = 'Event Type'

export const XREF_XIDS_REQUEST_SIZE = 50

