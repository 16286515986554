import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useXref } from '../../../hooks/useXref'
import { registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent } from '../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../utils/f2Constants'
import OptionsChain from './OptionsChain'
import Nodata from '../../Lib/NoData/NoData'
import Loader from '../../Lib/common/Loader'

export default function OptionsChainContainer ({symbol}) {
  // Need to update symbol on change event
  const [localSymbol, setSymbol] = useState(symbol)
  let xref = useXref(symbol)

  useEffect(() => {
    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (symbolUpdatedObject) => {
      xref.updateSymbol(symbolUpdatedObject.symbol)
      setSymbol(symbolUpdatedObject.symbol)
    })
    registerAccessTokenUpdatedF2OnEvent(() => {
      xref.callData(symbol)
    })
  }, [])

  return <div>
    <Loader spinnerSize={'2x'} ready={!xref?.isLoading}>
      {
        !xref?.isError
          ? <OptionsChain xref={xref} symbol={localSymbol} />
          : <Nodata />
      }
    </Loader>
  </div>
}
OptionsChainContainer.propTypes = {
  symbol: PropTypes.string
}
