import React, { useEffect, useState } from 'react'
import DataRequest from '../../../../../../services/DataRequest'
import FundStocksQuote from './FundStocksQuote/FundStocksQuote'
import { DEFAULT_VALUE, URLS, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS, FundScreenerResultColumnArr } from '../../utils/appConstants'
import {fundVestTag} from '../../Helpers/CommonFunctions'
import PropTypes from 'prop-types'
import { getAPIData, isMMF } from '../../utils/utilities'
import { FUND_QUOTE_DEFAULT_STATE } from './FundQuoteDefaultState'
import { getQuoteData } from '../../../../../../shared/dataMethods'

const FundQuoteBar = (props) => {
  let [stockData, setStockData] = useState({
    ...FUND_QUOTE_DEFAULT_STATE,
    shareName: props.shareName,
    isFundvestEtf: props.isFundvestEtf
  })

  function resetStates () {
    setStockData({
      ...FUND_QUOTE_DEFAULT_STATE,
      shareName: props.shareName,
      isFundvestEtf: props.isFundvestEtf
    })
  }

  useEffect(() => {
    // Whenever fund changes - resets state sub-component
    resetStates()
    getCategoryType(props.xid)
    if (!props.isFundvestEtf || props.isFundvestEtf === undefined) { // in case of view profile from recent search we dont get FundVest flag, so we need to get it from fund screenner
      getFundVestDetail(props.xid)
    }
  }, [props.xid])

  const fetchQuoteBarData = (mstarCat) => {
    let promiseArray = []
    if (props?.xid) {
      promiseArray.push(getQuote(props.xid, mstarCat))
      promiseArray.push(getFundNetAsset(props.xid))
      promiseArray.push(getGrossExpenseRatio(props.xid))
      promiseArray.push(getTrailing1YrDistributionYield(props.xid))

      Promise.all(promiseArray)
        .then(responseArray => {
          const [quotesData, fundNetAssetsData, grossExpenseRatioData, trailing1YrDistributionYieldData] =
                [responseArray[0], responseArray[1], responseArray[2], responseArray[3]]

          setStockData((prevState) => {
            return {...prevState,
              DelayedQuoteDate: {
                label: FUND_QUOTE_DEFAULT_STATE.DelayedQuoteDate.label,
                value: (quotesData && quotesData.lastTrade) ? new Date(quotesData.lastTrade.date) : DEFAULT_VALUE
              },
              LastNAV: {
                label: FUND_QUOTE_DEFAULT_STATE.LastNAV.label,
                value: (quotesData && quotesData.lastTrade) ? quotesData.lastTrade.last : DEFAULT_VALUE
              },
              Change: {
                label: FUND_QUOTE_DEFAULT_STATE.Change.label,
                value: (quotesData && quotesData.lastTrade) ? quotesData.lastTrade.change : DEFAULT_VALUE,
                percentChange: (quotesData && quotesData.changePercent) ? quotesData.changePercent.today : DEFAULT_VALUE
              },
              YTDChange: {
                label: FUND_QUOTE_DEFAULT_STATE.YTDChange.label,
                value: (quotesData && quotesData.changePercent) ? quotesData.changePercent.yearToDate : DEFAULT_VALUE
              },
              LastTradeDate: {
                label: FUND_QUOTE_DEFAULT_STATE.LastTradeDate.label,
                value: (quotesData && quotesData.lastTrade) ? new Date(quotesData.lastTrade.date) : DEFAULT_VALUE
              },
              FundNetAssets: {
                label: FUND_QUOTE_DEFAULT_STATE.FundNetAssets.label,
                value: (fundNetAssetsData && fundNetAssetsData.fundNetAssets) || DEFAULT_VALUE
              },
              GrossExpenseRatio: {
                label: FUND_QUOTE_DEFAULT_STATE.GrossExpenseRatio.label,
                value: (grossExpenseRatioData && grossExpenseRatioData.items.fundsData[0].prospectus.grossExpenseRatio) || DEFAULT_VALUE
              },
              Trailing1YrDistributionYield: {
                label: FUND_QUOTE_DEFAULT_STATE.Trailing1YrDistributionYield.label,
                value: (trailing1YrDistributionYieldData && trailing1YrDistributionYieldData.items.some(x => x.type === 'TrailingY1Yield') && trailing1YrDistributionYieldData.items.find(x => x.type === 'TrailingY1Yield').value) || DEFAULT_VALUE
              },
              shareName: props.shareName,
              isLoading: false
            }
          })
        })
        .catch(function () {
          setStockData((prevState) => {
            return {...prevState,
              ...FUND_QUOTE_DEFAULT_STATE,
              isLoading: false
            }
          })
        })
    } else {
      setStockData((prevState) => {
        return {...prevState,
          ...FUND_QUOTE_DEFAULT_STATE,
          isLoading: false
        }
      })
    }
  }
  const getQuote = (venueXid, mstarCat) => {
    return new Promise((resolve) => {
      let callback = (response) => {
        if (!response.isError) {
          let quotesData = response && response.data.quotes && response.data.quotes.length > 0 &&
          response.data.quotes[0].data
          return resolve(quotesData)
        } else return resolve(null)
      }
      getQuoteData(venueXid, callback, false, null, isMMF({CategoryName: mstarCat}))
    })
  }
  const setDefaultStateSector = () => {
    setStockData((prevState) => {
      return {
        ...prevState,
        categoryType: DEFAULT_VALUE,
        isLoading: false
      }
    })
  }
  const getCategoryType = (venueXid) => {
    let callbackFunction = (response) => {
      let mstarCat = ''
      if (!response.isError && response.data) {
        let data = response.data
        if (data.items) {
          mstarCat = data.items.find(x => x.type === 'MorningstarCategory').name || DEFAULT_VALUE
          setStockData((prevState) => {
            return {...prevState,
              categoryType: mstarCat
            }
          })
        } else {
          setDefaultStateSector()
        }
      } else {
        setDefaultStateSector()
      }
      fetchQuoteBarData(mstarCat)
    }
    getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_BENCHMARK_INFORMATION, callbackFunction)
  }
  const getFundNetAsset = (venueXid) => {
    return new Promise((resolve) => {
      let callbackFunction = (response) => {
        if (!response.isError && response.data) {
          return resolve(response.data)
        } else {
          return resolve(null)
        }
      }
      getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_SHARECLASS, callbackFunction)
    })
  }
  const getGrossExpenseRatio = (venueXid) => {
    return new Promise((resolve) => {
      let callbackFunction = (response) => {
        if (!response.isError && response.data) {
          return resolve(response.data)
        } else {
          return resolve(null)
        }
      }
      getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_PROSPECTUS, callbackFunction)
    })
  }
  const getTrailing1YrDistributionYield = (venueXid) => {
    return new Promise((resolve) => {
      let callbackFunction = (response) => {
        if (!response.isError && response.data) {
          return resolve(response.data)
        } else {
          return resolve(null)
        }
      }
      getAPIData(venueXid, URLS.PLATFORM.MORNINGSTAR_MANAGEDFUNDS_YIELD, callbackFunction)
    })
  }

  const getFundVestDetail = (venueXid) => {
    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        responseData.items && responseData.items.map(x => {
          x.isFundvestEtf = x.SPCFIsFVETF
        })
        setStockData((prevState) => {
          return {...prevState,
            isFundvestEtf: (Array.isArray(responseData.items) && responseData.items.length && responseData.items[0].SPCFOffshoreFlex === 'Yes') ? 'Offshore Flex' : fundVestTag(Array.isArray(responseData.items) && responseData.items.length && responseData.items[0])
          }
        })
      }
    }
    let screenerArguments = []
    let newArgument =
        {
          field: 'SPCFWSODIssue',
          conditions: [
            {
              operator: 'EqualTo',
              values: [venueXid + '']
            }
          ]
        }
    screenerArguments.push(newArgument)
    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN + '?screenType=MF_ETF'}`,
      buildScreenInputs(0, screenerArguments),
      screenDataCallback
    )
  }
  const buildScreenInputs = (_offset, screenerArguments) => {
    const screenInputs = {...DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS,
      offset: _offset,
      arguments: screenerArguments || [],
      sortArguments: [
        {
          direction: 'A',
          field: 'SPCFticker'
        }
      ],
      limit: 100,
      resultFields: FundScreenerResultColumnArr
    }

    return {
      method: 'POST',
      postData: screenInputs
    }
  }
  return (
    <React.Fragment>
      <FundStocksQuote stocksData={stockData} />
    </React.Fragment>
  )
}

FundQuoteBar.propTypes = {
  xid: PropTypes.any,
  shareName: PropTypes.string,
  isFundvestEtf: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool])
}
export default FundQuoteBar
