import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import BasicDropDown from '../../../Lib/Dropdown/BasicDropDown'
import styles from './OptionsChainFilters.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LinkButton from '../../../Lib/LinkButton/LinkButton'
import {CALLS, PUTS} from '../OptionsConstants'
import { getSymbolToDisplay } from '../../../../utils/utilities'

export default function OptionsChainFilters ({showAdjustmentHeader, filters, dropDownsHandler, tabsHandler, selectedTab, resetHandler, symbol}) {
  const [activeTab, setActiveTab] = useState(CALLS)

  const icon = <FontAwesomeIcon icon={[('fal'), ('check')]} color='white' className={styles.icon} />

  function tabClick (type) {
    setActiveTab(type)
    tabsHandler(type)
  }

  useEffect(() => {
    setActiveTab(selectedTab)
  }, [selectedTab])

  return <div className={styles.OptionsChainContainer}>
    <div className={styles.header}>{getSymbolToDisplay(symbol)}Options Chain</div>
    {showAdjustmentHeader && <div className={styles.adjustmentHeader}>
      <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={styles.iconNoData} />
      <div className={styles.adjustmentMsg}><span className={styles.adjustmentBoldMsg}>Non-Standard Option:</span> Please note this contract may have different delivery instructions.</div>
    </div>}
    <div className={styles.optionsDropdowns}>
      <div className={styles.optionsButtonContainer}>
        <button name={CALLS} className={`${styles.Button} ${activeTab === CALLS && styles['activeOptionsTab']}`}
          onClick={() => { tabClick(CALLS) }}>
          {CALLS} { activeTab === CALLS && icon }
        </button>
        <button name={PUTS} className={`${styles.Button} ${activeTab === PUTS && styles['activeOptionsTab']}`}
          onClick={() => { tabClick(PUTS) }}>
          {PUTS} { activeTab === PUTS && icon }
        </button>
      </div>
      {filters.map(item => {
        return <div key={item.name} className={`${styles.dropdown} ${styles[item.name]}`} data-testid={item.name}>
          <BasicDropDown
            customStyle
            resetDefaultValue={item.resetDefaultValue}
            setRevertDefaultValue={() => {}}
            data={item.options}
            changeHandler={(e) => { dropDownsHandler(e, item.name) }} />
        </div>
      })}
      <div className={styles.linkContainer}>
        <LinkButton dataTestId='Reset' label={'Reset'} onClick={resetHandler} />
      </div>
    </div>
  </div>
}
OptionsChainFilters.propTypes = {
  showAdjustmentHeader: PropTypes.bool,
  filters: PropTypes.array,
  dropDownsHandler: PropTypes.func,
  tabsHandler: PropTypes.func,
  selectedTab: PropTypes.string,
  symbol: PropTypes.string,
  resetHandler: PropTypes.func
}
